export const getTokenSaleCampaigns = (params, autoexec = false) => ({
	method: 'get',
	url: '/tokenSaleCampaign',
	params,
	autoexec
})

export const getTokenSaleCampaignById = (id, autoexec) => ({
	method: 'get',
	url: `/tokenSaleCampaign/${id}`,
	autoexec
})

export const getTokenSaleCampaignGroupById = (id, autoexec) => ({
	method: 'get',
	url: `/tokenSaleCampaign/group/${id}`,
	autoexec
})

export const getTokenSaleCampaignGroups = (params) => ({
	method: 'get',
	url: '/tokenSaleCampaign/groups',
	params,
	autoexec: true
})

export const clearTokenSaleCampaign = (data) => ({
	method: 'post',
	url: '/tokenSaleCampaign/clear',
	data,
	autoexec: false
})

export const generateTokenSaleCampaigns = (data) => ({
	method: 'post',
	url: '/tokenSaleCampaign/generateCampaigns',
	data,
	autoexec: false
})

export const createCampaignsService = (data) => ({
	method: 'post',
	url: '/tokenSaleCampaign/createCampaigns',
	data,
	autoexec: false
})

export const addTokenSaleCampaign = (data) => ({
	method: 'post',
	url: '/tokenSaleCampaign',
	data,
	autoexec: false
})

export const editTokenSaleCampaignGroup = (data) => ({
	method: 'put',
	url: '/tokenSaleCampaign/group',
	data,
	autoexec: false
})

export const editTokenSaleCampaign = (data) => ({
	method: 'put',
	url: '/tokenSaleCampaign',
	data,
	autoexec: false
})