import { Button } from 'react-bootstrap'
import { CustomSelect } from '../../common/customSelect'
import useGoScoreStageForm from './useGoScoreStageForm'
import { LoadMask } from 'components/common/loadMask'

const GoScoreStageForm = () => {
	const {
		data,
		metrics,
		loading,
		handleSave,
		handleCancel,
		handleChange,
		handleChangeMetric,
		handleAddNewMetric,
		handleRemoveMetric,
	} = useGoScoreStageForm()

	const renderMetrics = () => {
		return data.stageMetrics.map((stageMetric, idx) => {
			return (
				<div
					className="form-row"
					key={`stageMetric-${idx}`}
					style={{
						borderTop: '1px solid #473F72',
						paddingTop: '20px',
					}}
				>
					<div className="form-group col-md-8">
						<label>Metric</label>
						<CustomSelect
							{...{
								value: stageMetric.metricId,
								setValue: (metricId) => {
									handleChangeMetric({ metricId }, idx)
								},
								options: metrics,
							}}
						/>
					</div>
					<div className="form-group col-md-3">
						<label>Threshold</label>
						<input
							value={stageMetric.threshold}
							type="number"
							className="form-control"
							placeholder="Metric Threshold"
							onChange={(e) => {
								handleChangeMetric({ threshold: Number(e.target.value) }, idx)
							}}
						/>
					</div>
					<div
						className="col-md-1"
						style={{
							display: 'flex',
							alignItems: 'end',
							marginBottom: '20px',
						}}
					>
						<Button className="mr-2" variant="danger" onClick={handleRemoveMetric(idx)}>
							<i className="fa fa-trash"></i>
						</Button>
					</div>
				</div>
			)
		})
	}

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">Edit Stages</h4>
							</div>
							<div className="card-body">
								<div className="basic-form">
									<form onSubmit={(e) => e.preventDefault()}>
										<div className="form-row">
											<div className="form-group col-md-8">
												<label>Name</label>
												<input
													value={data.name}
													type="text"
													className="form-control"
													placeholder="Badge Name"
													onChange={(e) => {
														handleChange({ name: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Color</label>
												<input
													value={data.color}
													type="text"
													className="form-control"
													placeholder="Color"
													onChange={(e) => {
														handleChange({ name: e.target.value })
													}}
												/>
											</div>
										</div>
										{renderMetrics()}
										<div className="form-row mb-3">
											<Button className="mr-2" variant="info" onClick={handleAddNewMetric}>
												Add New Metric
											</Button>
										</div>
										<Button className="mr-2" variant="success" onClick={handleSave}>
											Save
										</Button>
										<Button variant="danger" onClick={handleCancel}>
											Cancel
										</Button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default GoScoreStageForm
