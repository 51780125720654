import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { addGoScoreBadge, editGoScoreBadge, getBadgeById } from 'services/GoScoreService'
import { getGoScoreMetrics, getGoScoreStages } from 'services/SeedServices'
import { useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'
import { showMessage } from 'utils/notification.util'

const useGoScoreBadgeForm = () => {
	const history = useHistory()
	const { id } = useParams()
	const [metrics, setMetrics] = useState([])
	const [stages, setStages] = useState([])
	const [data, setData] = useState({
		metricId: null,
		name: '',
		description: '',
		threshold: '',
		reward: '',
		image: null,
		active: true,
	})

	const afterFetchMetrics = (data, error) => {
		if (!error) {
			setMetrics(data)
		}
	}

	const [{ loading: loadingMetrics }] = useAxios(getGoScoreMetrics(true), afterFetchMetrics)

	const afterFetchStages = (data, error) => {
		if (!error) {
			setStages(data)
		}
	}

	const [{ loading: loadingStages }] = useAxios(getGoScoreStages(true), afterFetchStages)

	const afterFetchBadge = (badge, error) => {
		if (!error) {
			badge.active = Boolean(badge.active)
			setData(badge)
		}
	}

	const [{ loading: loadingBadges }] = useAxios(getBadgeById(!_.isNil(id), id), afterFetchBadge)

	const afterSave = (_data, error) => {
		if (!error) {
			const message = `Badge ${id ? 'updated' : 'added'} successfully!`
			showMessage({ message })
			history.push('/goscore-badges')
		}
	}

	const service = _.isNil(id) ? addGoScoreBadge(data) : editGoScoreBadge(data)

	const [{ loading: saving }, saveChanges] = useAxios(service, afterSave)

	const handleChange = (payload) => {
		setData({
			...data,
			...payload,
		})
	}

	const handleCancel = () => {
		history.goBack()
	}

	const loading = loadingMetrics || loadingStages || saving || loadingBadges

	const title = id ? 'Edit Badge Details' : 'Create GoScore Badge'

	return { data, stages, metrics, loading, saveChanges, handleCancel, handleChange, title }
}

export default useGoScoreBadgeForm
