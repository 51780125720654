import { useEffect, useState } from 'react'
import { getProjects, updateProject, updateProjectService } from 'services/ProjectService'
import useAxios from 'hooks/useAxios'
import { getProjectStagesService } from 'services/SeedServices'
import { showMessage } from 'utils/notification.util'

const useProjectsTable = () => {
	const [search, setSearch] = useState('')
	const [limit, setLimit] = useState(5)
	const [offset, setOffset] = useState(0)
	const [stageId, setStageId] = useState([])
	const [stages, setStages] = useState([])
	const [projects, setProjects] = useState({
		rows: [],
		count: 0,
	})

	const afterFetchProjects = (data, error) => {
		if (!error) {
			setProjects(data)
		}
	}

	const [{ loading: loadingProjects }, fetchProjects] = useAxios(
		getProjects({ limit, offset, stageId, search }),
		afterFetchProjects
	)

	const afterFetchStages = (data, error) => {
		if (!error) {
			setStages(data)
		}
	}

	const [{ loading: loadingStages }] = useAxios(getProjectStagesService(true), afterFetchStages)

	const afterUpdateProject = async (data, error) => {
		if (!error) {
			await fetchProjects()
			showMessage({ message: 'Project updated successfully!' })
		}
	}

	const [{ loading: updating }, updateProject] = useAxios(
		updateProjectService(),
		afterUpdateProject
	)

	const handleStageChange = (projectId, stageId) => {
		updateProject({
			data: { id: projectId, stageId },
		})
	}

	const loading = loadingProjects || loadingStages || updating

	useEffect(() => {
		fetchProjects()
	}, [limit, offset, stageId, search])

	return {
		loading,
		data: projects,
		stages,
		limit,
		setLimit,
		setOffset,
		setSearch,
		setStageId,
		handleStageChange,
	}
}

export default useProjectsTable
