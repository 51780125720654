import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { ThemeContext } from '../../../context/ThemeContext'
import logo from '../../../images/favicon.ico'

const NavHader = () => {
	const [toggle, setToggle] = useState(false)
	const { openMenuToggle } = useContext(ThemeContext)

	return (
		<div className="nav-header">
			<Link to="/" className="brand-logo">
				<img src={logo} alt="" style={{ width: '40px' }} />
				{!toggle && (
					<span style={{ fontSize: '25px', fontWeight: '600', marginLeft: '10px' }}>Filmio</span>
				)}
			</Link>

			<div
				className="nav-control"
				onClick={() => {
					setToggle(!toggle)
					openMenuToggle()
				}}
			>
				<div className={`hamburger ${toggle ? 'is-active' : ''}`}>
					<span className="line"></span>
					<span className="line"></span>
					<span className="line"></span>
				</div>
			</div>
		</div>
	)
}

export default NavHader
