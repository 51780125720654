import styles from './UserProfile.module.scss'
import useUserProfile from './useUserProfile'
import defaultAvatar from 'images/avatar/default-avatar.png'

const UserProfile = ({ userId }) => {
	const { profileCounts } = useUserProfile(userId)
	const avatar = profileCounts.avatar || defaultAvatar
	return (
		<>
			<div className={`profile card card-body px-3 pt-3 pb-3 ${styles.card_profile}`}>
				<div className="profile-head">
					<div className="photo-content">
						<div className="cover-photo"></div>
					</div>
					<div className={`profile-info ${styles.profile_info}`}>
						<div className={`profile-photo mb-3 ${styles.profile_photo}`}>
							<img src={avatar} alt="profile" />
						</div>
						<div className={`profile-details ${styles.profile_details}`}>
							<div className="profile-name">
								<h4 className="text-primary mb-0">{profileCounts.followersCount}</h4>
								<p>Followers</p>
							</div>
							<div className="profile-name">
								<h4 className="text-primary mb-0">{profileCounts.followingCount}</h4>
								<p>Following</p>
							</div>
							<div className="profile-name">
								<h4 className="text-primary mb-0">{profileCounts.projectsCount}</h4>
								<p>Projects</p>
							</div>
						</div>
						<div className="profile-name">
							<h4 className="text-primary mb-0">{profileCounts.displayName}</h4>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default UserProfile
