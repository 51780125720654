export const getIngXRestrictionUsers = (params) => ({
	method: 'get',
	url: '/ingXRestrictions/users',
    params,
	autoexec: true
})

export const addIngXRestrictionUser = (data) => ({
    method: 'post',
    url: '/ingXRestrictions/users',
    data,
    autoexec: true
})

export const deleteIngXRestrictionUser = (data) => ({
    method: 'delete',
    url: `/ingXRestrictions/users/${data.userId}`,
    autoexec: true
})

export const runIngXRestrictionCron = () => ({
    method: 'post',
    url: `/ingXRestrictions`,
    autoexec: true
})

