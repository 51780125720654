import { useEffect, useState } from 'react'
import { getContestProjects } from 'services/ProjectService'
import useAxios from 'hooks/useAxios'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import { setContestWinner } from 'services/ContestService'
import useAxiosV2 from 'hooks/useAxiosV2'
import { showMessage } from 'utils/notification.util'

const useContestProjectsTable = () => {
	const [search, setSearch] = useState('')
	const [limit, setLimit] = useState(5)
	const [offset, setOffset] = useState(0)
	const [reloadProjects, setReloadProjects] = useState(false)
	const [projects, setProjects] = useState({
		rows: [],
		count: 0,
	})
	const { contestId } = useParams()
	const { axiosService } = useAxiosV2()

	const afterFetchProjects = (data, error) => {
		if (!error) {
			setProjects(data)
		}
	}

	const [{ loading: loadingProjects }, fetchProjects] = useAxios(
		getContestProjects({ limit, offset, search, contestId }),
		afterFetchProjects
	)

	const handleSetWinner = (projectId) => {
		axiosService(
			setContestWinner({ contestId, projectId }),
			(_data, error) => {
				if (!error) {
					setReloadProjects(!reloadProjects)
					showMessage({ message: 'Contest winner set.' })
				}
			}
		)
	}

	const loading = loadingProjects

	useEffect(() => {
		fetchProjects()
	}, [limit, offset, search, contestId, reloadProjects])

	return {
		limit,
		loading,
		data: projects,
		handleSetWinner,
		setOffset,
		setSearch,
		setLimit
	}
}

export default useContestProjectsTable
