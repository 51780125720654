import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { editTriggeredEmailService, getTriggerEmailsService } from 'services/EmailNotificationServices'
import { setStep } from '../triggeredForm/triggeredFormSlice'
import { useDispatch } from 'react-redux'
import { showMessage } from 'utils/notification.util'

const useTriggeredTable = () => {
	const [search, setSearch] = useState('')
	const [limit, setLimit] = useState(5)
	const [offset, setOffset] = useState(0)
	const [data, setData] = useState({
		rows: [],
		count: 0,
	})
	const dispatch = useDispatch()

	const afterFetchNotificationEmail = (data, error) => {
		if (!error) {
			setData(data)
		}
	}

	const [{ loading }, fetchReviews] = useAxios(
		getTriggerEmailsService({ limit, offset, search }),
		afterFetchNotificationEmail
	)

	const handleEditClick = () => {
		dispatch(setStep(0))
	}

	const afterActivate = (data, error) => {
		if (!error) {
			const mpart = data.active === 1 ? 'enabled' : 'disabled'
			const message = `Notification Email have been ${mpart}`
			showMessage({ message })
			setData(d => {
				const rows = d?.rows?.map(el => {
					if (el.id === data.id) {
						el.active = data.active
					}
					return el
				})
				return {
					...d,
					rows
				}
			})
		}
	}

	const [{ loading: activating }, activate] = useAxios(
		editTriggeredEmailService(),
		afterActivate
	)

	const handleActivate = (id, active) => {
		activate({ data: { id, active } })
	}

	useEffect(() => {
		fetchReviews()
	}, [limit, offset, search])

	return {
		loading: loading || activating,
		data,
		limit,
		setLimit,
		setOffset,
		setSearch,
		handleActivate,
		handleEditClick
	}
}

export default useTriggeredTable
