import _ from 'lodash'
import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { useHistory, useParams } from 'react-router-dom'
import { showMessage } from 'utils/notification.util'
import { addStudio, editStudio, getStudioById } from 'services/StudioService'

const getStudioDto = (data) => {
	const { id, name } = data
	return { id, name }
}

const useStudioForm = () => {
	const history = useHistory()
	const { id } = useParams()
	const [data, setData] = useState({})

	const afterFetchStudio = (studio, error) => {
		if (!error) {
			setData(studio)
		}
	}

	const [{ loading: loadingStudio }] = useAxios(getStudioById(!_.isNil(id), id), afterFetchStudio)

	const afterSave = (_data, error) => {
		if (!error) {
			const message = 'Studio updated successfully!'
			showMessage({ message })
			history.push('/studios')
		}
	}

	const service = _.isNil(id) ? addStudio(data) : editStudio(getStudioDto(data))

	const [{ loading: saving }, saveChanges] = useAxios(service, afterSave)

	const handleChange = (payload) => {
		setData({
			...data,
			...payload,
		})
	}

	const handleCancel = () => {
		history.goBack()
	}

	const loading = saving || loadingStudio

	const title = _.isNil(id) ? 'Add studio' : 'Edit Studio'

	return {
		data,
		title,
		loading,
		saveChanges,
		handleCancel,
		handleChange,
	}
}

export default useStudioForm
