export const getComments = (limit, offset, search) => ({
	method: 'get',
	url: '/comment',
	params: { limit, offset, search },
	autoexec: false,
})

export const editComment = (data) => ({
	method: 'put',
	url: '/comment',
	data,
	autoexec: false,
})
