import _ from 'lodash'
import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { useHistory, useParams } from 'react-router-dom'
import { showMessage } from 'utils/notification.util'
import { addTokenSaleCampaign, editTokenSaleCampaign, getTokenSaleCampaignById } from 'services/TokenCampaignService'

const useTokenSaleCampaignForm = () => {
	const history = useHistory()
	const [data, setData] = useState({
		label: null,
		startDate: null,
		endDate: null
	})
	const { id } = useParams()

	const afterFetchCampaign = (data, error) => {
		if (!error) {
			data.startDate = new Date(data.startDate)
			data.endDate = new Date(data.endDate)
			setData(data)
		}
	}
	const [{ loading: loadingCampaign }] = useAxios(getTokenSaleCampaignById(id, !_.isNil(id)), afterFetchCampaign)

	const afterSave = (_data, error) => {
		if (!error) {
			const message = `Token Sale Campaign ${_.isNil(id) ? 'added' : 'updated'} successfully!`
			showMessage({ message })
			history.goBack()
		}
	}

	const service = _.isNil(id) ? addTokenSaleCampaign(data) : editTokenSaleCampaign(data)
	const [{ loading: saving }, saveChanges] = useAxios(service, afterSave)

	const handleChange = (payload) => {
		const key = Object.keys(payload)[0]
		if (key === 'isAirdrop' && payload[key] === true) {
			payload.campaignId = null
		}
		setData({
			...data,
			...payload,
		})
	}

	const handleCancel = () => {
		history.goBack()
	}

	const loading = saving || loadingCampaign

	return {
		data,
		loading,
		handleCancel,
		handleChange,
		saveChanges
	}
}

export default useTokenSaleCampaignForm
