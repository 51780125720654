import { useEffect, useState } from 'react'
import styles from './CustomPagination.module.scss'

const Pagination = ({ count, limit, setLimit, setOffset, maxLimit }) => {
	const options = maxLimit ? [5, 20, 50, 100, maxLimit]: [5, 20, 50, 100, 'All']
	const [page, setPage] = useState(1)
	const totalPages = Math.ceil(count / limit)
	const offset = (page - 1) * limit

	const nextPage = () => {
		setPage((p) => {
			return p + 1 <= totalPages ? p + 1 : p
		})
	}

	const prevPage = () => {
		setPage((p) => {
			return p - 1 >= 1 ? p - 1 : p
		})
	}

	const handleLimitChange = (e) => {
		setLimit(e.target.value)
		setPage(1)
	}

	const renderLimitOptions = () => {
		return options.map((el) => {
			if (el === 'All')
				return (
					<option className={styles.option_style} value={count} key={`option-${el}`}>
						{el}
					</option>
				)
			return (
				<option className={styles.option_style} value={el} key={`option-${el}`}>
					{el}
				</option>
			)
		})
	}

	const handlePageChange = (e) => {
		const newPage = Number(e.target.value)
		if (newPage <= totalPages && newPage > 0) {
			setPage(newPage)
		}
	}

	const getSelectValue = () => {
		let value = count;
		let arr = options.filter((ele) => ele >= limit && ele != "All")
		if(arr.length > 0) return arr[0]
		return value
	}

	useEffect(() => {
		const offset = (page - 1) * limit
		setOffset(offset)
	}, [page, limit])

	useEffect(() => {
		setPage(1)
	}, [count])

	const disabledNext = page === totalPages
	const disabledPrev = page === 1
	const entryLimit = offset + Number(limit) > count ? count : offset + Number(limit)

	return (
		<div className={styles.pagination}>
			<div className={styles.records}>
				Showing <span>{offset + 1}</span> to <span> {entryLimit}</span> of <span>{count}</span>{' '}
				entries
			</div>
			<div className={styles.limit}>
				{'Show '}
				<select value={getSelectValue()} onChange={handleLimitChange}>
					{renderLimitOptions()}
				</select>
				{' entries'}
			</div>
			<div className={styles.pages}>
				Page
				<button className=" previous-button" onClick={() => prevPage()} disabled={disabledPrev}>
					{'<'}
				</button>
				<input value={page} onChange={handlePageChange} />
				<button className=" next-button" onClick={() => nextPage()} disabled={disabledNext}>
					{'>'}
				</button>
				of {totalPages}
			</div>
		</div>
	)
}

export default Pagination
