import { useEffect, useState } from 'react'
import { showMessage } from 'utils/notification.util'
import useAxios from 'hooks/useAxios'
import {
	getTransactionProject,
	getTransactionStatus,
	getTransactionType,
	getTransactions,
	reconcileTransactionService
} from 'services/TransactionsService'

const useTransactionsTable = () => {
	const [reload, setReaload] = useState(false)
	const [filters, setFilters] = useState({
		sortColumn: 'createdAt',
		direction: 'desc',
		search: '',
		status: '',
		type: '',
		from: null,
		to: null,
		limit: 20,
		offset: 0,
	})

	const [transactions, setTransactions] = useState({
		rows: [],
		count: 0,
	})
	const [project, setProject] = useState(null)
	const [show, setShow] = useState(false);
	const [selectedRow, setSelectedRow] = useState({})
	const [statuses, setStatuses] = useState([])
	const [types, setTypes] = useState([])

	const mapColumn = (column) => {
		switch (column) {
			case "Date & Time":
				return "createdAt"
			case "Name":
				return "displayName"
			case "Email":
				return "email"
			case "Amount":
				return "amount"
			case "Status":
				return "status"
			case "Type":
				return "type"
			default:
				return "createdAt"
		}
	}

	const afterFetchTransactions = (data, error) => {
		if (!error) {
			setTransactions(data)
		}
	}

	const afterFetchTransactionProject = (data, error) => {
		console.log({ data })
		if (!error) {
			setProject(data?.title)
		}
	}

	const afterFetchStatuses = (data, error) => {
		if (!error) {
			setStatuses(data.rows)
		}
	}

	const afterFetchTypes = (data, error) => {
		if (!error) {
			setTypes(data.rows)
		}
	}

	const [{ loading: loadingTransactions }, fetchTransactions] = useAxios(

		getTransactions({
			limit: filters.limit,
			offset: filters.offset,
			search: filters.search,
			status: filters.status,
			type: filters.type,
			from: filters?.from,
			to: filters?.to,
			direction: filters?.direction,
			orderBy: mapColumn(filters?.sortColumn)
		}),
		afterFetchTransactions
	)

	const [{ loading: loadingTransactionProject }, fetchTransactionProject] = useAxios(
		getTransactionProject({
			objectId: selectedRow.objectId,
			typeId: selectedRow.typeId,
			projectId: selectedRow.originWallet?.projectId ?? selectedRow.destinationWallet?.projectId
		}),
		afterFetchTransactionProject
	)

	const [{ loading: loadingStatuses }, fetchStatuses] = useAxios(
		getTransactionStatus(),
		afterFetchStatuses
	)

	const [{ loading: loadingTypes }, fetchTypes] = useAxios(
		getTransactionType(),
		afterFetchTypes
	)

	const [{ loading: loadingReconcileTransaction }, reconcileTransaction] = useAxios(
		reconcileTransactionService(),
		(_data, error) => {
			if (!error) {
				if (_data.isReconciled) {
					showMessage({ message: 'The transaction was reconciled successfully.' })
					setReaload(r => !r)
				} else {
					showMessage({ message: 'The transaction could not be reconciled.' })
				}

			}
		}
	)

	const loading = loadingTransactions || loadingStatuses || loadingTypes || loadingReconcileTransaction || loadingTransactionProject

	useEffect(() => {
		fetchTransactions()
	}, [filters, reload])

	useEffect(() => {
		if (Object.keys(selectedRow).length > 0) {
			const index = [1, 5, 6, 9, 10, 11, 12, 13, 14, 15, 16].findIndex((ele) => ele === selectedRow.typeId)
			if (index === -1) {
				fetchTransactionProject()
			} else {
				setProject(null)
			}
		}
	}, [selectedRow])

	useEffect(() => {
		fetchStatuses()
		fetchTypes()
	}, [])

	const handleClearFilter = () => {
		setFilters({
			sortColumn: 'createdAt',
			direction: 'desc',
			search: '',
			status: '',
			type: '',
			from: null,
			to: null,
			limit: 20,
			offset: 0,
		});
	}
	const handleViewDetails = (row) => {
		setSelectedRow(row)
		setShow(true)
	}

	const handleChangeLimit = (limit) => {
		setFilters((prv) => {
			return { ...prv, limit: limit }
		})
	}

	const setOffset = (offset) => {
		setFilters(prv => {
			if (prv.offset === offset) {
				return prv
			}
			return { ...prv, offset: offset }
		})


	}

	const handleChangeSearch = (search) => {
		setFilters(prv => {
			return { ...prv, search: search, from: null, to: null, direction: "desc", sortColumn: "createdAt", type: '', status: '', offset: 0 }
		})

	}


	const handleChangeSortColumn = (sortColumn) => {
		setFilters(prv => {
			return { ...prv, sortColumn: sortColumn }
		})
	}

	const handleChangeDirectioin = (direction) => {
		setFilters(prv => {
			return { ...prv, direction: direction }
		})
	}

	const handleChangeStatus = (status) => {
		setFilters((prv) => {
			return { ...prv, status: status }
		})
	}


	const handleChangeType = (type) => {
		setFilters((prv) => {
			return { ...prv, type: type }
		})
	}

	const handleChangeFromDateRange = (data) => {
		setFilters(prv => {
			return { ...prv, from: data.from }
		})
	}

	const handleChangeToDateRange = (data) => {
		setFilters(prv => {
			return { ...prv, to: data.to }
		})
	}

	const handleReconcileTransaction = (transactionId) => {
		reconcileTransaction({ data: { transactionId } })
	}

	return {
		loading,
		data: transactions,
		project,
		filters: filters,
		handleClearFilter,
		handleChangeLimit,
		setOffset,
		handleChangeSearch,
		handleChangeSortColumn,
		handleChangeDirectioin,
		handleChangeStatus,
		handleChangeType,
		handleChangeFromDateRange,
		handleChangeToDateRange,
		handleReconcileTransaction,
		statuses,
		types,
		selectedRow,
		show,
		setShow,
		handleViewDetails,
	}
}

export default useTransactionsTable
