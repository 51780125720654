import _ from 'lodash'
import { Row, Col, Card } from 'react-bootstrap'
import useJoinUsersTable from './useJoinUsersTable'
import { formatDate } from 'utils/date.utils'
import PageTitle from 'jsx/layouts/PageTitle'
import { CustomTable } from 'components/common/customTable'
import { SearchField } from 'components/common/searchField'

const frontBaseURL = process.env.REACT_APP_FRONT_BASE_URL

const JoinUsersTable = () => {
	const {
		data,
		limit,
		loading,
		setOffset,
		setLimit,
		setSearch
	} = useJoinUsersTable()

	const projectTitle = _.get(data, 'rows[0].socialJoins[0].project.title', '')
	const creatorName = _.get(data, 'rows[0].socialJoins[0].project.user.displayName', '')
	const creatorId = _.get(data, 'rows[0].socialJoins[0].project.user.id', '')

	const columns = [
		{
			header: 'Join Date',
			render: (row) => formatDate(_.get(row, 'createdAt', null), 'inputDate'),
		},
		{
			header: 'User',
			render: (row) => (
				<a href={`${frontBaseURL}/profile/${row.id}`} target="_blank" rel="noreferrer">
					<div className="d-flex align-items-center">
						<img src={row.avatar} className="rounded-md mr-2" width="24" alt="" /> {row.displayName}
					</div>{' '}
				</a>
			),
		},
		{ header: 'Username', key: 'username' },
		{ header: 'Email', key: 'email' },
		{
			header: 'Invited By',
			render: (row) => (
				<a href={`${frontBaseURL}/profile/${creatorId}`} target="_blank" rel="noreferrer">
					<div className="d-flex align-items-center">
						{creatorName}
					</div>{' '}
				</a>
			),
		}
	]

	return (
		<>
			<PageTitle title={'Creator Share Users Joined'} />

			<Row>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>{projectTitle}</Card.Title>
							<SearchField {...{ setSearch }} />
						</Card.Header>
						<Card.Body>
							<CustomTable
								{...{
									data,
									loading,
									limit,
									setLimit,
									setOffset,
									columns,
								}}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default JoinUsersTable
