import useDigestForm from './useDigestForm'
import { LoadMask } from 'components/common/loadMask'
import { Step, Stepper } from 'react-form-stepper'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'

const DaoTierForm = () => {
	const {
		data,
		step,
		days,
		hours,
		cronObj,
		loading,
		minutes,
		templates,
		monthDays,
		frequencies,
		handlePublish,
		notificationTypes,
		getFrequencyHumanFormat,
		handleChangeFrequency,
		handleChangeStep,
		handleChange,
		handleCancel,
		handleSave
	} = useDigestForm()

	const getComponent = () => {
		const components = [Step1, Step2, Step3, Step4, Step5]
		return components[step]
	}
	const Component = getComponent()

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<Stepper className="nav-wizard" activeStep={step}>
							<Step className="nav-link" label='General' onClick={handleChangeStep(0)} />
							<Step className="nav-link" label='Notification Type' onClick={handleChangeStep(1)} />
							<Step className="nav-link" label='Template' onClick={handleChangeStep(2)} />
							<Step className="nav-link" label='Frequency' onClick={handleChangeStep(3)} />
							<Step className="nav-link" label='Confirm' onClick={handleChangeStep(4)} />
						</Stepper>
					</div>
					<Component {...{
						data,
						step,
						days,
						hours,
						cronObj,
						minutes,
						templates,
						monthDays,
						frequencies,
						notificationTypes,
						getFrequencyHumanFormat,
						handleChangeFrequency,
						handlePublish,
						handleChange,
						handleCancel,
						handleSave,
					}} />
				</div>
			)}
		</>
	)
}

export default DaoTierForm
