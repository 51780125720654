import { useEffect, useState } from 'react'
import axios from 'axios'
import useAxios from 'hooks/useAxios'
import { useConfirmModal } from 'components/common/confirmModal'
import {
	getIngXRestrictionUsers,
	deleteIngXRestrictionUser,
	runIngXRestrictionCron
} from 'services/IngXRestrictionService'
import { showMessage } from 'utils/notification.util'

const useIngXRestrictionTable = () => {
	const [search, setSearch] = useState('')
	const [limit, setLimit] = useState(20)
	const [offset, setOffset] = useState(0)
	const [selectedRow, setSelectedRow] = useState(null)
	const [users, setUsers] = useState({
		rows: [],
		count: 0,
	})

	const {
		isOpen,
		handleClose,
		handleOpen
	} = useConfirmModal()

	const afterFetchUsers = (data, error) => {
		if (!error) {
			setUsers(data)
		}
	}

	const [{ loading: loadingUsers }, fetchUsers] = useAxios(
		getIngXRestrictionUsers({ limit, offset, search }),
		afterFetchUsers
	)

	const handleDeleteUser = (row) => {
		setSelectedRow(row)
		handleOpen()
	}

	const handleConfirm = () => {
		if (selectedRow?.userId)
			axios(deleteIngXRestrictionUser({ userId: selectedRow?.userId })).then(() => {
				const message = `User with email ${selectedRow?.email} deleted successfully!`
				showMessage({ message })
				fetchUsers()
			}).finally(() => { handleClose() })
	}

	const handleRunCronJob = () => {
		axios(runIngXRestrictionCron()).then(() => {
			const message = `Command to run IngredientX Restriction cron job sent successfully!`
			showMessage({ message })
			fetchUsers()
		}).catch((error) => showMessage({ message: "Something went wrong, Please try again later!" })) 
	}

	useEffect(() => {
		fetchUsers()
	}, [limit, offset, search])

	return {
		limit,
		loading: loadingUsers,
		data: users,
		selectedRow,
		isOpen,
		setOffset,
		setLimit,
		setSearch,
		handleDeleteUser,
		handleConfirm,
		handleClose,
		handleRunCronJob
	}
}

export default useIngXRestrictionTable
