import _ from 'lodash'
import { Link } from 'react-router-dom'
import { Row, Col, Card, Button } from 'react-bootstrap'
import useTokenSaleCampaignsTable from './useTokenSaleCampaignGroupsTable'
import PageTitle from 'jsx/layouts/PageTitle'
import { CustomTable } from 'components/common/customTable'
import { SearchField } from 'components/common/searchField'
import { formatDate } from 'utils/date.utils'

const TokenSaleCampaignGroupsTable = () => {
	const {
		data,
		limit,
		loading,
		setOffset,
		setLimit,
		setSearch
	} = useTokenSaleCampaignsTable()

	const columns = [
		{ header: 'Name', key: 'name' },
		{
			header: 'Links',
			render: (row) => {
				const count = row?.tokenSaleCampaigns?.length
				return (
					<Link to={`/token-sale-campaigns/${row?.id}`} >{count}</Link>
				)
			}
		},
		{
			header: 'Start Date',
			render: (row) => formatDate(_.get(row, 'startDate', null), 'timestamp'),
		},
		{
			header: 'End Date',
			render: (row) => formatDate(_.get(row, 'endDate', null), 'timestamp'),
		},
		{
			header: 'Action',
			render: (row) => (
				<div className="d-flex">
					<Link to={`/edit-token-sale-campaign-group/${row.id}`} className="btn btn-primary shadow btn-xs sharp mr-1">
						<i className="fa fa-pencil"></i>
					</Link>
				</div>
			),
		}
	]

	return (
		<>
			<PageTitle title={'Token Sale Campaign Groups Management'} />

			<Row>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>Current Groups</Card.Title>

							<Link to="new-token-sale-campaign">
								<Button className="mr-2" variant="success">
									Create Campaigns
								</Button>
							</Link>
							<SearchField {...{ setSearch }} />
						</Card.Header>
						<Card.Body>
							<CustomTable
								{...{
									data,
									loading,
									limit,
									setLimit,
									setOffset,
									columns
								}}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default TokenSaleCampaignGroupsTable
