import { UploadFile } from 'components/common/uploadFile'
import { Button } from 'react-bootstrap'
import { CustomSelect } from '../../common/customSelect'
import useGoScoreBadgeForm from './useGoScoreBadgeForm'
import { CustomCheckbox } from 'components/common/customCheckbox'
import { LoadMask } from 'components/common/loadMask'

const GoScoreBadgeForm = () => {
	const { data, metrics, loading, saveChanges, handleCancel, handleChange, title } =
		useGoScoreBadgeForm()

	const uploadFileValue = typeof data.image === 'string' ? data.image : ''

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">{title}</h4>
							</div>
							<div className="card-body">
								<div className="basic-form">
									<form onSubmit={(e) => e.preventDefault()}>
										<div className="form-row">
											<div className="form-group col-md-10">
												<label>Name</label>
												<input
													value={data.name}
													type="text"
													className="form-control"
													placeholder="Badge Name"
													onChange={(e) => {
														handleChange({ name: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-2">
												<CustomCheckbox
													label="Active"
													checked={data.active}
													setChecked={(checked) => {
														handleChange({ active: checked })
													}}
												/>
											</div>
											<div className="form-group col-md-12">
												<label>Description</label>
												<textarea
													value={data.description}
													className="form-control"
													rows="4"
													placeholder="Badge Description"
													onChange={(e) => {
														handleChange({ description: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Metric</label>
												<CustomSelect
													{...{
														value: data.metricId,
														setValue: (metricId) => {
															handleChange({ metricId })
														},
														options: metrics,
													}}
												/>
											</div>
											<div className="form-group col-md-3">
												<label>Threshold</label>
												<input
													value={data.threshold}
													type="number"
													className="form-control"
													placeholder="Metric Threshold"
													onChange={(e) => {
														handleChange({ threshold: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-3">
												<label>REP awarded</label>
												<input
													value={data.reward}
													type="number"
													className="form-control"
													placeholder="Reputation Awarded"
													onChange={(e) => {
														handleChange({ reward: e.target.value })
													}}
												/>
											</div>
											<div className="input-group mb-3 col-md-2">
												<label>Image</label>
												<UploadFile
													variant="button"
													defaultImage={uploadFileValue}
													setFile={(image) => {
														handleChange({
															image,
														})
													}}
												/>
											</div>
										</div>
										<Button className="mr-2" variant="success" onClick={() => saveChanges()}>
											Save
										</Button>
										<Button variant="danger" onClick={handleCancel}>
											Cancel
										</Button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default GoScoreBadgeForm
