import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { getUsers } from 'services/UserService'
import { sendChooseEmail } from 'services/EmailService'
import { showMessage } from 'utils/notification.util'
import { useParams } from 'react-router-dom'
import { getCampaignById } from 'services/CampaignService'
import _ from 'lodash'

const useUsersTable = () => {
	const [search, setSearch] = useState('')
	const [limit, setLimit] = useState(5)
	const [offset, setOffset] = useState(0)
	const [campaignName, setCampaignName] = useState(0)
	const [users, setUsers] = useState({
		rows: [],
		count: 0,
	})
	const { campaignId } = useParams()

	const afterFetchCampaign = (data, error) => {
		if (!error) {
			setCampaignName(data?.name)
		}
	}
	const [{ loading: loadingCampaign }] = useAxios(getCampaignById(campaignId, !_.isNil(campaignId)), afterFetchCampaign)

	const afterFetchUsers = (data, error) => {
		if (!error) {
			setUsers(data)
		}
	}

	const [{ loading: loadingUsers }, fetchUsers] = useAxios(
		getUsers({ limit, offset, search, campaignId }),
		afterFetchUsers
	)

	const [{ loading: sendingEmail }, sendEmail] = useAxios(
		sendChooseEmail(),
		(_data, error) => {
			if (!error) {
				showMessage({ message: 'Email sent successfully.' })
			}
		}
	)

	const handleSendEmail = userId => () => {
		sendEmail({ data: { userId } })
	}

	useEffect(() => {
		fetchUsers()
	}, [limit, offset, search])

	const loading = loadingUsers || sendingEmail || loadingCampaign

	return {
		limit,
		loading,
		data: users,
		campaignName,
		handleSendEmail,
		setOffset,
		setLimit,
		setSearch,
		campaignId
	}
}

export default useUsersTable
