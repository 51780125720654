import _ from "lodash"

export const addObjectToLocalStorage = data => {
	Object.keys(data).forEach(key => {
		let value = _.get(data, [key], '')
		if (typeof value === 'object') value = JSON.stringify(value)
		localStorage.setItem(key, value)
	})
}

export const validateLocalStorageKey = (key, value) => {
	let storedValue = localStorage.getItem(key)
	if (storedValue === value) return true
	return false
}