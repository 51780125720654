import _ from 'lodash'
import { Link } from 'react-router-dom'
import { Row, Col, Card, Button } from 'react-bootstrap'
import useStudiosTable from './useStudiosTable'
import { formatDate } from 'utils/date.utils'
import PageTitle from 'jsx/layouts/PageTitle'
import { CustomTable } from 'components/common/customTable'
import { SearchField } from 'components/common/searchField'

const StudiosTable = () => {
	const { data, loading, limit, setLimit, setOffset, setSearch } = useStudiosTable()

	const columns = [
		{
			header: 'Join Date',
			render: (row) => formatDate(_.get(row, 'createdAt', null), 'inputDate'),
		},
		{
			header: 'Name',
			key: 'name',
		},
		{
			header: 'Action',
			render: (row) => (
				<div className="d-flex">
					<Link to={`/edit-studio/${row.id}`} className="btn btn-primary shadow btn-xs sharp mr-1">
						<i className="fa fa-pencil"></i>
					</Link>
				</div>
			),
		},
	]

	return (
		<>
			<PageTitle title={'Studio Management'} />

			<Row>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>Current Studios</Card.Title>

							<Link to="new-studio">
								<Button className="mr-2" variant="success">
									Create Studio
								</Button>
							</Link>
							<SearchField {...{ setSearch }} />
						</Card.Header>
						<Card.Body>
							<CustomTable
								{...{
									data,
									loading,
									limit,
									setLimit,
									setOffset,
									columns,
								}}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default StudiosTable
