import { Spinner } from 'react-bootstrap'
import styles from './LoadMask.module.scss'

const LoadMask = () => {
	return (
		<div className={styles.load_mask}>
			<Spinner animation="grow" variant="light" />
		</div>
	)
}

export default LoadMask
