import useAxios from 'hooks/useAxios'
import { useEffect, useState } from 'react'
import { getBadges } from 'services/GoScoreService'

const useGoScoreTable = () => {
	const [search, setSearch] = useState('')
	const [limit, setLimit] = useState(5)
	const [offset, setOffset] = useState(0)
	const [badges, setBadges] = useState({
		rows: [],
		count: 0,
	})

	const afterFetchBadges = (data, error) => {
		if (!error) {
			setBadges(data)
		}
	}

	const [{ loading }, fetchBadges] = useAxios(
		getBadges({ limit, offset, search }),
		afterFetchBadges
	)

	useEffect(() => {
		fetchBadges()
	}, [limit, offset, search])

	return {
		loading,
		badges,
		limit,
		search,
		setLimit,
		setOffset,
		setSearch,
	}
}

export default useGoScoreTable
