import { useState, useEffect } from 'react'
import axios from 'axios'
import _ from 'lodash'
import { showMessage } from 'utils/notification.util'

const useAxios = (config, callback, axiosInstance) => {
	const { autoexec } = config
	const [response, setResponse] = useState(null)
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(Boolean(autoexec))
	const [controller, setController] = useState()

	const includeInFormData = (config, extraConfig) => {
		if (_.get(config, ['headers', 'Content-Type']) === 'multipart/form-data') {
			Object.keys(extraConfig).forEach(key => {
				config.data.set(key, extraConfig[key])
			})
		}
		return config
	}

	const axiosFetch = async (extraConfig = {}) => {
		try {
			setLoading(true)
			const ctrl = new AbortController()
			setController(ctrl)
			const axiosClient = axiosInstance || axios
			let finalConfig = _.merge(config, extraConfig)
			if (
				_.get(config, ['headers', 'Content-Type']) === 'multipart/form-data'
			) {
				finalConfig = includeInFormData(config, extraConfig)
			}
			const res = await axiosClient({
				...finalConfig,
				signal: ctrl.signal,
			})
			setResponse(res)
			return res
		} catch (err) {
			setResponse(err.response)
			setError(err)
			controller && controller.abort()
		} finally {
			setLoading(false)
		}
	}

	const cancelCall = () => {
		controller && controller.abort()
	}

	useEffect(() => {
		if (config?.autoexec) {
			axiosFetch()
		}
		return cancelCall
	}, [])

	useEffect(() => {
		if (response !== null) {
			const error = response?.status !== 200
			callback && callback(response?.data, error)
			if (response?.data?.message && error) {
				showMessage({ message: response?.data?.message, type: 'error' })
			}
		}
		return cancelCall
	}, [response])

	return [{ response, error, loading }, axiosFetch, cancelCall]
}

export default useAxios
