import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	step: 0,
	formData: {
		name: '',
		subject: '',
		fromEmail: 'no-reply@filmio.com',
		fromName: 'Film.io Notifications',
		typeId: null,
		templateSlug: '',
		frequency: null,
		active: 0
	}
}

export const digestFormSlice = createSlice({
	name: 'digestForm',
	initialState,
	reducers: {
		setStep: (state, { payload }) => {
			state.step = payload
		},
		setDigestData: (state, { payload }) => {
			state.formData = payload
		},
		resetDigestForm: (state) => {
			state.formData = initialState.formData
		}
	},
})

export const { setStep, setDigestData, resetDigestForm } = digestFormSlice.actions

export default digestFormSlice.reducer
