import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { editReview, getReviews } from 'services/ReviewService'
import { showMessage } from 'utils/notification.util'

const useReviewsTable = () => {
	const [search, setSearch] = useState('')
	const [limit, setLimit] = useState(5)
	const [offset, setOffset] = useState(0)
	const [reviews, setReviews] = useState({
		rows: [],
		count: 0,
	})

	const afterFetchReviews = (data, error) => {
		if (!error) {
			setReviews(data)
		}
	}

	const [{ loading: loadingReviews }, fetchReviews] = useAxios(
		getReviews({ limit, offset, search }),
		afterFetchReviews
	)

	const afterEditReviews = (data, error) => {
		if (!error) {
			showMessage({ message: 'Review updated successfully.' })
			fetchReviews()
		}
	}

	const [{ loading: editing }, editReviewFn] = useAxios(editReview(), afterEditReviews)

	const handleApproveChange = (id, approved) => {
		console.log({id, approved})
		editReviewFn({ data: { id, approved } })
	}

	const loading = loadingReviews || editing

	useEffect(() => {
		fetchReviews()
	}, [limit, offset, search])

	return { loading, data: reviews, limit, setLimit, setOffset, setSearch, handleApproveChange }
}

export default useReviewsTable
