import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { editGoScoreStage, getGoScoreStageById } from 'services/GoScoreService'
import { getGoScoreMetrics } from 'services/SeedServices'
import { useHistory, useParams } from 'react-router-dom'
import { showMessage } from 'utils/notification.util'

const useGoScoreStageForm = () => {
	const history = useHistory()
	const { id } = useParams()
	const [metrics, setMetrics] = useState([])
	const [metricsToDelete, setMetricsToDelete] = useState([])
	const [data, setData] = useState({
		id: null,
		name: '',
		color: '',
		stageMetrics: [],
	})

	const afterFetchMetrics = (data, error) => {
		if (!error) {
			setMetrics(data)
		}
	}

	const [{ loading: loadingMetrics }] = useAxios(getGoScoreMetrics(true), afterFetchMetrics)

	const afterFetchStage = (stage, error) => {
		if (!error) {
			setData(stage)
		}
	}

	const [{ loading: loadingStage }] = useAxios(getGoScoreStageById(id), afterFetchStage)

	const afterSave = (_data, error) => {
		if (!error) {
			const message = `GoScore Stage updated successfully!`
			showMessage({ message })
			history.push('/goscore-stages')
		}
	}

	const [{ loading: saving }, saveChanges] = useAxios(
		editGoScoreStage({
			...data,
			metricsToDelete,
		}),
		afterSave
	)

	const handleChange = (payload) => {
		setData({
			...data,
			...payload,
		})
	}

	const handleChangeMetric = (payload, idx) => {
		data.stageMetrics[idx] = {
			...data.stageMetrics[idx],
			...payload,
		}
		setData({
			...data,
		})
	}

	const handleCancel = () => {
		history.goBack()
	}

	const handleAddNewMetric = () => {
		setData({
			...data,
			stageMetrics: [
				...data.stageMetrics,
				{
					stageId: data.id,
				},
			],
		})
	}

	const handleRemoveMetric = (idx) => () => {
		const toDelete = []
		setData({
			...data,
			stageMetrics: data.stageMetrics.filter((el, pos) => {
				if (pos === idx) toDelete.push(el.metricId)
				return pos !== idx
			}),
		})
		setMetricsToDelete([...metricsToDelete, ...toDelete])
	}

	const handleSave = () => {
		saveChanges()
	}

	const loading = loadingMetrics || saving || loadingStage

	return {
		data,
		metrics,
		loading,
		handleSave,
		handleCancel,
		handleChange,
		handleChangeMetric,
		handleAddNewMetric,
		handleRemoveMetric,
	}
}

export default useGoScoreStageForm
