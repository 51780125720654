import { Card, Button } from 'react-bootstrap'
import useFanSupport from './useFanSupport'
import PageTitle from 'jsx/layouts/PageTitle'
import { LoadMask } from 'components/common/loadMask'


const FanSupport = () => {
	const {
        email,
        amount,
		loading,
        setEmail,
        setAmount,
		handleAddSpecialBonus
	} = useFanSupport()

	const title = 'Support'
	const subtitle = 'Token Support'

	return (
		<>
        {loading ? <LoadMask /> : <>
			<PageTitle title={title} />
            <Card>
                <Card.Header>
                    <Card.Title>{subtitle}</Card.Title>
                    <div className='form-group col-md-4'>
                        <label>Email Address</label>
                        <input
                            value={email}
                            type='email'
                            className='form-control'
                            placeholder='User Email'
                            onChange={(e) => setEmail(e.target.value )}
                        />
                    </div>
                    <div className='form-group col-md-2'>
                        <label>FAN Amount</label>
                        <input
                            value={amount}
                            type='number'
                            className='form-control'
                            placeholder='Amount'
                            onChange={(e) => setAmount(Number(e.target.value))}
                        />
                    </div>
                    <Button className="mr-2" variant="danger" onClick={handleAddSpecialBonus}>
                        Send Special Bonus
                    </Button>
                </Card.Header>
                
            </Card>
            </>
            }
		</>
	)
}

export default FanSupport
