import _ from 'lodash'
import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { useHistory, useParams } from 'react-router-dom'
import { showMessage } from 'utils/notification.util'
import {
	editTokenSaleCampaignGroup,
	generateTokenSaleCampaigns,
	getTokenSaleCampaignGroupById
} from 'services/TokenCampaignService'
import { formatDate, getDateHour } from 'utils/date.utils'

const useTokenSaleCampaignGroupForm = () => {
	const history = useHistory()
	const [data, setData] = useState({
		name: null,
		count: null,
		startDate: null,
		endDate: null,
		startHour: 0,
		endHour: 0,
	})
	const { id } = useParams()

	const afterFetchCampaign = (response, error) => {
		if (!error) {
			const startDate = new Date(formatDate(response.startDate))
			const endDate = new Date(formatDate(response.endDate))
			const startHour = getDateHour(response?.startDate)
			const endHour = getDateHour(response?.endDate)
			setData({
				...response,
				startDate,
				endDate,
				startHour,
				endHour
			})
		}
	}
	const [{ loading: loadingCampaign }] = useAxios(getTokenSaleCampaignGroupById(id, !_.isNil(id)), afterFetchCampaign)

	const afterSave = (_data, error) => {
		if (!error) {
			const message = `Token Sale Campaign ${_.isNil(id) ? 'added' : 'updated'} successfully!`
			showMessage({ message })
			history.push('/token-sale-campaign-group')
		}
	}

	const service = _.isNil(id) ? generateTokenSaleCampaigns(data) : editTokenSaleCampaignGroup(data)
	const [{ loading: saving }, saveChanges] = useAxios(service, afterSave)

	const handleChange = (payload) => {
		setData({
			...data,
			...payload
		})
	}

	const handleCancel = () => {
		history.goBack()
	}

	const loading = saving || loadingCampaign

	return {
		id,
		data,
		loading,
		handleCancel,
		handleChange,
		saveChanges
	}
}

export default useTokenSaleCampaignGroupForm
