import { Link } from 'react-router-dom'
import { Row, Col, Card, Table, Button, Badge } from 'react-bootstrap'
import PageTitle from 'jsx/layouts/PageTitle'
import useGoScoreTable from './useGoScoreTable'
import { CustomPagination } from 'components/common/customPagination'
import { LoadMask } from 'components/common/loadMask'
import { SearchField } from 'components/common/searchField'

const GoScoreTable = () => {
	const { badges, loading, limit, setLimit, setOffset, setSearch } = useGoScoreTable()

	const renderBadges = () => {
		return badges.rows.map((badge) => {
			return (
				<tr key={`badge-${badge.id}`}>
					<td>
						<div className="d-flex align-items-center">
							<img src={badge.image} className="rounded-lg mr-2" width="24" alt="" />{' '}
						</div>
					</td>
					<td>{badge.name}</td>
					<td>{badge.reward}</td>
					<td>{badge.description}</td>
					<td>
						{badge.active ? (
							<Badge variant="success light">Active</Badge>
						) : (
							<Badge variant="danger light">Inactive</Badge>
						)}
					</td>
					<td>
						<div className="d-flex">
							<Link
								to={`/edit-badge/${badge.id}`}
								className="btn btn-primary shadow btn-xs sharp mr-1"
							>
								<i className="fa fa-pencil"></i>
							</Link>
						</div>
					</td>
				</tr>
			)
		})
	}

	return (
		<>
			<PageTitle title={'Badges associated with Go Score Journey'} />
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>Current Badges</Card.Title>

							<Link to="new-badge">
								<Button className="mr-2" variant="success">
									Create Badge
								</Button>
							</Link>
							<SearchField {...{ setSearch }} />
						</Card.Header>
						<Card.Body>
							{loading ? (
								<LoadMask />
							) : (
								<Table responsive>
									<thead>
										<tr>
											<th>
												<strong>Badge</strong>
											</th>
											<th>
												<strong>Name</strong>
											</th>
											<th>
												<strong>Reputation award</strong>
											</th>
											<th>
												<strong>Description</strong>
											</th>
											<th>
												<strong>Status</strong>
											</th>
											<th>
												<strong>Action</strong>
											</th>
										</tr>
									</thead>
									<tbody>{renderBadges()}</tbody>
								</Table>
							)}
							<CustomPagination
								{...{
									count: badges.count,
									limit,
									setLimit,
									setOffset,
								}}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default GoScoreTable
