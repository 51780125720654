import { UploadFile } from 'components/common/uploadFile'
import { Button } from 'react-bootstrap'
import { CustomSelect } from '../../common/customSelect'
import { LoadMask } from 'components/common/loadMask'
import useProjectForm from './useProjectForm'
import { UserProfile } from 'components/users/userProfile'
import { CustomCheckbox } from 'components/common/customCheckbox'

const frontBaseURL = process.env.REACT_APP_FRONT_BASE_URL

const ProjectForm = () => {
	const {
		data,
		stages,
		types,
		loading,
		statuses,
		saveChanges,
		goScoreStages,
		handleCancel,
		handleChange,
		handleSendReview
	} = useProjectForm()

	const uploadFileValue = typeof data.image === 'string' ? data.image : ''

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<>
					<div className="row mb-3">
						<div className="col-xl-12 col-lg-12">
							<a href={`${frontBaseURL}/project/${data.id}`} target="_blank" rel="noreferrer">
								<Button className="mr-2" variant="secondary">
									View on FDP
								</Button>
							</a>
							<Button className="mr-2" variant="success" onClick={handleSendReview(4)}>
								Approve Project
							</Button>
							<Button className="mr-2" variant="danger" onClick={handleSendReview(1)}>
								Reject Project
							</Button>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-8 col-lg-8">
							<div className="card">
								<div className="card-header">
									<h4 className="card-title">Project Details</h4>
								</div>
								<div className="card-body">
									<div className="basic-form">
										<form onSubmit={(e) => e.preventDefault()}>
											<div className="form-row">
												<div className="form-group col-md-6">
													<label>Title</label>
													<input
														value={data.title}
														type="text"
														className="form-control"
														placeholder="Title"
														onChange={(e) => {
															handleChange({ title: e.target.value })
														}}
													/>
												</div>
												<div className="form-group col-md-6">
													<label>Trailer</label>
													<input
														value={data.trailer}
														type="text"
														className="form-control"
														placeholder="Project Trailer"
														onChange={(e) => {
															handleChange({ trailer: e.target.value })
														}}
													/>
												</div>
												<div className="form-group col-md-6">
													<label>Budget</label>
													<input
														value={data.budget}
														type="number"
														className="form-control"
														placeholder="Project Budget"
														onChange={(e) => {
															handleChange({ budget: e.target.value })
														}}
													/>
												</div>
												<div className="form-group col-md-6">
													<label>Treatment</label>
													<input
														value={data.treatment}
														type="text"
														className="form-control"
														placeholder="Project Treatment"
														onChange={(e) => {
															handleChange({ treatment: e.target.value })
														}}
													/>
												</div>
												<div className="form-group col-md-6">
													<label>Stage</label>
													<CustomSelect
														{...{
															value: data.stageId,
															setValue: (stageId) => {
																handleChange({ stageId })
															},
															options: stages,
														}}
													/>
												</div>
												<div className="form-group col-md-6">
													<label>Status</label>
													<CustomSelect
														{...{
															value: data.statusId,
															setValue: (statusId) => {
																handleChange({ statusId })
															},
															options: statuses,
														}}
													/>
												</div>
												<div className="form-group col-md-6">
													<label>Type</label>
													<CustomSelect
														{...{
															value: data.typeId,
															setValue: (typeId) => {
																handleChange({ typeId })
															},
															options: types,
														}}
													/>
												</div>
												<div className="form-group col-md-6">
													<label>GoScore Stage</label>
													<CustomSelect
														{...{
															value: data.goScoreStageId,
															setValue: (goScoreStageId) => {
																handleChange({ goScoreStageId })
															},
															options: goScoreStages,
														}}
													/>
												</div>
												<div className="input-group mb-3 col-md-6">
													<label>Lookbook</label>
													<UploadFile
														defaultValue={uploadFileValue}
														setFile={(file) => {
															handleChange({
																image: file,
															})
														}}
													/>
												</div>
												<div className="form-group col-md-6">
													<label>Decay Effective Date</label>
													<input
														value={data.decayEffectiveDate ? new Date(data.decayEffectiveDate).toISOString().split('T')[0] : ''}
														type="date"
														className="form-control"
														placeholder="Decay Effective Date"
														onChange={(e) => {
															handleChange({ decayEffectiveDate: !isNaN(Date.parse(e.target.value)) ? e.target.value : null })
														}}
													/>
												</div>
												<div className="form-group col-md-6">
													<CustomCheckbox
														label="Is Decay Active"
														checked={data.isDecayActive}
														setChecked={(isDecayActive) => {
															const res = {
																isDecayActive,
															}
															if (isDecayActive) {
																res.decayEffectiveDate = new Date()
															}
															handleChange(res)
														}}
													/>
												</div>
												<div className="form-group col-md-6">
													<CustomCheckbox
														label="Requires Approval"
														checked={data.approval}
														setChecked={(checked) => {
															handleChange({ approval: checked })
														}}
													/>
												</div>
												<div className="form-group col-md-6">
													<CustomCheckbox
														label="Staff Pick"
														checked={data.staffPick}
														setChecked={(staffPick) => {
															handleChange({ staffPick })
														}}
													/>
												</div>
												<div className="form-group col-md-12">
													<label>Logline</label>
													<textarea
														value={data.logline}
														className="form-control"
														rows="4"
														placeholder="Project Logline"
														onChange={(e) => {
															handleChange({ logline: e.target.value })
														}}
													/>
												</div>
											</div>
											<Button className="mr-2" variant="success" onClick={() => saveChanges()}>
												Save
											</Button>
											<Button variant="danger" onClick={handleCancel}>
												Cancel
											</Button>
										</form>
									</div>
								</div>
							</div>
						</div>

						<div className="col-xl-4 col-lg-4">
							<UserProfile {...{ userId: data.userId }} />
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default ProjectForm
