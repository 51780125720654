import { DateTime } from 'luxon'

export const getUtcDate = date => DateTime.fromISO(date, { zone: 'utc' })

export const formatDate = (dateStr, format) => {
	if (!dateStr) return null
	const date = DateTime.fromISO(dateStr, { zone: 'utc' })
	switch (format) {
		case 'timestamp':
			return date.toFormat('yyyy-MM-dd HH:mm:ss')
		case 'med':
			return date.toLocaleString(DateTime.DATE_MED)
		case 'inputDate':
			return date.toFormat('yyyy-MM-dd')
		default:
			return date.toLocaleString(DateTime.DATE_SHORT)
	}
}

export const formatDateToRelative = date => {
	return DateTime.fromISO(date, { zone: 'utc' }).toRelative()
}

export const getLuxonJsDate = date => {
	return DateTime.fromISO(date, { zone: 'utc' }).toJSDate()
}

export const getDateHour = date => {
	return DateTime.fromISO(date, { zone: 'utc' }).hour
}

export const getDateNow = (format = 'yyyy-MM-dd') => {
	return DateTime.now().toFormat(format)
}

export const convertToMillis = date =>
	DateTime.fromISO(date, { zone: 'utc' }).toMillis()

export const getNowDateDiff = compareDate => {
	const endDate = DateTime.fromISO(compareDate, { zone: 'utc' })
	const dateDiff = DateTime.now({ zone: 'utc' }).diff(endDate, ['seconds', 'minutes', 'hours', 'days'] )
	return dateDiff
}

export const humanDateDiff = (startDate, endDate) => {
	const start = DateTime.fromISO(startDate, { zone: 'utc' })
	const end = DateTime.fromISO(endDate, { zone: 'utc' })
	const months = end.diff(start, ['months']).months
	const unit = ['days']
	if (months >= 1) unit.push('months')
	return end.diff(start, unit).toHuman()
}
