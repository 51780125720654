import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { getContests } from 'services/ContestService'

const useContestTable = () => {
	const [filters, setFilters] = useState({
		sortColumn: 'createdAt',
		search: '',
		direction: 'desc',
		limit: 20,
		offset: 0,
	})

	const mapColumn = (column) => {
		switch (column) {
			case "Name":
				return "name"
			case "Projects count":
				return "projectsCount"
			case "Start Date":
				return "startDate"
			case "End Date":
				return "endDate"
			case "Completed":
				return "isCompleted"
			default:
				return "createdAt"
		}
	}

	const [contests, setContests] = useState({
		rows: [],
		count: 0,
	})

	const afterFetchContests = (data, error) => {
		if (!error) {
			setContests(data)
		}
	}

	const [{ loading }, fetchContests] = useAxios(
		getContests({ 
			limit: filters.limit, 
			offset: filters.offset, 
			search: filters.search, 
			direction: filters?.direction,
			orderBy: mapColumn(filters?.sortColumn)
		}),
		afterFetchContests
	)

	const handleChangeLimit = (limit) => {
		setFilters((prv) => {
			return { ...prv, limit: limit }
		})
	}

	const setOffset = (offset) => {
		setFilters(prv => {
			if (prv.offset === offset) {
				return prv
			}
			return { ...prv, offset: offset }
		})


	}

	const handleChangeSearch = (search) => {
		setFilters(prv => {
			return { ...prv, search: search, from: null, to: null, direction: "desc", sortColumn: "createdAt", type: '', status: '', offset: 0 }
		})

	}

	const handleChangeSortColumn = (sortColumn) => {
		setFilters(prv => {
			return { ...prv, sortColumn: sortColumn }
		})
	}

	const handleChangeDirectioin = (direction) => {
		setFilters(prv => {
			return { ...prv, direction: direction }
		})
	}

	useEffect(() => {
		fetchContests()
	}, [filters])


	return {
		loading,
		data: contests,
		filters,
		handleChangeLimit,
		setOffset,
		handleChangeSearch,
		handleChangeSortColumn,
		handleChangeDirectioin,
	}
}

export default useContestTable
