import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { deleteUserRestriction, getRestrictedUsers, getUsersList, setUserRestriction } from 'services/UserService'
import { showMessage } from 'utils/notification.util'
import { useParams } from 'react-router-dom'

const useUsersRestrictedTable = () => {
	const [search, setSearch] = useState('')
	const [limit, setLimit] = useState(5)
	const [offset, setOffset] = useState(0)
	const [restrictionChange, setRestrictionChange] = useState(false)
	const [searchUser, setSearchUser] = useState('')
	const [endDate, setEndDate] = useState('')
	const [selectedUser, setSelectedUser] = useState('')
	const [userList, setUserList] = useState([])
	const [users, setUsers] = useState({
		rows: [],
		count: 0,
	})
	const { campaignId } = useParams()

	const [{ loading: loadingUsersList }, fetchSelectUsers] = useAxios(getUsersList({ search: searchUser }), (data, error) => {
		if (!error) {
			setUserList(data)
		}
	})

	const afterFetchUsers = (data, error) => {
		if (!error) {
			setUsers(data)
		}
	}

	const [{ loading: loadingUsers }, fetchUsers] = useAxios(
		getRestrictedUsers({ limit, offset, search, campaignId }),
		afterFetchUsers
	)

	const [{ loading: updatingRestriction }, addRestriction] = useAxios(
		setUserRestriction({
			restrictionEndDate: endDate,
			userId: selectedUser
		}),
		(_data, error) => {
			if (!error) {
				setRestrictionChange(!restrictionChange)
				showMessage({ message: 'User restriction updated successfully.' })
			}
		}
	)

	const [{ loading: deletingRestriction }, removeRestriction] = useAxios(
		deleteUserRestriction(),
		(data, error) => {
			if (!error) {
				setRestrictionChange(!restrictionChange)
				showMessage({ message: `Restriction removed successfully for email: ${data?.email}` })
			}
		}
	)

	const handleAddRestriction = () => {
		addRestriction()
		setEndDate('')
		setSelectedUser('')
	}

	const handleDeleteRestriction = (userId) => () => {
		removeRestriction({ data: { userId } })
	}

	useEffect(() => {
		fetchUsers()
	}, [limit, offset, search, restrictionChange])

	useEffect(() => {
		fetchSelectUsers()
	}, [searchUser])

	const loading = loadingUsers || updatingRestriction || loadingUsersList || deletingRestriction

	return {
		data: users,
		limit,
		loading,
		endDate,
		userList,
		selectedUser,
		handleDeleteRestriction,
		handleAddRestriction,
		setSelectedUser,
		setSearchUser,
		setEndDate,
		setOffset,
		setLimit,
		setSearch
	}
}

export default useUsersRestrictedTable
