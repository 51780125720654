import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { showMessage } from 'utils/notification.util'
import { addSpecialBonusService } from 'services/TransactionsService'

const useFanSupport = () => {

    const [email, setEmail] = useState('')
    const [amount, setAmount] = useState()
    const [transactionInProgess, setTransactionInProgess] = useState(false)

	const [{ loading: addSpecialBonusLoading }, addSpecialBonus] = useAxios(
		addSpecialBonusService({ email, amount }),
		(_data, error) => {
			if (!error) {
				showMessage({ message: 'Special Bonus sent successfully!' })
			} else {
                showMessage({ message: 'Special Bonus failed!' })
            }
            setTransactionInProgess(false)
		}
	)

	const handleAddSpecialBonus = () => {
        setTransactionInProgess(true)
        if(amount <= 0 || amount > 1000){
            showMessage({ message: "The amount should be 1 <= X <= 1000" })
            setTransactionInProgess(false)
            return
        }
		addSpecialBonus()
	}

	return {
        email,
        amount,
		loading: transactionInProgess || addSpecialBonusLoading,
        setEmail,
        setAmount,
		handleAddSpecialBonus
	}
}

export default useFanSupport
