import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { useHistory, useParams } from 'react-router-dom'
import { showMessage } from 'utils/notification.util'
import _ from 'lodash'
import { addContest, editContest, enrollProjects, getContestById } from 'services/ContestService'

const useContestForm = () => {
	const history = useHistory()
	const [projectUrls, setProjectUrls] = useState()
	const [data, setData] = useState({
		name: null,
		startDate: null,
		endDate: null,
		badge: null,
		badgeWinner: null,
		isCompleted: true
	})
	const { id } = useParams()

	const afterFetchContest = (data, error) => {
		if (!error) {
			data.startDate = new Date(data.startDate)
			data.endDate = new Date(data.endDate)
			setData(data)
		}
	}
	const [{ loading: loadingContest }] = useAxios(getContestById(id, !_.isNil(id)), afterFetchContest)

	const afterSave = (_data, error) => {
		if (!error) {
			const message = `Contest ${_.isNil(id) ? 'added' : 'updated'} successfully!`
			showMessage({ message })
			history.push('/contests')
		}
	}

	const service = _.isNil(id) ? addContest(data) : editContest({ ...data, id })
	const [{ loading: saving }, saveChanges] = useAxios(service, afterSave)

	const [{ loading: enrollingProjects }, enroll] = useAxios(enrollProjects(), afterSave)

	const handleChange = (payload) => {
		setData({
			...data,
			...payload,
		})
	}

	const handleCancel = () => {
		history.goBack()
	}

	const handleSave = () => {
		saveChanges()
	}

	const handleEnrollProjects = () => {
		if (projectUrls.trim() === '') {
			showMessage({ message: 'There is not any project in the list', type: 'error' })
		} else
			enroll({ data: { contestId: id, projectUrls } })
	}

	const loading = saving || loadingContest || enrollingProjects

	return {
		id,
		data,
		loading,
		projectUrls,
		handleEnrollProjects,
		setProjectUrls,
		handleCancel,
		handleChange,
		handleSave
	}
}

export default useContestForm
