export const addRetroactiveFollowsService = () => ({
	method: 'post',
	url: '/helpers/addRetroactiveFollows',
	autoexec: false,
})

export const reconcileSaleTransactionsService = () => ({
	method: 'post',
	url: '/helpers/reconcileSaleTransactions',
	autoexec: false,
})

export const deploySaleVestingSchedulesService = () => ({
	method: 'post',
	url: '/helpers/deploySaleVestingSchedules',
	autoexec: false,
})