import { Button } from 'react-bootstrap'
import useContestForm from './useContestForm'
import { LoadMask } from 'components/common/loadMask'
import { UploadFile } from 'components/common/uploadFile'
import { CustomCheckbox } from 'components/common/customCheckbox'
import { getFileDefaultValue } from 'utils/form.util'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const ContestForm = () => {
	const {
		id,
		data,
		loading,
		projectUrls,
		handleEnrollProjects,
		setProjectUrls,
		handleCancel,
		handleChange,
		handleSave,
	} = useContestForm()

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">Contest Details</h4>
							</div>
							<div className="card-body">
								<div className="basic-form">
									<form onSubmit={(e) => e.preventDefault()}>
										<div className="form-row">
											<div className="form-group col-md-6">
												<label>Contest Name*</label>
												<input
													value={data.name}
													type="text"
													className="form-control"
													placeholder="Contest Name"
													onChange={(e) => {
														handleChange({ name: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-3">
												<label>Start Date*</label>
												<DatePicker
													selected={data?.startDate}
													onChange={(startDate) => handleChange({ startDate })}
													className="form-control"
												/>
											</div>
											<div className="form-group col-md-3">
												<label>End Date*</label>
												<DatePicker
													selected={data?.endDate}
													onChange={(endDate) => handleChange({ endDate })}
													className="form-control"
												/>
											</div>
											<div className="input-group mb-3 col-md-4">
												<label>Badge</label>
												<UploadFile
													defaultValue={getFileDefaultValue(data, 'badge')}
													setFile={(badge) => {
														handleChange({ badge })
													}}
												/>
											</div>
											<div className="input-group mb-3 col-md-4">
												<label>Badge Winner</label>
												<UploadFile
													defaultValue={getFileDefaultValue(data, 'badgeWinner')}
													setFile={(badgeWinner) => {
														handleChange({ badgeWinner })
													}}
												/>
											</div>
											<div className="input-group mb-3 col-md-4">
												<label>Banner</label>
												<UploadFile
													defaultValue={getFileDefaultValue(data, 'banner')}
													setFile={(banner) => {
														handleChange({ banner })
													}}
												/>
											</div>
											<div className="form-group col-md-2">
												<CustomCheckbox
													label="Completed"
													checked={data.isCompleted}
													setChecked={(isCompleted) => {
														handleChange({ isCompleted })
													}}
												/>
											</div>
											<div className="form-group col-md-2">
												<CustomCheckbox
													label="Admin Join Only"
													checked={data.onlyAdminJoin}
													setChecked={(onlyAdminJoin) => {
														handleChange({ onlyAdminJoin })
													}}
												/>
											</div>
											<div className="form-group col-md-2">
												<CustomCheckbox
													label="Admins Preview"
													checked={data.adminPreview}
													setChecked={(adminPreview) => {
														handleChange({ adminPreview })
													}}
												/>
											</div>
											{id && (
												<div className="form-group col-md-12">
													<label>
														Projects to import (Project short url or project url list, split by
														comma)
													</label>
													<textarea
														value={projectUrls}
														className="form-control"
														rows="4"
														placeholder="Subtext"
														onChange={(e) => {
															setProjectUrls(e.target.value)
														}}
													/>
												</div>
											)}
										</div>
										<Button className="mr-2" variant="success" onClick={handleSave}>
											Save
										</Button>
										<Button variant="danger" onClick={handleCancel}>
											Cancel
										</Button>
										{id && (
											<Button className="ml-4" variant="info" onClick={handleEnrollProjects}>
												Import projects
											</Button>
										)}
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default ContestForm
