import { Button } from 'react-bootstrap'
import useIngXRestrictionAddForm from './useIngXRestrictionAddForm'
import { LoadMask } from 'components/common/loadMask'
import "react-datepicker/dist/react-datepicker.css"

const IngXRestrictionAddForm = () => {
	const {
		data,
		loading,
		handleCancel,
		handleChange,
		handleSubmit
	} = useIngXRestrictionAddForm()

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">Add a new user to IngredientX Restriction List</h4>
							</div>
							<div className="card-body">
								<div className="basic-form">
									<form onSubmit={(e) => e.preventDefault()}>
										<div className="form-row">
											<div className="form-group col-md-4">
												<label>Email*</label>
												<input
													value={data.email}
													type="text"
													className="form-control"
													placeholder="Email"
													onChange={(e) => {
														handleChange({ email: e.target.value })
													}}
												/>
											</div>
										</div>
										<Button className="mr-2" variant="success" onClick={() => handleSubmit()}>
											Add
										</Button>
										<Button variant="danger" onClick={handleCancel}>
											Cancel
										</Button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default IngXRestrictionAddForm
