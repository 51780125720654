import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { showMessage } from 'utils/notification.util'
import { getGemOptionService, updateGemOptionsService } from 'services/GemOptionService'
import { getNaturalUnlockProgressionService, getRewardBucketsService, updateRewardBucketsService } from 'services/RewardBucketService'

const useRewardUnlockConfigForm = () => {
	const [gemOptions, setGemOptions] = useState([])
	const [buckets, setBuckets] = useState([])
	const [testBreakpoints, setTestBreakpoints] = useState([])
	const [progression, setProgression] = useState([])
	const initialParams = {
		days: 0,
		totalTokens: 0,
		accFactor: 0,
		rewardDay: 0,
		newRewardAmount: 0,
	}
	const [progressionParams, setProgressionParams] = useState(initialParams)

	const [{ loading: loadGemOptions }] = useAxios(getGemOptionService(), (options, error) => {
		if (!error) {
			setGemOptions(options)
		}
	})

	const [{ loading: loadBuckets }] = useAxios(getRewardBucketsService(), (bucketList, error) => {
		if (!error) {
			setBuckets(bucketList)
		}
	})

	const [
		{ loading: loadProgression },
		getNaturalUnlockProgression
	] = useAxios(getNaturalUnlockProgressionService({ ...progressionParams, testBreakpoints }), (data, error) => {
		if (!error) {
			setProgression(data)
		}
	})

	const afterUpdateGems = (_config, error) => {
		if (!error) {
			const message = 'Gems changes saved successfully!'
			showMessage({ message })
		}
	}

	const afterUpdateBuckets = (_config, error) => {
		if (!error) {
			const message = 'Bucket changes saved successfully!'
			showMessage({ message })
		}
	}

	const [{ loading: saving }, updateGemOptions] = useAxios(updateGemOptionsService(gemOptions), afterUpdateGems)

	const [{ loading: savingBuckets }, updateRewardBuckets] = useAxios(updateRewardBucketsService(buckets), afterUpdateBuckets)

	const showMask = saving || loadGemOptions || loadBuckets || savingBuckets || loadProgression

	const handleEmulateProgression = () => {
		getNaturalUnlockProgression()
	}

	const handleAddGemOption = () => {
		setGemOptions([...gemOptions, {
			amount: 0,
			rate: 0
		}])
	}

	const handleRemoveGemOption = (idx) => () => {
		const newGemOptions = gemOptions.filter((_el, pos) => {
			return pos !== idx
		})
		setGemOptions(newGemOptions)
	}

	const handleChangeGemOption = (payload, idx) => {
		const newGemOptions = [...gemOptions]
		newGemOptions[idx] = {
			...newGemOptions[idx],
			...payload,
		}
		setGemOptions(newGemOptions)
	}

	const handleAddBucket = () => {
		setBuckets([...buckets, {
			maxDays: 0,
			minTokenAmount: 0,
			accelerationFactor: 0,
			maxTokenAmount: 0,
			timeFactor: 0,
			bucketBreakPoints: []
		}])
	}

	const handleAddBucketBreakPoint = idx => () => {
		const bucket = buckets[idx]
		bucket.bucketBreakPoints.push({
			accelerationRate: 0,
			timeRate: 0
		})
		const newBuckets = [...buckets]
		newBuckets[idx] = bucket
		setBuckets(newBuckets)
	}

	const handleRemoveBucket = (idx) => () => {
		const newBuckets = buckets.filter((_el, pos) => {
			return pos !== idx
		})
		setBuckets(newBuckets)
	}

	const handleRemoveBucketBreakPoint = (idx, bucketIdx) => () => {
		const bucketsCopy = [...buckets]
		bucketsCopy[bucketIdx].bucketBreakPoints = bucketsCopy[bucketIdx]?.bucketBreakPoints.filter((_el, pos) => {
			return pos !== idx
		})
		setBuckets(bucketsCopy)
	}

	const handleChangeBucket = (payload, idx) => {
		const newBuckets = [...buckets]
		newBuckets[idx] = {
			...newBuckets[idx],
			...payload,
		}
		setBuckets(newBuckets)
	}

	const handleChangeBucketBreakpoint = (payload, idx, bucketIdx) => {
		const newBuckets = [...buckets]
		const bucketBreakPoint = newBuckets[bucketIdx].bucketBreakPoints[idx]
		newBuckets[bucketIdx].bucketBreakPoints[idx] = {
			...bucketBreakPoint,
			...payload,
		}
		setBuckets(newBuckets)
	}

	const handleChangeProgressionParams = (payload) => {
		const newProgressionParams = {
			...progressionParams,
			...payload,
		}
		setProgressionParams(newProgressionParams)
	}

	const handleClearProgression = () => {
		setProgressionParams(initialParams)
		setProgression([])
	}

	const handleAddTestBreakpoint = () => {
		setTestBreakpoints([
			...testBreakpoints,
			{
				accelerationRate: 0,
				timeRate: 0
			}
		])
	}

	const handleRemoveTestBreakPoint = (idx) => () => {
		const newTestBreakpoints = testBreakpoints.filter((_el, pos) => {
			return pos !== idx
		})
		setTestBreakpoints(newTestBreakpoints)
	}

	const handleChangeTestBreakpoint = (payload, idx) => {
		const newTestBreakpoints = [...testBreakpoints]
		newTestBreakpoints[idx] = {
			...testBreakpoints[idx],
			...payload,
		}
		setTestBreakpoints(newTestBreakpoints)
	}

	return {
		buckets,
		showMask,
		gemOptions,
		progression,
		testBreakpoints,
		progressionParams,
		handleChangeProgressionParams,
		handleRemoveBucketBreakPoint,
		handleChangeBucketBreakpoint,
		handleRemoveTestBreakPoint,
		handleChangeTestBreakpoint,
		handleAddBucketBreakPoint,
		handleEmulateProgression,
		handleAddTestBreakpoint,
		handleClearProgression,
		handleChangeGemOption,
		handleRemoveGemOption,
		updateRewardBuckets,
		handleAddGemOption,
		handleChangeBucket,
		handleRemoveBucket,
		updateGemOptions,
		handleAddBucket
	}
}

export default useRewardUnlockConfigForm
