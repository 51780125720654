import { Button } from 'react-bootstrap'
import useEditUserForm from './useEditUserForm'
import { CustomSelect } from 'components/common/customSelect'
import { LoadMask } from 'components/common/loadMask'
import { UploadFile } from 'components/common/uploadFile'
import { UserProfile } from '../userProfile'
import { CustomCheckbox } from 'components/common/customCheckbox'
import { getFileDefaultValue } from 'utils/form.util'
import { ConfirmModal } from 'components/common/confirmModal'

const frontBaseURL = process.env.REACT_APP_FRONT_BASE_URL

const getSocialNetworkValue = (data, socialNetworkId) => {
  const socialNetwork = data.socialNetworks?.find(
    (el) => el.socialNetworkId === socialNetworkId
  )
  return socialNetwork?.username ?? ''
}

const EditUserForm = () => {
  const {
    id,
    data,
    walletData,
    loading,
    genders,
    userTypes,
    campaigns,
    countries,
    saveChanges,
    handleBanChanges,
    handleCancel,
    handleChange,
    handleWalletDataChange,
    handleSocialNetworkChange,
    isOpen,
    handleConfirm,
    handleClose,
    text,
    getBanButtonText,
    getBlacklistButtonText,
  } = useEditUserForm()

  return (
    <>
      {loading ? (
        <LoadMask />
      ) : (
        <>
          <ConfirmModal
            {...{
              isOpen,
              handleClose,
              text,
              handleConfirm,
              confirmText: 'Hell Yea!',
              cancelText: 'Nah',
            }}
          />
          <div className='row mb-3'>
            <div className='col-xl-12 col-lg-12'>
              <Button className='mr-2' variant='dark' onClick={() => {}}>
                Grant Admin Status
              </Button>
              <a
                href={`${frontBaseURL}/profile/${id}`}
                target='_blank'
                rel='noreferrer'
              >
                <Button className='mr-2' variant='secondary'>
                  View on FDP
                </Button>
              </a>
              <Button className='mr-2' variant='primary' onClick={() => {}}>
                Quarantine
              </Button>
              <a
                href={`${frontBaseURL}/profile/${id}?tab=2`}
                target='_blank'
                rel='noreferrer'
              >
                <Button className='mr-2' variant='success'>
                  View Projects
                </Button>
              </a>
              <Button
                className='mr-2'
                variant='danger'
                onClick={() => {
                  handleBanChanges({ isBanned: !data?.isBanned })
                }}
              >
                {getBanButtonText(data?.isBanned, data?.isBlacklisted)}
              </Button>
              <Button
                className='mr-2'
                variant='dark'
                style={{ backgroundColor: '#404040' }}
                onClick={() => {
                  handleBanChanges({ isBlacklisted: !data?.isBlacklisted })
                }}
              >
                {getBlacklistButtonText(data?.isBanned, data?.isBlacklisted)}
              </Button>
            </div>
          </div>
          <div className='row'>
            <div className='col-xl-6 col-sm-4 m-t35'>
              <div className='card card-coin'>
                <div className='card-body text-center'>
                  <svg
                    className='mb-3 currency-icon'
                    width='80'
                    height='80'
                    viewBox='0 0 80 80'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle cx='40' cy='40' r='40' fill='white' />
                    <path
                      d='M40.725 0.00669178C18.6241 -0.393325 0.406678 17.1907 0.00666126 39.275C-0.393355 61.3592 17.1907 79.5933 39.2749 79.9933C61.3592 80.3933 79.5933 62.8093 79.9933 40.7084C80.3933 18.6241 62.8092 0.390041 40.725 0.00669178ZM39.4083 72.493C21.4909 72.1597 7.17362 57.3257 7.50697 39.4083C7.82365 21.4909 22.6576 7.17365 40.575 7.49033C58.5091 7.82368 72.8096 22.6576 72.493 40.575C72.1763 58.4924 57.3257 72.8097 39.4083 72.493Z'
                      fill='#00ADA3'
                    />
                    <path
                      d='M40.5283 10.8305C24.4443 10.5471 11.1271 23.3976 10.8438 39.4816C10.5438 55.549 23.3943 68.8662 39.4783 69.1662C55.5623 69.4495 68.8795 56.599 69.1628 40.5317C69.4462 24.4477 56.6123 11.1305 40.5283 10.8305ZM40.0033 19.1441L49.272 35.6798L40.8133 30.973C40.3083 30.693 39.6966 30.693 39.1916 30.973L30.7329 35.6798L40.0033 19.1441ZM40.0033 60.8509L30.7329 44.3152L39.1916 49.022C39.4433 49.162 39.7233 49.232 40.0016 49.232C40.28 49.232 40.56 49.162 40.8117 49.022L49.2703 44.3152L40.0033 60.8509ZM40.0033 45.6569L29.8296 39.9967L40.0033 34.3364L50.1754 39.9967L40.0033 45.6569Z'
                      fill='#00ADA3'
                    />
                  </svg>
                  <h2 className='text-black mb-2 font-w600'>$168,331.09</h2>
                  <p className='mb-0 fs-14'>
                    <svg
                      width='29'
                      height='22'
                      viewBox='0 0 29 22'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g filter='url(#filter0_d1)'>
                        <path
                          d='M5 16C5.91797 14.9157 8.89728 11.7277 10.5 10L16.5 13L23.5 4'
                          stroke='#2BC155'
                          strokeWidth='2'
                          strokeLinecap='round'
                        />
                      </g>
                      <defs>
                        <filter
                          id='filter0_d1'
                          x='-3.05176e-05'
                          y='-6.10352e-05'
                          width='28.5001'
                          height='22.0001'
                          filterUnits='userSpaceOnUse'
                          colorInterpolationFilters='sRGB'
                        >
                          <feFlood
                            floodOpacity='0'
                            result='BackgroundImageFix'
                          />
                          <feColorMatrix
                            in='SourceAlpha'
                            type='matrix'
                            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                          />
                          <feOffset dy='1' />
                          <feGaussianBlur stdDeviation='2' />
                          <feColorMatrix
                            type='matrix'
                            values='0 0 0 0 0.172549 0 0 0 0 0.72549 0 0 0 0 0.337255 0 0 0 0.61 0'
                          />
                          <feBlend
                            mode='normal'
                            in2='BackgroundImageFix'
                            result='effect1_dropShadow'
                          />
                          <feBlend
                            mode='normal'
                            in='SourceGraphic'
                            in2='effect1_dropShadow'
                            result='shape'
                          />
                        </filter>
                      </defs>
                    </svg>
                    <span className='text-success mr-1'>45%</span>This week
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-6 col-sm-4 m-t35'>
              <div className='card card-coin'>
                <div className='card-body text-center'>
                  <svg
                    className='mb-3 currency-icon'
                    width='80'
                    height='80'
                    viewBox='0 0 80 80'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle cx='40' cy='40' r='40' fill='white' />
                    <path
                      d='M40 0C17.9083 0 0 17.9083 0 40C0 62.0917 17.9083 80 40 80C62.0917 80 80 62.0917 80 40C80 17.9083 62.0917 0 40 0ZM40 72.5C22.0783 72.5 7.5 57.92 7.5 40C7.5 22.08 22.0783 7.5 40 7.5C57.9217 7.5 72.5 22.0783 72.5 40C72.5 57.9217 57.92 72.5 40 72.5Z'
                      fill='#FFAB2D'
                    />
                    <path
                      d='M42.065 41.2983H36.8133V49.1H42.065C43.125 49.1 44.1083 48.67 44.7983 47.9483C45.52 47.2566 45.95 46.275 45.95 45.1833C45.9517 43.0483 44.2 41.2983 42.065 41.2983Z'
                      fill='#FFAB2D'
                    />
                    <path
                      d='M40 10.8333C23.9167 10.8333 10.8333 23.9166 10.8333 40C10.8333 56.0833 23.9167 69.1666 40 69.1666C56.0833 69.1666 69.1667 56.0816 69.1667 40C69.1667 23.9183 56.0817 10.8333 40 10.8333ZM45.935 53.5066H42.495V58.9133H38.8867V53.5066H36.905V58.9133H33.28V53.5066H26.9067V50.1133H30.4233V29.7799H26.9067V26.3866H33.28V21.0883H36.905V26.3866H38.8867V21.0883H42.495V26.3866H45.6283C47.3783 26.3866 48.9917 27.1083 50.1433 28.26C51.295 29.4116 52.0167 31.025 52.0167 32.775C52.0167 36.2 49.3133 38.995 45.935 39.1483C49.8967 39.1483 53.0917 42.3733 53.0917 46.335C53.0917 50.2816 49.8983 53.5066 45.935 53.5066Z'
                      fill='#FFAB2D'
                    />
                    <path
                      d='M44.385 36.5066C45.015 35.8766 45.3983 35.0316 45.3983 34.08C45.3983 32.1916 43.8633 30.655 41.9733 30.655H36.8133V37.52H41.9733C42.91 37.52 43.77 37.12 44.385 36.5066Z'
                      fill='#FFAB2D'
                    />
                  </svg>
                  <h2 className='text-black mb-2 font-w600'>$24,098</h2>
                  <p className='mb-0 fs-13'>
                    <svg
                      width='29'
                      height='22'
                      viewBox='0 0 29 22'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g filter='url(#filter0_d2)'>
                        <path
                          d='M5 16C5.91797 14.9157 8.89728 11.7277 10.5 10L16.5 13L23.5 4'
                          stroke='#2BC155'
                          strokeWidth='2'
                          strokeLinecap='round'
                        />
                      </g>
                      <defs>
                        <filter
                          id='filter0_d2'
                          x='-3.05176e-05'
                          y='-6.10352e-05'
                          width='28.5001'
                          height='22.0001'
                          filterUnits='userSpaceOnUse'
                          colorInterpolationFilters='sRGB'
                        >
                          <feFlood
                            floodOpacity='0'
                            result='BackgroundImageFix'
                          />
                          <feColorMatrix
                            in='SourceAlpha'
                            type='matrix'
                            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                          />
                          <feOffset dy='1' />
                          <feGaussianBlur stdDeviation='2' />
                          <feColorMatrix
                            type='matrix'
                            values='0 0 0 0 0.172549 0 0 0 0 0.72549 0 0 0 0 0.337255 0 0 0 0.61 0'
                          />
                          <feBlend
                            mode='normal'
                            in2='BackgroundImageFix'
                            result='effect1_dropShadow'
                          />
                          <feBlend
                            mode='normal'
                            in='SourceGraphic'
                            in2='effect1_dropShadow'
                            result='shape'
                          />
                        </filter>
                      </defs>
                    </svg>
                    <span className='text-success mr-1'>45%</span>This week
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-xl-8 col-lg-8'>
              <div className='card'>
                <div className='card-header'>
                  <h4 className='card-title'>User Details</h4>
                </div>
                <div className='card-body'>
                  <div className='basic-form'>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className='form-row'>
                        <div className='form-group col-md-6'>
                          <label>Display Name</label>
                          <input
                            value={data.displayName}
                            type='text'
                            className='form-control'
                            placeholder='Display Name'
                            onChange={(e) => {
                              handleChange({ displayName: e.target.value })
                            }}
                          />
                        </div>
                        <div className='form-group col-md-6'>
                          <label>Email Address</label>
                          <input
                            value={data.email}
                            type='email'
                            className='form-control'
                            placeholder='User Email'
                            onChange={(e) => {
                              handleChange({ email: e.target.value })
                            }}
                          />
                        </div>
                        <div className='form-group col-md-6'>
                          <label>First Name</label>
                          <input
                            value={data.firstName}
                            type='text'
                            className='form-control'
                            placeholder='First Name'
                            onChange={(e) => {
                              handleChange({ firstName: e.target.value })
                            }}
                          />
                        </div>
                        <div className='form-group col-md-6'>
                          <label>Last Name</label>
                          <input
                            value={data.lastName}
                            type='text'
                            className='form-control'
                            placeholder='Last Name'
                            onChange={(e) => {
                              handleChange({ lastName: e.target.value })
                            }}
                          />
                        </div>
                        <div className='form-group col-md-6'>
                          <label>Username</label>
                          <input
                            value={data.username}
                            type='text'
                            className='form-control'
                            placeholder='Username'
                            onChange={(e) => {
                              handleChange({ username: e.target.value })
                            }}
                          />
                        </div>
                        <div className='form-group col-md-6'>
                          <label>New Password</label>
                          <input
                            value={data.password}
                            type='password'
                            className='form-control'
                            placeholder='New User Password'
                            onChange={(e) => {
                              handleChange({ password: e.target.value })
                            }}
                          />
                        </div>
                        <div className='form-group col-md-6'>
                          <label>Short Url</label>
                          <input
                            value={data.shortUrl}
                            type='shortUrl'
                            className='form-control'
                            placeholder='User Short Url'
                            onChange={(e) => {
                              handleChange({ shortUrl: e.target.value })
                            }}
                          />
                        </div>
                        <div className='form-group col-md-6'>
                          <label>Country</label>
                          <CustomSelect
                            {...{
                              value: data.countryId,
                              setValue: (countryId) => {
                                handleChange({ countryId })
                              },
                              options: countries,
                            }}
                          />
                        </div>
                        <div className='form-group col-md-2'>
                          <CustomCheckbox
                            label='Admin'
                            checked={data.isAdmin}
                            setChecked={(checked) => {
                              handleChange({ isAdmin: checked })
                            }}
                          />
                        </div>
                        <div className='form-group col-md-2'>
                          <CustomCheckbox
                            label='Vesting'
                            checked={data.isVesting}
                            setChecked={(checked) => {
                              handleChange({ isVesting: checked })
                            }}
                          />
                        </div>
                        <div className='form-group col-md-2'>
                          <CustomCheckbox
                            label='Featured'
                            checked={data.featured}
                            setChecked={(featured) => {
                              handleChange({ featured })
                            }}
                          />
                        </div>
                        <div className='form-group col-md-6'>
                          <label>Campaign</label>
                          <CustomSelect
                            {...{
                              value: data.campaignId,
                              setValue: (campaignId) => {
                                handleChange({ campaignId })
                              },
                              options: campaigns,
                            }}
                          />
                        </div>
                        <div className='form-group col-md-6'>
                          <label>Magic Wallet address</label>
                          <input
                            value={walletData.magicWalletAddress}
                            type='text'
                            className='form-control'
                            disabled
                          />
                        </div>
                        <div className='form-group col-md-6'>
                          <label>SCW address</label>
                          <input
                            value={walletData.scw}
                            type='text'
                            className='form-control'
                            disabled
                          />
                        </div>
                        <div className='form-group col-md-6'>
                          <label>NCW address</label>
                          <input
                            value={walletData.ncWalletAddress}
                            type='text'
                            className='form-control'
                            disabled
                          />
                        </div>
                        <div className='form-group col-md-6'>
                          <CustomCheckbox
                            label='Is fraud?'
                            checked={walletData.isFraud}
                            setChecked={(isFraud) => {
                              handleWalletDataChange({ isFraud })
                            }}
                          />
                        </div>

                        <div className='col-lg-12'>
                          <div className='form-group'>
                            <label
                              htmlFor='about'
                              className='text-black font-w600'
                            >
                              About
                            </label>
                            <textarea
                              value={data.about}
                              rows={8}
                              className='form-control'
                              name='about'
                              placeholder='User About'
                              onChange={(e) => {
                                handleChange({ about: e.target.value })
                              }}
                            />
                          </div>
                        </div>
                        <div className='col-lg-12'>
                          <div className='form-group'>
                            <label
                              htmlFor='bio'
                              className='text-black font-w600'
                            >
                              Bio
                            </label>
                            <textarea
                              value={data.bio}
                              rows={8}
                              className='form-control'
                              name='about'
                              placeholder='User Bio'
                              onChange={(e) => {
                                handleChange({ bio: e.target.value })
                              }}
                            />
                          </div>
                        </div>
                        <div className='input-group mb-3 col-md-6'>
                          <label>Banner</label>
                          <UploadFile
                            defaultValue={getFileDefaultValue(
                              data,
                              'headerImage'
                            )}
                            setFile={(headerImage) => {
                              handleChange({ headerImage })
                            }}
                          />
                        </div>
                        <div className='input-group mb-3 col-md-6'>
                          <label>Avatar</label>
                          <UploadFile
                            defaultValue={getFileDefaultValue(data, 'avatar')}
                            setFile={(avatar) => {
                              handleChange({ avatar })
                            }}
                          />
                        </div>
                        <div className='form-group col-md-6'>
                          <label>Gender</label>
                          <CustomSelect
                            {...{
                              value: data.genderId,
                              setValue: (genderId) => {
                                handleChange({ genderId })
                              },
                              options: genders,
                            }}
                          />
                        </div>
                        <div className='form-group col-md-6'>
                          <label>Profile facebook</label>
                          <input
                            value={getSocialNetworkValue(data, 1)}
                            type='text'
                            className='form-control'
                            placeholder='Profile facebook'
                            onChange={(e) => {
                              handleSocialNetworkChange(1, e.target.value)
                            }}
                          />
                        </div>
                        <div className='form-group col-md-6'>
                          <label>Profile Instagram</label>
                          <input
                            value={getSocialNetworkValue(data, 4)}
                            type='text'
                            className='form-control'
                            placeholder='Profile Instagram'
                            onChange={(e) => {
                              handleSocialNetworkChange(4, e.target.value)
                            }}
                          />
                        </div>
                        <div className='form-group col-md-6'>
                          <label>Profile twitter</label>
                          <input
                            value={getSocialNetworkValue(data, 3)}
                            type='text'
                            className='form-control'
                            placeholder='Profile twitter'
                            onChange={(e) => {
                              handleSocialNetworkChange(3, e.target.value)
                            }}
                          />
                        </div>
                        <div className='form-group col-md-6'>
                          <label>Profile imdb</label>
                          <input
                            value={getSocialNetworkValue(data, 2)}
                            type='text'
                            className='form-control'
                            placeholder='Profile imdb'
                            onChange={(e) => {
                              handleSocialNetworkChange(2, e.target.value)
                            }}
                          />
                        </div>
                        <div className='form-group col-md-6'>
                          <label>Role</label>
                          <CustomSelect
                            {...{
                              value: data.typeId,
                              setValue: (typeId) => {
                                handleChange({ typeId })
                              },
                              options: userTypes,
                            }}
                          />
                        </div>
                        <div className='col-lg-12'>
                          <div className='form-group'>
                            <label
                              htmlFor='adminNote'
                              className='text-black font-w600'
                            >
                              Admin note
                            </label>
                            <textarea
                              value={data.adminNote}
                              rows={8}
                              className='form-control'
                              name='adminNote'
                              placeholder='Admin note'
                              onChange={(e) => {
                                handleChange({ adminNote: e.target.value })
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <Button
                        className='mr-2'
                        variant='success'
                        onClick={() => saveChanges()}
                      >
                        Save
                      </Button>
                      <Button variant='danger' onClick={handleCancel}>
                        Cancel
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-xl-4 col-lg-4'>
              <UserProfile {...{ userId: id }} />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default EditUserForm
