import { Table } from 'react-bootstrap'
import { CustomPagination } from 'components/common/customPagination'
import { LoadMask } from 'components/common/loadMask'
import _ from 'lodash'

const CustomTable = ({
	data,
	loading,
	limit,
	setLimit,
	setOffset,
	columns,
	sortColumn,
	setSortColumn,
	order,
	setOrder,
	maxLimit
}) => {
	const renderColumnHeaders = () => {

		return columns.map((column) => (
			<th key={`header-${column.header}`}>
				<span className={order && 'ordered-column'}>
					<strong>{column.header}</strong>
					{
						order && column.header === sortColumn && (
							<span className='show-icon'>
								{order === "desc" && (<i className="fa fa-arrow-down" onClick={() => {
									setOrder("asc");
									setSortColumn(column.header)
								}} aria-hidden="true"></i>)}
								{order === "asc" && (<i className="fa fa-arrow-up" onClick={() => {
									setOrder("desc");
									setSortColumn(column.header)
								}} aria-hidden="true"></i>)}
							</span>)

					}
					{
						order && column.header !== sortColumn && (
							<span className='hide-icon'>
								<i className="fa fa-arrow-up" onClick={() => { setOrder("asc"); setSortColumn(column.header) }} aria-hidden="true"></i>
							</span>)

					}
				</span>


			</th>
		))
	}
	const renderColumns = (row) => {
		return columns.map((column, idx) => {
			let value = ''
			if (column.key) value = _.get(row, column.key, '')
			if (column.render) value = column.render(row)
			const textAlign = column.textAlign ?? 'left'
			return <td key={`column-${idx}`} style={{ textAlign }}>{value}</td>
		})
	}

	const renderRows = () => {
		return data.rows.map((row) => {
			return <tr key={`row-${row.id}`}>{renderColumns(row)}</tr>
		})
	}

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<Table responsive>
					<thead>
						<tr>{renderColumnHeaders()}</tr>
					</thead>
					<tbody>{renderRows()}</tbody>
				</Table>
			)}
			<CustomPagination
				{...{
					count: data.count,
					limit,
					setLimit,
					setOffset,
					maxLimit
				}}
			/>
		</>
	)
}

export default CustomTable
