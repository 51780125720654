import { CustomSelect } from 'components/common/customSelect'
import _ from 'lodash'
import { Button } from 'react-bootstrap'

const Step2 = ({
	handleChange,
	data,
	handleSave,
	handleCancel,
	notificationTypes
}) => {
	const getNotificationFields = () => {
		if (_.isNil(notificationType?.fields)) return ''
		return notificationType?.fields.split(',').map((el, i) => { return <li key={`item-${i}`}>{el}</li> })
	}
	const notificationType = notificationTypes.find(el => el?.id === data?.typeId)
	const availableFields = getNotificationFields()
	return (
		<div className="col-xl-12 col-lg-12">
			<div className="card">
				<div className="card-header">
					<h4 className="card-title">Notification Type</h4>
				</div>
				<div className="card-body">
					<div className="basic-form">
						<form onSubmit={(e) => e.preventDefault()}>
							<div className="form-row">
								<div className="form-group col-md-12">
									<label>Notification Type</label>
									<CustomSelect
										{...{
											value: data.typeId,
											setValue: (typeId) => {
												handleChange({ typeId })
											},
											options: notificationTypes,
										}}
									/>
								</div>
								<div className="form-group col-md-12">
									<label>Available Fields: </label>
									<br />
									<ul>
										{availableFields}
									</ul>
								</div>
							</div>
							<Button className="mr-2" variant="danger" onClick={handleCancel}>
								Cancel
							</Button>
							<Button variant="success" onClick={handleSave}>
								Save and continue
							</Button>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Step2
