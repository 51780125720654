import { Button } from 'react-bootstrap'
import useGoScoreConfigForm from './useGoScoreConfigForm'
import { LoadMask } from 'components/common/loadMask'
import { CustomSelect } from 'components/common/customSelect'

const GoScoreConfigForm = () => {
	const {
		data,
		stages,
		showMask,
		handleChange,
		handleSaveChanges,
		handleUpdateGoscore,
		handleSendProjectDecayNotificationEmails,
		setTestEmails,
		testEmails
	} = useGoScoreConfigForm()

	return (
		<>
			{showMask ? (
				<LoadMask />
			) : (
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">Update GoScore Configuration Params</h4>
							</div>
							<div className="card-body">
								<div className="basic-form">
									<form onSubmit={(e) => e.preventDefault()}>
										<div className="form-row">
											<div className="form-group col-md-4">
												<label>Perfect Score</label>
												<input
													value={data.perfectScore}
													type="number"
													className="form-control"
													placeholder="Perfect Score"
													onChange={(e) => {
														handleChange({ perfectScore: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Review Weightage</label>
												<input
													value={data.reviewWeightage}
													type="number"
													className="form-control"
													placeholder="Review Weightage"
													onChange={(e) => {
														handleChange({ reviewWeightage: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Rating Weightage</label>
												<input
													value={data.ratingWeightage}
													type="number"
													className="form-control"
													placeholder="Rating Weightage"
													onChange={(e) => {
														handleChange({ ratingWeightage: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Fan Token Weightage</label>
												<input
													value={data.fanTokenWeightage}
													type="number"
													className="form-control"
													placeholder="Fan Token Weightage"
													onChange={(e) => {
														handleChange({ fanTokenWeightage: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Share Weightage</label>
												<input
													value={data.shareWeightage}
													type="number"
													className="form-control"
													placeholder="Share Weightage"
													onChange={(e) => {
														handleChange({ shareWeightage: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Followers Weightage</label>
												<input
													value={data.followersWeightage}
													type="number"
													className="form-control"
													placeholder="Followers Weightage"
													onChange={(e) => {
														handleChange({ followersWeightage: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Views Weightage</label>
												<input
													value={data.viewsWeightage}
													type="number"
													className="form-control"
													placeholder="Views Weightage"
													onChange={(e) => {
														handleChange({ viewsWeightage: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Fan Token Category Weightage</label>
												<input
													value={data.fanTokenCatWeightage}
													type="number"
													className="form-control"
													placeholder="Fan Token Category Weightage"
													onChange={(e) => {
														handleChange({ fanTokenCatWeightage: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Share Category Weightage</label>
												<input
													value={data.shareCatWeightage}
													type="number"
													className="form-control"
													placeholder="Share Category Weightage"
													onChange={(e) => {
														handleChange({ shareCatWeightage: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Followers Category tWeightage</label>
												<input
													value={data.followersCatWeightage}
													type="number"
													className="form-control"
													placeholder="Followers Category tWeightage"
													onChange={(e) => {
														handleChange({ followersCatWeightage: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Views Category Weightage</label>
												<input
													value={data.viewsCatWeightage}
													type="number"
													className="form-control"
													placeholder="Views Category Weightage"
													onChange={(e) => {
														handleChange({ viewsCatWeightage: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Rating Review Category Weightage</label>
												<input
													value={data.ratingreviewCatWeightage}
													type="number"
													className="form-control"
													placeholder="Rating Review Category Weightage"
													onChange={(e) => {
														handleChange({ ratingreviewCatWeightage: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Decay Period Days</label>
												<input
													value={data.decayPeriodDays}
													type="number"
													className="form-control"
													placeholder="Decay Period Days"
													onChange={(e) => {
														handleChange({ decayPeriodDays: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Decay Rate</label>
												<input
													value={data.decayRate}
													type="number"
													className="form-control"
													placeholder="Decay Rate"
													onChange={(e) => {
														handleChange({ decayRate: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Bonus Multiplier</label>
												<input
													value={data.bonusMultiplier}
													type="number"
													className="form-control"
													placeholder="Bonus Multiplier"
													onChange={(e) => {
														handleChange({ bonusMultiplier: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Benchmark Newsreel Posts</label>
												<input
													value={data.benchmarkNewsreelPosts}
													type="number"
													className="form-control"
													placeholder="Benchmark Newsreel Posts"
													onChange={(e) => {
														handleChange({ benchmarkNewsreelPosts: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Last Newsreel Weight</label>
												<input
													value={data.lastNewsreelWeight}
													type="number"
													className="form-control"
													placeholder="Last Newsreel Weight"
													onChange={(e) => {
														handleChange({ lastNewsreelWeight: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Newsreel Activity Weight</label>
												<input
													value={data.newsreelActivityWeight}
													type="number"
													className="form-control"
													placeholder="Newsreel Activity Weight"
													onChange={(e) => {
														handleChange({ newsreelActivityWeight: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Effective Date</label>
												<input
													value={new Date(data.effectiveDate)?.toISOString().split('T')[0]}
													type="date"
													className="form-control"
													placeholder="Effective Date"
													onChange={(e) => {
														handleChange({ effectiveDate: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Lower Threshold</label>
												<input
													value={data.lowerThreshold}
													type="number"
													className="form-control"
													placeholder="Lower Threshold"
													onChange={(e) => {
														handleChange({ lowerThreshold: e.target.value })
													}}
												/>
											</div>
											<div className='form-group col-md-4'>
												<label>Pre Decay Days (Use Comma Seperation Ex. 2,5)</label>
												<input
												value={data.preDecayDays}
												className='form-control'
												placeholder='Pre Decay Days'
												onChange={(e) => {
													handleChange({ preDecayDays: e.target.value })
												}}
												/>
											</div>
											<div className='form-group col-md-4'>
												<label>Post Decay Days (Use Comma Seperation Ex. 14,20)</label>
												<input
												value={data.postDecayDays}
												className='form-control'
												placeholder='Post Decay Days'
												onChange={(e) => {
													handleChange({ postDecayDays: e.target.value })
												}}
												/>
											</div>
											<div className='form-group col-md-4'>
												<label>Project Decay Notification Test Emails (Use Comma Seperation Ex. test1@example.com,test2@example.com)</label>
												<input
												value={testEmails}
												className='form-control'
												placeholder='Project Decay Notification Test Emails'
												onChange={(e) => {
													handleChange({ testEmails: e.target.value })
												}}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>GoScore Stage Allowed</label>
												<CustomSelect
													{...{
														value: data.goScoreStageAllowed,
														setValue: (goScoreStageAllowed) => {
															handleChange({ goScoreStageAllowed })
														},
														options: stages,
													}}
												/>
											</div>
										</div>
										<Button className="mr-2" variant="success" onClick={handleSaveChanges}>
											Save
										</Button>
										<Button className="mr-2" variant="primary" onClick={handleUpdateGoscore}>
											Update GoScores
										</Button>
										<Button variant="secondary" onClick={handleSendProjectDecayNotificationEmails}>
											Send Project Decay Notification Email
										</Button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default GoScoreConfigForm
