export const getTransactions = (params) => ({
	method: 'get',
	url: '/admin/transactions/userRelated',
	params,
	autoexec: false
})

export const getTransactionProject = (params) => ({
	method: 'get',
	url: '/admin/transactions/project',
	params,
	autoexec: false
})

export const getTransactionStatus = (params) => ({
	method: 'get',
	url: '/admin/transactions/status',
	params,
	autoexec: false
})

export const getTransactionType = (params) => ({
	method: 'get',
	url: '/admin/transactions/type',
	params,
	autoexec: false
})

export const reconcileTransactionService = (data) => ({
	method: 'post',
	url: '/profile/reconcile/transaction',
	data,
	autoexec: false
})

export const getTransactionsInProgress = (params) => ({
	method: 'get',
	url: '/admin/transactions/inProgress',
	params,
	autoexec: false
})

export const failTransactionService = (data) => ({
	method: 'post',
	url: '/admin/failTransaction',
	data,
	autoexec: false
})

export const failTransactionsInProgressService = (data) => ({
	method: 'post',
	url: '/admin/failTransactionsInProgress',
	data,
	autoexec: false
})

export const addSpecialBonusService = (data) => ({
	method: 'post',
	url: '/profile/specialBonus',
	data,
	autoexec: false
})
