import _ from 'lodash'
import { Link } from 'react-router-dom'
import { Row, Col, Card, Button, Badge } from 'react-bootstrap'
import useCampaignsTable from './useCampaignsTable'
import PageTitle from 'jsx/layouts/PageTitle'
import { CustomTable } from 'components/common/customTable'
import { SearchField } from 'components/common/searchField'
import { formatDate } from 'utils/date.utils'

const frontBaseURL = process.env.REACT_APP_FRONT_BASE_URL

const CampaignsTable = () => {
	const {
		data,
		limit,
		loading,
		setOffset,
		setLimit,
		setSearch
	} = useCampaignsTable()

	const columns = [
		{
			header: 'QR Code',
			render: (row) => (
				<div className="d-flex align-items-center">
					<a href={row?.qrImage} target="_blank" rel="noreferrer">
						<img src={row.qrImage} className="rounded-md mr-2" width="24" alt="" />
					</a>
				</div>
			),
		},
		{ header: 'Name', key: 'name' },
		{
			header: 'URL',
			render: (row) => (
				<a href={`${frontBaseURL}/onboard/join/${row?.slug}`} target="_blank" rel="noreferrer">
					{row?.slug}
				</a>
			),
		},
		{ header: 'Signup Limit', key: 'limitSignups' },
		{
			header: 'Accounts created',
			render: (row) => (
				<Link to={`/campaign/users/${row?.id}`} >{row?.usersCount}</Link>
			)
		},
		{
			header: 'Is Airdrop',
			render: (row) => (
				<>
					{row.isAirdrop ? (
						<Badge variant="success light">Yes</Badge>
					) : (
						<Badge variant="danger light">No</Badge>
					)}
				</>
			)
		},
		{
			header: 'Start Date',
			render: (row) => formatDate(_.get(row, 'startDate', null), 'inputDate'),
		},
		{
			header: 'End Date',
			render: (row) => formatDate(_.get(row, 'endDate', null), 'inputDate'),
		},
		{
			header: 'Action',
			render: (row) => (
				<div className="d-flex">
					<Link to={`/edit-campaign/${row.id}`} className="btn btn-primary shadow btn-xs sharp mr-1">
						<i className="fa fa-pencil"></i>
					</Link>
				</div>
			),
		},
	]

	return (
		<>
			<PageTitle title={'Campaign Management'} />

			<Row>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>Current Campaigns</Card.Title>

							<Link to="/new-campaign">
								<Button className="mr-2" variant="success">
									Create Campaign
								</Button>
							</Link>
							<SearchField {...{ setSearch }} />
						</Card.Header>
						<Card.Body>
							<CustomTable
								{...{
									data,
									loading,
									limit,
									setLimit,
									setOffset,
									columns,
								}}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default CampaignsTable
