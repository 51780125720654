import { Link } from 'react-router-dom'
import { Row, Col, Card, Button } from 'react-bootstrap'
import useProjectCampaignsTable from './useProjectCampaignsTable'
import PageTitle from 'jsx/layouts/PageTitle'
import { CustomTable } from 'components/common/customTable'
import { SearchField } from 'components/common/searchField'

const frontBaseURL = process.env.REACT_APP_FRONT_BASE_URL

const ProjectCampaignsTable = () => {
	const {
		data,
		limit,
		loading,
		setOffset,
		setLimit,
		setSearch
	} = useProjectCampaignsTable()

	const columns = [
		{
			header: 'Project',
			render: (row) => (
				<a href={`${frontBaseURL}/project/${row?.slug}`} target="_blank" rel="noreferrer">
					{row?.title}
				</a>
			)
		},
		{
			header: 'URL',
			render: (row) => (
				<a href={`${frontBaseURL}/join/c/${row?.user?.shortUrl}/${row?.shortUrl}`} target="_blank" rel="noreferrer">
					{row?.slug}
				</a>
			),
		},
		{ header: 'Signup Limit', key: 'limit' },
		{
			header: 'Accounts created',
			render: (row) => (
				row?.joinsCount > 0 ? <Link to={`/project-campaign/users/${row?.id}`} >{row?.joinsCount}</Link> : 0
			)
		}
	]

	return (
		<>
			<PageTitle title={'Campaign Management'} />

			<Row>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>Current Campaigns</Card.Title>
							<SearchField {...{ setSearch }} />
						</Card.Header>
						<Card.Body>
							<CustomTable
								{...{
									data,
									loading,
									limit,
									setLimit,
									setOffset,
									columns,
								}}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default ProjectCampaignsTable
