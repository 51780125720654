export const getGemTransactionsService = (params) => ({
	method: 'get',
	url: '/gemTransaction',
	params,
	autoexec: false
})

export const getGemAnalyticsService = () => ({
	method: 'get',
	url: '/gemTransaction/analytics',
	autoexec: true
})
