import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { getDashboardUsers } from 'services/UserService'
import { sendChooseEmail } from 'services/EmailService'
import { showMessage } from 'utils/notification.util'
import { useParams } from 'react-router-dom'

const useUsersDasboradTable = () => {
	const [search, setSearch] = useState('')
	const [limit, setLimit] = useState(5)
	const [offset, setOffset] = useState(0)
	const [users, setUsers] = useState({
		rows: [],
		count: 0,
	})
	const { campaignId } = useParams()

	const afterFetchUsers = (data, error) => {
		if (!error) {
			setUsers(data)
		}
	}

	const [{ loading: loadingUsers }, fetchUsers] = useAxios(
		getDashboardUsers({ limit, offset, search, campaignId }),
		afterFetchUsers
	)

	const [{ loading: sendingEmail }, sendEmail] = useAxios(sendChooseEmail(), (_data, error) => {
		if (!error) {
			showMessage({ message: 'Email sent successfully.' })
		}
	})

	const handleSendEmail = (userId) => () => {
		sendEmail({ data: { userId } })
	}

	const loading = loadingUsers || sendingEmail

	useEffect(() => {
		fetchUsers()
	}, [limit, offset, search])

	return {
		data: users,
		limit,
		loading,
		handleSendEmail,
		setOffset,
		setLimit,
		setSearch,
	}
}

export default useUsersDasboradTable
