import { Row, Col, Card } from 'react-bootstrap'
import useContestProjectsTable from './useContestProjectsTable'
import { formatDate } from 'utils/date.utils'
import styles from './ContestProjectsTable.module.scss'
import { CustomTable } from 'components/common/customTable'
import PageTitle from 'jsx/layouts/PageTitle'
import { SearchField } from 'components/common/searchField'
import _ from 'lodash'

const frontBaseURL = process.env.REACT_APP_FRONT_BASE_URL

const ContestProjectsTable = () => {
	const {
		data,
		limit,
		loading,
		handleSetWinner,
		setOffset,
		setSearch,
		setLimit
	} = useContestProjectsTable()

	const columns = [
		{
			header: 'Created On',
			render: (row) => formatDate(row.createdAt, 'inputDate'),
		},
		{
			header: 'Registered On',
			render: (row) => {
				return formatDate(_.get(row, ['projectContests', 0, 'createdAt']), 'inputDate')
			},
		},
		{
			header: 'Project',
			render: (row) => (
				<a href={`${frontBaseURL}/project/${row.slug}`} target="_blank" rel="noreferrer">
					{row.title}
				</a>
			),
		},
		{ header: 'Status', key: 'stage.name' },
		{ header: 'GoScore', key: 'goScore.goScore' },
		{ header: 'Final GoScore', key: 'finalGoScore' },
		{
			header: 'Creator',
			render: (row) => (
				<a href={`${frontBaseURL}/profile/${row.user?.username}`} target="_blank" rel="noreferrer">
					<div className="d-flex align-items-center">
						<img src={row.user?.avatar} className="rounded-md mr-2" width="24" alt="" />{' '}
						{row.user.email}
					</div>{' '}
				</a>
			),
		},
		{
			header: 'Registered By',
			render: (row) => {
				const enrollUser = _.get(row, ['projectContests', 0, 'user'])
				return (
					!_.isNil(enrollUser) ?
						<a href={`${frontBaseURL}/profile/${enrollUser.username}`} target="_blank" rel="noreferrer">
							<div className="d-flex align-items-center">
								{enrollUser.email}
							</div>{' '}
						</a> : '-'
				)
			},
		},
		{
			header: 'Winner',
			render: (row) => (
				<div className="custom-control custom-checkbox checkbox-success check-lg">
					<input
						type="checkbox"
						checked={row.isWinner}
						className="custom-control-input"
						id={`customCheckBox${row.id}`}
						onChange={(e) => {
							handleSetWinner(row.id)
						}}
					/>
					<label className="custom-control-label" htmlFor={`customCheckBox${row.id}`}></label>
				</div>
			),
		}
	]

	return (
		<>
			<PageTitle title={'Contest Projects'} />
			<Row className={styles.project_table}>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>{data?.contest?.name} Projects</Card.Title>
							<SearchField {...{ setSearch }} />
						</Card.Header>
						<Card.Body>
							<CustomTable
								{...{
									data,
									loading,
									limit,
									setLimit,
									setOffset,
									columns,
								}}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default ContestProjectsTable
