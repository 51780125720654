import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { getDiamondCircleUsers } from 'services/DiamondCircleService'

const useDiamondCircleTable = () => {
	const [search, setSearch] = useState('')
	const [limit, setLimit] = useState(5)
	const [offset, setOffset] = useState(0)
	const [users, setUsers] = useState({
		rows: [],
		count: 0,
	})

	const afterFetchUsers = (data, error) => {
		if (!error) {
			setUsers(data)
		}
	}

	const [{ loading: loadingUsers }, fetchUsers] = useAxios(
		getDiamondCircleUsers({ limit, offset, search }),
		afterFetchUsers
	)

	useEffect(() => {
		fetchUsers()
	}, [limit, offset, search])

	const loading = loadingUsers

	return {
		limit,
		loading,
		data: users,
		setOffset,
		setLimit,
		setSearch
	}
}

export default useDiamondCircleTable
