import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { useHistory, useParams } from 'react-router-dom'
import { showMessage } from 'utils/notification.util'
import { getUserTypes } from 'services/SeedServices'
import { addCampaign, editCampaign, getAirdropCampaigns, getCampaignById } from 'services/CampaignService'
import _ from 'lodash'

const useCampaignForm = () => {
	const history = useHistory()
	const [userTypes, setUserTypes] = useState([])
	const [airdropCampaigns, setAirdropCampaigns] = useState([])
	const [data, setData] = useState({
		name: null,
		slug: null,
		title: null,
		subtext: null,
		startDate: null,
		endDate: null,
		limitSignups: 0,
		reward: 0,
		rewardMessage: null,
		qrImage: null,
		headerImage: null,
		formText: null,
		isAirdrop: false,
		logo: null,
		userTypeId: 1,
		benefits: []
	})
	const { id } = useParams()

	const afterFetchUserTypes = (userTypes, error) => {
		if (!error) {
			setUserTypes(userTypes)
		}
	}

	const [{ loading: loadingUserTypes }] = useAxios(getUserTypes(true), afterFetchUserTypes)

	const [{ loading: loadingAirdrop }] = useAxios(getAirdropCampaigns(), (campaigns, error) => {
		if (!error) {
			setAirdropCampaigns(campaigns)
		}
	})

	const afterFetchCampaign = (data, error) => {
		if (!error) {
			data.isAirdrop = Boolean(data.isAirdrop)
			data.startDate = new Date(data.startDate)
			data.endDate = new Date(data.endDate)
			setData(data)
		}
	}
	const [{ loading: loadingCampaign }] = useAxios(getCampaignById(id, !_.isNil(id)), afterFetchCampaign)

	const afterSave = (_data, error) => {
		if (!error) {
			const message = `Campaign ${_.isNil(id) ? 'added' : 'updated'} successfully!`
			showMessage({ message })
			history.push('/campaigns')
		}
	}

	const service = _.isNil(id) ? addCampaign(data) : editCampaign(data)
	const [{ loading: saving }, saveChanges] = useAxios(service, afterSave)

	const handleChange = (payload) => {
		const key = Object.keys(payload)[0]
		if (key === 'isAirdrop' && payload[key] === true) {
			payload.campaignId = null
		}
		setData({
			...data,
			...payload,
		})
	}

	const handleCancel = () => {
		history.goBack()
	}

	const handleAddNewBenefit = () => {
		setData({
			...data,
			benefits: [
				...data.benefits,
				{
					tierId: data.id,
				},
			],
		})
	}

	const handleRemoveBenefit = (idx) => () => {
		const benefits = data.benefits.filter((_el, pos) => {
			return pos !== idx
		})
		setData({
			...data,
			benefits
		})
	}

	const handleChangeBenefit = (payload, idx) => {
		data.benefits[idx] = {
			...data.benefits[idx],
			...payload,
		}
		setData({
			...data,
		})
	}

	const loading = loadingUserTypes || saving || loadingCampaign || loadingAirdrop

	return {
		data,
		loading,
		userTypes,
		airdropCampaigns,
		handleAddNewBenefit,
		handleChangeBenefit,
		handleRemoveBenefit,
		handleCancel,
		handleChange,
		saveChanges
	}
}

export default useCampaignForm
