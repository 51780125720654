import _ from 'lodash'
import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { useHistory, useParams } from 'react-router-dom'
import { showMessage } from 'utils/notification.util'
import {
  getCountries,
  getUserGenders,
  getUserTypes,
} from 'services/SeedServices'
import { editUser, editWallet, getUserById } from 'services/UserService'
import { getCampaigns } from 'services/CampaignService'

const getUserDto = (data) => {
  const {
    id,
    email,
    about,
    avatar,
    genderId,
    username,
    shortUrl,
    headerImg,
    firstName,
    displayName,
    socialNetworks,
    isAirdropCreator,
    isAirdropFan,
    campaignId,
    countryId,
    isVesting,
    featured,
    lastName,
    password,
    isAdmin,
    typeId,
    bio,
    isBlacklisted,
    isBanned,
    banReason,
    adminNote,
  } = data
  const campaignIdTmp = campaignId === 'none' ? null : campaignId
  return {
    id,
    email,
    about,
    avatar,
    genderId,
    username,
    shortUrl,
    headerImg,
    firstName,
    displayName,
    socialNetworks,
    isAirdropCreator,
    isAirdropFan,
    campaignId: campaignIdTmp,
    countryId,
    isVesting,
    featured,
    lastName,
    password,
    isAdmin,
    typeId,
    bio,
    isBlacklisted,
    isBanned,
    banReason,
    adminNote,
  }
}

const useEditUserForm = () => {
  const history = useHistory()
  const { id } = useParams()
  const [userTypes, setUserTypes] = useState([])
  const [genders, setGenders] = useState([])
  const [countries, setCountries] = useState([])
  const [campaigns, setCampaigns] = useState([])
  const [data, setData] = useState({})
  const [walletData, setWalletData] = useState({})
  const [banDataChanged, setBanDataChanged] = useState(false)
  const [banModalText, setBanModalText] = useState()
  const [bannedData, setBannedData] = useState({
    isBanned: 0,
    isBlacklisted: 0,
  })

  const getBanButtonText = (isBanned, isBlacklisted) => {
    if (isBanned && isBlacklisted) return 'Unban'
    if (!isBanned && isBlacklisted) return 'Ban (Already Blacklisted)'
    if (!isBanned && !isBlacklisted) return 'Ban & Blacklist'
    return 'Whoa!'
  }

  const getBlacklistButtonText = (isBanned, isBlacklisted) => {
    if (isBlacklisted && isBanned) return 'Unblacklist & Unban'
    if (isBlacklisted && !isBanned) return 'Unblacklist'
    if (!isBlacklisted && !isBanned) return 'Blacklist'
    return 'Whoa!'
  }

  const [{ loading: loadingCampaigns }] = useAxios(
    getCampaigns({}, true),
    (data, error) => {
      if (!error) {
        const none = { id: 'none', name: 'None' }
        setCampaigns([none, ...data?.rows])
      }
    }
  )

  const afterFetchCountries = (data, error) => {
    if (!error) {
      setCountries(data)
    }
  }

  const [{ loading: loadingCountries }] = useAxios(
    getCountries(true),
    afterFetchCountries
  )

  const afterFetchUserTypes = (data, error) => {
    if (!error) {
      setUserTypes(data)
    }
  }

  const [{ loading: loadingUserTypes }] = useAxios(
    getUserTypes(true),
    afterFetchUserTypes
  )

  const afterFetchGenders = (g, error) => {
    if (!error) {
      setGenders(g)
    }
  }

  const [{ loading: loadingGenders }] = useAxios(
    getUserGenders(true),
    afterFetchGenders
  )

  const afterFetchUser = (user, error) => {
    if (!error) {
      const isAdmin = user?.isAdmin === 1
      const isVesting = user?.isVesting === 1
      const featured = user?.featured === 1
      const isBanned = user?.isBanned === 1
      const isBlacklisted = user?.isBlacklisted === 1
      setBannedData({
        isBanned,
        isBlacklisted,
      })
      data.airdropTypeId = 1
      if (user?.isAirdropFan === 1) user.airdropTypeId = 2
      if (user?.isAirdropCreator === 1) user.airdropTypeId = 3
      console.log({ user })
      setData({
        ...user,
        isAdmin,
        isVesting,
        isBlacklisted,
        isBanned,
        featured,
      })
      setWalletData(user?.wallet)
    }
  }

  const [{ loading: loadingUser }] = useAxios(
    getUserById(!_.isNil(id), id),
    afterFetchUser
  )

  const afterSave = (_data, error) => {
    if (!error) {
      const message = `User updated successfully!`
      showMessage({ message })
      history.push('/users')
    }
  }

  const saveChanges = () => {
    saveUserChanges()
    saveWalletChanges()
  }

  const [{ loading: isSavingUserChanges }, saveUserChanges] = useAxios(
    editUser(getUserDto(data)),
    afterSave
  )

  const [{ loading: isSavingWalletChanges }, saveWalletChanges] = useAxios(
    editWallet(walletData)
  )

  const submitBanChanges = () => {
    setBanDataChanged(false)
    saveUserChanges({ ...bannedData })
  }

  const cancelBanChanges = () => {
    setBanDataChanged(false)
  }

  const handleBanChanges = (payload) => {
    const text = payload?.isBanned
      ? `Are you sure you want to ${getBanButtonText(
          bannedData?.isBanned,
          bannedData?.isBlacklisted
        )} this user?`
      : `Are you sure you want to ${getBlacklistButtonText(
          bannedData?.isBanned,
          bannedData?.isBlacklisted
        )} this user?`

    setBanModalText(text)

    if (payload?.isBanned) {
      payload.isBlacklisted = true
    }
    if (!payload?.isBlacklisted) {
      payload.isBanned = false
    }

    setBannedData({
      ...bannedData,
      ...payload,
    })

    setBanDataChanged(true)
  }

  const handleChange = (payload) => {
    const key = Object.keys(payload)[0]
    if (key === 'airdropTypeId') {
      switch (payload.airdropTypeId) {
        case 1:
          payload.isAirdropFan = 0
          payload.isAirdropCreator = 0
          break
        case 2:
          payload.isAirdropFan = 1
          payload.isAirdropCreator = 0
          break
        case 3:
          payload.isAirdropFan = 0
          payload.isAirdropCreator = 1
          break
        default:
          payload.isAirdropFan = 0
          payload.isAirdropCreator = 0
          break
      }
    }
    console.log(payload)
    setData({
      ...data,
      ...payload,
    })
  }

  const handleWalletDataChange = (payload) => {
    setWalletData({
      ...walletData,
      ...payload,
    })
  }

  const handleSocialNetworkChange = (socialNetworkId, username) => {
    const isNew =
      data.socialNetworks.find(
        (el) => el.socialNetworkId === socialNetworkId
      ) === undefined
    let socialNetworks = {}
    if (isNew) {
      socialNetworks = [
        ...data.socialNetworks,
        {
          socialNetworkId,
          username,
        },
      ]
    } else {
      socialNetworks = data.socialNetworks.map((el) => {
        if (el.socialNetworkId === socialNetworkId)
          return {
            ...el,
            username,
          }
        return el
      })
    }
    setData((dt) => ({ ...dt, socialNetworks }))
  }

  const handleCancel = () => {
    history.goBack()
  }

  const airdropTypes = [
    {
      id: 1,
      name: 'None',
    },
    {
      id: 2,
      name: 'Airdrop FAN',
    },
    {
      id: 3,
      name: 'Airdrop Creator',
    },
  ]

  const loading =
    loadingUserTypes ||
    isSavingUserChanges ||
    isSavingWalletChanges ||
    loadingUser ||
    loadingGenders ||
    loadingCountries ||
    loadingCampaigns

  return {
    id,
    data,
    walletData,
    loading,
    genders,
    userTypes,
    countries,
    campaigns,
    saveChanges,
    airdropTypes,
    handleCancel,
    handleChange,
    handleWalletDataChange,
    handleBanChanges,
    handleSocialNetworkChange,
    handleConfirm: submitBanChanges,
    handleClose: cancelBanChanges,
    isOpen: banDataChanged,
    text: banModalText,
    getBanButtonText,
    getBlacklistButtonText,
  }
}

export default useEditUserForm
