import { Button } from 'react-bootstrap'
import useUserForm from './useUserForm'
import { CustomSelect } from 'components/common/customSelect'
import { LoadMask } from 'components/common/loadMask'

const UserForm = () => {
	const { data, loading, userTypes, saveChanges, handleCancel, handleChange } = useUserForm()

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">User Details</h4>
							</div>
							<div className="card-body">
								<div className="basic-form">
									<form onSubmit={(e) => e.preventDefault()}>
										<div className="form-row">
											<div className="form-group col-md-6">
												<label>Username</label>
												<input
													value={data.username}
													type="text"
													className="form-control"
													placeholder="Username"
													onChange={(e) => {
														handleChange({ username: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>Email Address</label>
												<input
													value={data.email}
													type="email"
													className="form-control"
													placeholder="User Email"
													onChange={(e) => {
														handleChange({ email: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>First Name</label>
												<input
													value={data.firstName}
													type="text"
													className="form-control"
													placeholder="First Name"
													onChange={(e) => {
														handleChange({ firstName: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>Last Name</label>
												<input
													value={data.lastName}
													type="text"
													className="form-control"
													placeholder="Last Name"
													onChange={(e) => {
														handleChange({ lastName: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>Password</label>
												<input
													value={data.password}
													type="password"
													className="form-control"
													placeholder="User Password"
													onChange={(e) => {
														handleChange({ password: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>User Group</label>
												<CustomSelect
													{...{
														value: data.typeId,
														setValue: (typeId) => {
															handleChange({ typeId })
														},
														options: userTypes,
													}}
												/>
											</div>
										</div>
										<Button className="mr-2" variant="success" onClick={() => saveChanges()}>
											Save
										</Button>
										<Button variant="danger" onClick={handleCancel}>
											Cancel
										</Button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default UserForm
