import _ from 'lodash'
import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { getProjectStagesService, getProjectStatus, getProjectTypes } from 'services/SeedServices'
import { useHistory, useParams } from 'react-router-dom'
import { showMessage } from 'utils/notification.util'
import { editProject, getProjectById } from 'services/ProjectService'
import { getGoScoreStages } from 'services/GoScoreService'

const useProjectForm = () => {
	const history = useHistory()
	const { id } = useParams()
	const [types, setTypes] = useState([])
	const [stages, setStages] = useState([])
	const [goScoreStages, setGoScoreStages] = useState([])
	const [statuses, setStatuses] = useState([])
	const [data, setData] = useState({
		metricId: null,
		stageId: null,
		name: '',
		description: '',
		threshold: '',
		reward: '',
		image: null,
		active: true,
	})

	const afterFetchTypes = (data, error) => {
		if (!error) {
			setTypes(data)
		}
	}

	const [{ loading: loadingTypes }] = useAxios(getProjectTypes(true), afterFetchTypes)


	const [{ loading: loadingGoScoreStages }] = useAxios(getGoScoreStages(), (data, error) => {
		if (!error) {
			setGoScoreStages(data)
		}
	})

	const afterFetchStages = (data, error) => {
		if (!error) {
			setStages(data)
		}
	}

	const [{ loading: loadingStages }] = useAxios(getProjectStagesService(true), afterFetchStages)

	const afterFetchStatus = (data, error) => {
		if (!error) {
			setStatuses(data)
		}
	}

	const [{ loading: loadingStatus }] = useAxios(getProjectStatus(true), afterFetchStatus)

	const afterFetchProject = (project, error) => {
		if (!error) {
			project.approval = Boolean(project.approval)
			project.staffPick = Boolean(project.staffPick)
			setData(project)
		}
	}

	const [{ loading: loadingProject }, fetchProject] = useAxios(getProjectById(!_.isNil(id), id), afterFetchProject)

	const afterSave = (_data, error) => {
		if (!error) {
			const message = `Project updated successfully!`
			showMessage({ message })
			history.push('/projects')
		}
	}

	const [{ loading: saving }, saveChanges] = useAxios(editProject(data), afterSave)

	const afterEditProject = (_data, error) => {
		if (!error) {
			const message = `Project updated successfully!`
			showMessage({ message })
			fetchProject()
		}
	}

	const [{ loading: reviewing }, reviewProject] = useAxios(editProject({}), afterEditProject)

	const handleSendReview = (stageId) => () => {
		setTimeout(() => {
			reviewProject({ data: { stageId, id: data?.id } })
		}, 500)
	}

	const handleChange = (payload) => {
		setData({
			...data,
			...payload,
		})
	}

	const handleCancel = () => {
		history.goBack()
	}

	const loading = loadingTypes || loadingStages || saving || loadingProject || loadingStatus || reviewing || loadingGoScoreStages

	return {
		data,
		stages,
		types,
		loading,
		statuses,
		goScoreStages,
		handleSendReview,
		handleCancel,
		handleChange,
		saveChanges
	}
}

export default useProjectForm
