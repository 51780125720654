import { Button } from 'react-bootstrap'
import useTokenSaleCampaignGroupForm from './useTokenSaleCampaignGroupForm'
import { LoadMask } from 'components/common/loadMask'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import _ from 'lodash'

const TokenSaleCampaignGroupForm = () => {
	const {
		id,
		data,
		loading,
		handleCancel,
		handleChange,
		saveChanges
	} = useTokenSaleCampaignGroupForm()

	console.log({ data })

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">Token Sale Campaign Group Details</h4>
							</div>
							<div className="card-body">
								<div className="basic-form">
									<form onSubmit={(e) => e.preventDefault()}>
										<div className="form-row">
											<div className="form-group col-md-9">
												<label>Group Name*</label>
												<input
													value={data.name}
													type="text"
													className="form-control"
													placeholder="Campaign Name"
													onChange={(e) => {
														handleChange({ name: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-3">
												<label>Price*</label>
												<input
													value={data.price}
													type="text"
													className="form-control"
													placeholder="Price"
													onChange={(e) => {
														handleChange({ price: e.target.value })
													}}
												/>
											</div>
											{_.isNil(id) && <div className="form-group col-md-4">
												<label>Number of links*</label>
												<input
													value={data.count}
													type="number"
													className="form-control"
													placeholder="Number of links"
													onChange={(e) => {
														handleChange({ count: Number(e.target.value) })
													}}
												/>
											</div>}
											<div className="form-group col-md-3">
												<label>Start Date*</label>
												<DatePicker selected={data?.startDate} onChange={(startDate) => handleChange({ startDate })} className="form-control" />
											</div>
											<div className="form-group col-md-3">
												<label>Start Date Hour*</label>
												<input
													value={data.startHour}
													type="number"
													className="form-control"
													placeholder="Start Date Hour"
													onChange={(e) => {
														handleChange({ startHour: Number(e.target.value) })
													}}
												/>
											</div>
											<div className="form-group col-md-3">
												<label>End Date*</label>
												<DatePicker selected={data?.endDate} onChange={(endDate) => handleChange({ endDate })} className="form-control" />
											</div>
											<div className="form-group col-md-3">
												<label>End Date Hour*</label>
												<input
													value={data.endHour}
													type="number"
													className="form-control"
													placeholder="End Date Hour"
													onChange={(e) => {
														handleChange({ endHour: Number(e.target.value) })
													}}
												/>
											</div>
										</div>
										<Button className="mr-2" variant="success" onClick={() => saveChanges()}>
											Save
										</Button>
										<Button variant="danger" onClick={handleCancel}>
											Cancel
										</Button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default TokenSaleCampaignGroupForm
