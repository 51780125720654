import { Link } from 'react-router-dom'
import { Row, Col, Card, Table } from 'react-bootstrap'
import PageTitle from 'jsx/layouts/PageTitle'
import useGoScoreMilestoneTable from './useGoScoreStageTable'
import { LoadMask } from 'components/common/loadMask'

const GoScoreStageTable = () => {
	const { stages, loading } = useGoScoreMilestoneTable()

	const renderStages = () => {
		return stages.map((stage) => {
			return (
				<tr key={`stage-${stage.id}`}>
					<td>{stage.name}</td>
					<td>
						<div
							style={{
								backgroundColor: stage.color,
								width: '50%',
								height: '15px',
								borderRadius: '6px',
							}}
						></div>
					</td>
					<td>
						<div className="d-flex">
							<Link
								to={`/edit-goscore-stage/${stage.id}`}
								className="btn btn-primary shadow btn-xs sharp mr-1"
							>
								<i className="fa fa-pencil"></i>
							</Link>
						</div>
					</td>
				</tr>
			)
		})
	}

	return (
		<>
			<PageTitle title={'GoScore Stages Management'} />
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>Current GoScore Stages</Card.Title>
						</Card.Header>
						<Card.Body>
							{loading ? (
								<LoadMask />
							) : (
								<Table responsive>
									<thead>
										<tr>
											<th>
												<strong>Name</strong>
											</th>
											<th>
												<strong>Color</strong>
											</th>
											<th>
												<strong>Action</strong>
											</th>
										</tr>
									</thead>
									<tbody>{renderStages()}</tbody>
								</Table>
							)}
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default GoScoreStageTable
