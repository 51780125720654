import { Link } from 'react-router-dom'
import _ from 'lodash'
import { Row, Col, Card, Button } from 'react-bootstrap'
import { ConfirmModal } from 'components/common/confirmModal'
import useIngXRestrictionTable from './useIngXRestrictionTable'
import PageTitle from 'jsx/layouts/PageTitle'
import { CustomTable } from 'components/common/customTable'
import { SearchField } from 'components/common/searchField'
import { formatDate } from 'utils/date.utils'

const IngXRestrictionTable = () => {
	const {
		data,
		limit,
		loading,
		selectedRow,
		isOpen,
		setOffset,
		setLimit,
		setSearch,
		handleDeleteUser,
		handleClose,
		handleConfirm,
		handleRunCronJob
	} = useIngXRestrictionTable()

	const confirmationText = `Are you sure you want to remove ${selectedRow?.email} from Ingredient-X Restriction List`
	const columns = [
		{
			header: 'Email',
			key: 'email',
		},
		{
			header: 'Limit Start Date',
			render: (row) => formatDate(_.get(row, 'limitStartDate', null), 'timestamp'),
		},
		{
			header: 'Current Daily Limit',
			key: 'ingXLimit',
		},
		{
			header: 'Amount withdrawn today',
			key: 'amountWithdrawnToday',
		},
		{
			header: 'Actions',
			render: (row) => (
				<div className="d-flex">
					<Link
						to="#"
						className="btn btn-danger shadow btn-xs sharp mr-1"
						onClick={() => handleDeleteUser(row)}
					>
						<i className="fa fa-trash"></i>
					</Link>
				</div>
			),
		}

	]

	return (
		<>
		<ConfirmModal {...{ isOpen, handleClose, text:confirmationText, handleConfirm }} />
			<PageTitle title={'IngredientX Restriction'} />
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>Current IngredientX Restriction Users</Card.Title>

							<Link to="add-ingx-restriction-user">
								<Button className="mr-2" variant="success">
									Add User
								</Button>
							</Link>
							<Link to="#">
								<Button className="mr-2" variant="info" onClick={handleRunCronJob}>
									Run IngX Cron Job
								</Button>
							</Link>
							<SearchField {...{ setSearch }} />
						</Card.Header>
						<Card.Body>
							<CustomTable
								{...{
									data,
									loading,
									limit,
									setLimit,
									setOffset,
									columns
								}}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default IngXRestrictionTable
