import _ from 'lodash'
import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import {
	getGemAnalyticsService,
	getGemTransactionsService,
} from 'services/GemTransactionService'
import { getNowDateDiff } from 'utils/date.utils'

const lockStatuses = [
	{ id: 'paused', name: 'Paused' },
	{ id: 'notpaused', name: 'Not Paused' },
]

const useRRSTable = () => {

	const [filters, setFilters] = useState({
		from: null,
		option: null,
		lockStatus: null,
		to: null,
		limit: 5,
		offset: 0,
	})


	const [gemTransactions, setGemTransactions] = useState({
		rows: [],
		count: 0,
	})

	const [gemAnalytics, setGemAnalytics] = useState({
		usersWithLockedTokens: 0,
		usersNotSpentGems: 0
	})

	const afterFetchTransactions = (data, error) => {
		if (!error) {
			setGemTransactions(data)
		}
	}

	const afterFetchAnalytics = (data, error) => {
		if (!error) {
			setGemAnalytics(data)
		}
	}

	const [{ loading: loadingTransactions }, fetchTransactions] = useAxios(

		getGemTransactionsService({
			limit: filters.limit,
			offset: filters.offset,
			option: filters.option,
			lockStatus: filters.lockStatus,
			search: filters.search,
			from: filters?.from,
			to: filters?.to,
			direction: 'desc'
		}),
		afterFetchTransactions
	)

	const [{ loading: loadingAnalytics }, fetchAnalytics] = useAxios(

		getGemAnalyticsService(),
		afterFetchAnalytics
	)


	const loading = loadingTransactions || loadingAnalytics

	useEffect(() => {
		fetchTransactions();
	}, [filters])

	useEffect(() => {
		fetchAnalytics();
	}, [])


	const handleClearFilter = () => {
		setFilters({
			search: '',
			option: null,
			lockStatus: null,
			from: null,
			to: null,
			limit: 5,
			offset: 0,
		});
	}

	const handleChangeLockStatus = (lockStatus) => {
		setFilters({ ...filters, lockStatus: lockStatus })
	}

	const handleChangeLimit = (limit) => {
		setFilters((prv) => {
			return { ...prv, limit: limit }
		})
	}

	const setOffset = (offset) => {
		setFilters(prv => {
			if (prv.offset === offset) {
				return prv
			}
			return { ...prv, offset: offset }
		})


	}

	const handleChangeSearch = (search) => {
		setFilters(prv => {
			return {
				...prv,
				search: search,
				option: null,
				lockStatus: null,
				from: null,
				to: null,
				limit: 5,
				offset: 0,
			}
		})

	}

	const handleChangeFromDateRange = (data) => {
		setFilters(prv => {
			return { ...prv, from: data.from }
		})
	}

	const handleChangeToDateRange = (data) => {
		setFilters(prv => {
			return { ...prv, to: data.to }
		})
	}

	const checkRRSPaused = date => {
		if (_.isNil(date)) return ''
		const diff = getNowDateDiff(date).toMillis()
		if (diff > 259200000) return "True"
		return "False"
	}

	return {
		loading,
		data: gemTransactions,
		gemAnalytics,
		filters: filters,
		lockStatuses,
		handleChangeLockStatus,
		handleClearFilter,
		handleChangeLimit,
		setOffset,
		handleChangeSearch,
		handleChangeFromDateRange,
		handleChangeToDateRange,
		checkRRSPaused
	}
}

export default useRRSTable
