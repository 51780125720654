import { toast } from 'react-toastify'

export const showMessage = ({ message, type = 'info' }) => {
	toast[type](message, {
		toastId: 'filmio-toast',
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: false,
		progress: undefined,
	})
}
