import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { getProjectCampaigns } from 'services/ProjectService'

const useProjectCampaignsTable = () => {
	const [search, setSearch] = useState('')
	const [limit, setLimit] = useState(5)
	const [offset, setOffset] = useState(0)
	const [campaigns, setCampaigns] = useState({
		rows: [],
		count: 0,
	})

	const afterFetchCampaigns = (data, error) => {
		if (!error) {
			setCampaigns(data)
		}
	}

	const [{ loading }, fetchProjectCampaigns] = useAxios(
		getProjectCampaigns({ limit, offset, search }),
		afterFetchCampaigns
	)

	useEffect(() => {
		fetchProjectCampaigns()
	}, [limit, offset, search])

	return {
		limit,
		loading,
		data: campaigns,
		setOffset,
		setLimit,
		setSearch
	}
}

export default useProjectCampaignsTable
