import { Button } from 'react-bootstrap'

const Step1 = ({
	handleChange,
	data,
	handleSave,
	handleCancel
}) => {
	return (
		<div className="col-xl-12 col-lg-12">
			<div className="card">
				<div className="card-header">
					<h4 className="card-title">General</h4>
				</div>
				<div className="card-body">
					<div className="basic-form">
						<form onSubmit={(e) => e.preventDefault()}>
							<div className="form-row">
								<div className="form-group col-md-6">
									<label>Notification Name</label>
									<input
										value={data?.name}
										type="text"
										className="form-control"
										placeholder="Notification Name"
										onChange={(e) => {
											handleChange({ name: e.target.value })
										}}
									/>
								</div>
								<div className="form-group col-md-6">
									<label>Subject Line</label>
									<input
										value={data?.subject}
										type="text"
										className="form-control"
										placeholder="Subject Line"
										onChange={(e) => {
											handleChange({ subject: e.target.value })
										}}
									/>
								</div>
								<div className="form-group col-md-6">
									<label>From Email</label>
									<input
										value={data?.fromEmail}
										type="text"
										className="form-control"
										placeholder="From Email"
										onChange={(e) => {
											handleChange({ fromEmail: e.target.value })
										}}
									/>
								</div>
								<div className="form-group col-md-6">
									<label>From Name</label>
									<input
										value={data?.fromName}
										type="text"
										className="form-control"
										placeholder="From Name"
										onChange={(e) => {
											handleChange({ fromName: e.target.value })
										}}
									/>
								</div>
								<div className="form-group col-md-12">
									<label>Test Emails</label>
									<textarea
										value={data?.testEmails}
										className="form-control"
										rows="4"
										placeholder="Test Emails"
										onChange={(e) => {
											handleChange({ testEmails: e.target.value })
										}}
									/>
								</div>
							</div>
							<Button className="mr-2" variant="danger" onClick={handleCancel}>
								Cancel
							</Button>
							<Button variant="success" onClick={handleSave}>
								Save and continue
							</Button>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Step1
