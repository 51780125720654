import _ from "lodash"

export const getWeekDays = () => {
	return [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday'
	]
}

export const getHours = () => {
	const hours = []
	for (let i = 0; i < 24; i++) {
		const sufix = i < 12 ? `am` : `pm`
		const hour = i < 12 ? i : i - 12
		hours.push({
			id: i,
			name: `${hour}:00${sufix} UTC`,
		})
	}
	return hours
}

export const getMinutes = () => {
	const minutes = []
	for (let i = 0; i < 60; i++) {
		minutes.push({
			id: i,
			name: i,
		})
	}
	return minutes
}

export const getMonthDays = () => {
	const days = []
	for (let i = 1; i < 31; i++) {
		days.push({
			id: i,
			name: i,
		})
	}
	return days
}



const getTime = (h, m) => {
	const hour = h < 10 ? `0${h}` : h
	const minute = m < 10 ? `0${m}` : m
	return `${hour}:${minute}`
}

export const getHumanReadableFromCron = (cronExpression) => {
	if (_.isNil(cronExpression)) return ''
	const values = cronExpression.split(' ')
	const minute = values[0] !== '*' ? Number(values[0]) : 0
	const hour = values[1] !== '*' ? Number(values[1]) : 0
	const monthDay = values[2] !== '*' ? Number(values[2]) : 1
	const day = values[4] !== '*' ? Number(values[4]) : 0
	const weekDays = getWeekDays()
	let frequency = 'daily'
	if (values[4] !== '*') frequency = 'weekly'
	if (values[2] !== '*') frequency = 'monthly'
	const time = getTime(hour, minute)
	switch (frequency) {
		case 'daily':
			return `Every day at ${time}`
		case 'weekly':
			return `Every ${weekDays[day]} at ${time}`
		case 'monthly':
			return `Every day ${monthDay} of each month at ${time}`
		default:
			return cronExpression
	}
}