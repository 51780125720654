import axios from 'axios'
import { useConfirmModal } from 'components/common/confirmModal'
import useAxios from 'hooks/useAxios'
import { useState } from 'react'
import { deleteDaoTier, getDaoTiers } from 'services/DaoTierService'
import { showMessage } from 'utils/notification.util'

const useDaoTierTable = () => {
	const [tiers, setTiers] = useState([])
	const [tierToDelete, setTierToDelete] = useState({
		tierId: null,
		tierName: null
	})
	const {
		isOpen,
		handleClose,
		handleOpen
	} = useConfirmModal()

	const afterFetchTiers = (data, error) => {
		if (!error) {
			setTiers(data)
		}
	}

	const [{ loading }, fetchTiers] = useAxios(getDaoTiers(), afterFetchTiers)

	const handleConfirm = () => {
		if (tierToDelete?.tierId)
			axios(deleteDaoTier(tierToDelete?.tierId)).then(() => {
				showMessage({ message: 'Tier deleted successfully' })
				fetchTiers()
			}).finally(() => { handleClose() })
	}

	const handleDeleteTier = (tierId, tierName) => () => {
		setTierToDelete({ tierId, tierName })
		handleOpen()
	}

	return {
		tiers,
		isOpen,
		loading,
		tierToDelete,
		handleDeleteTier,
		handleConfirm,
		handleClose
	}
}

export default useDaoTierTable
