import { Row, Col, Card, Button } from 'react-bootstrap'
import useRRSTable from './useRRSTable'
import { formatDate } from 'utils/date.utils'
import styles from './RRSTable.module.scss'
import { CustomSelect } from 'components/common/customSelect'
import { CustomTable } from 'components/common/customTable'
import { SearchField } from 'components/common/searchField'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const RRSTable = () => {
	const {
		data,
		filters,
		loading,
		gemAnalytics,
		lockStatuses,
		handleChangeFromDateRange,
		handleChangeToDateRange,
		handleChangeLockStatus,
		handleChangeSearch,
		handleClearFilter,
		handleChangeLimit,
		setOffset,
	} = useRRSTable()

	const columns = [
		{
			header: 'Name',
			render: (row) => <div>{row.displayName}</div>,

		},
		{
			header: 'Email',
			render: (row) => <div>{row.email}</div>,
		},
		{
			header: 'Join Date',
			render: (row) => formatDate(row?.createdAt, 'inputDate'),
		},
		{
			header: 'Last Sign In',
			render: (row) => formatDate(row.lastSignIn, 'timestamp'),
		},
		{
			header: 'RRS Paused',
			render: (row) => row?.rrsPaused ? 'Yes' : 'No',
		},
		{
			header: 'Total FAN Rewards',
			render: (row) => <div>{row.totalRewards}</div>,
		},
		{
			header: 'Released FAN Rewards',
			render: (row) => <div>{row.unlockTokens}</div>,
		},
		{
			header: 'Total Gems',
			render: (row) => <div>{row.gemTotal}</div>,
		},
		{
			header: 'Gems Spent',
			render: (row) => <div>{row.gemSpend}</div>,
		}
	]
	return (
		<>
			<div className="row">
				<div className="col-xl-2 col-sm-6 m-t35">
					<div className="card card-coin">
						<div className="card-body text-center">
							<h2 className="text-black mb-2 font-w600">{gemAnalytics.usersWithLockedTokens}</h2>
							<p className="mb-0 fs-14">
								Users With Locked Tokens
							</p>
						</div>
					</div>
				</div>
				<div className="col-xl-2 col-sm-6 m-t35">
					<div className="card card-coin">
						<div className="card-body text-center">
							<h2 className="text-black mb-2 font-w600">{gemAnalytics.usersNotSpentGems}</h2>
							<p className="mb-0 fs-13">
								Users Never Spent Gems
							</p>
						</div>
					</div>
				</div>

				<div className="col-md-12 m-t35">
					<Row className={styles.transaction_table}>
						<Col lg={12}>
							<Card>
								<Card.Header>
									<Card.Title className='col-5 flex-fill mw-100' >RRS Dashboard</Card.Title>
									<SearchField {...{ search: filters.search, setSearch: handleChangeSearch }} />
									<Button onClick={handleClearFilter} className={styles.refresh_btn}>
										<i className="fa fa-refresh" aria-hidden="true"></i>
									</Button>

								</Card.Header>
								<Card.Header>

									<Row>
										<Col xs={12} md={3} xl={3}>
											<label>RRS Status</label>
											<CustomSelect
												{...{
													value: filters.lockStatus,
													setValue: handleChangeLockStatus,
													options: lockStatuses,
													className: styles.stages,
												}}
											/>
										</Col>
										<Col xs={12} md={3} xl={3}>
											<label>Start Date</label>
											<DatePicker selected={filters?.from} onChange={(from) => handleChangeFromDateRange({ from })} className="form-control" />
										</Col>
										<Col xs={12} md={3} xl={3}>
											<label>End Date</label>
											<DatePicker selected={filters?.to} onChange={(to) => handleChangeToDateRange({ to })} className="form-control" />
										</Col>
									</Row>
								</Card.Header>
								<Card.Body>
									<CustomTable
										{...{
											data,
											loading,
											limit: filters?.limit,
											setLimit: handleChangeLimit,
											setOffset,
											columns,
										}}
									/>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</div>
			</div>

		</>
	)
}

export default RRSTable
