import useAxios from 'hooks/useAxios'
import { useState } from 'react'
import { getCollaboratorRoles } from 'services/CollaboratorRoleService'

const useCollaboratorRoleTable = () => {
	const [levels, setLevels] = useState([])

	const afterFetchLevels = (data, error) => {
		if (!error) {
			setLevels(data)
		}
	}

	const [{ loading }] = useAxios(getCollaboratorRoles(), afterFetchLevels)

	return {
		loading,
		levels,
	}
}

export default useCollaboratorRoleTable
