export const getGemOptionService = () => ({
	method: 'get',
	url: '/gemOption',
	autoexec: true
})

export const updateGemOptionsService = (data) => ({
	method: 'put',
	url: '/gemOption',
	data,
	autoexec: false
})
