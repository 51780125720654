import { Button } from 'react-bootstrap'

const Step4 = ({
	data,
	handleSave,
	handleCancel,
	handlePublish
}) => {
	const active = data?.active === 1

	return (
		<div className="col-xl-12 col-lg-12">
			<div className="card">
				<div className="card-header">
					<h4 className="card-title">Confirm</h4>
				</div>
				<div className="card-body">
					<div className="basic-form">
						<form onSubmit={(e) => e.preventDefault()}>
							<div className="col-md-12">
								<h1>Notification Summary</h1>
								<p>
									Below you will find a list of the settings for this notification. Please double check before you publish. If you aren’t sure that the settings are correct, or need more time, you can always save this notification as a draft.
								</p>
							</div>
							<div className="col-md-12 separator">
							</div>
							<div className="row mb-5">
								<div className="col-md-4">
									Notification Name: <strong>{data?.name}</strong>
								</div>
								<div className="col-md-4">
									Notification Type: <strong>{data?.type?.name}</strong>
								</div>
							</div>

							<Button className="mr-2" variant="danger" onClick={handleCancel}>
								Cancel
							</Button>
							<Button className="mr-2" onClick={handleSave}>
								Save
							</Button>
							{!active && <Button variant="success" onClick={handlePublish}>
								Publish
							</Button>}
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Step4
