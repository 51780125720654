export const getNewsreels = (limit, offset) => ({
	method: 'get',
	url: '/newsreel/admin',
	params: { limit, offset },
	autoexec: false,
})

export const editNewsreel = (data) => ({
	method: 'put',
	url: '/newsreel',
	data,
	autoexec: false,
})
