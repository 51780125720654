import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { editDigestEmailService, getDigestEmailService } from 'services/EmailNotificationServices'
import { useDispatch } from 'react-redux'
import { setStep } from '../digestForm/digestFormSlice'
import { showMessage } from 'utils/notification.util'
import { getHumanReadableFromCron } from 'utils/cron.util'

const useDigestTable = () => {
	const [search, setSearch] = useState('')
	const [limit, setLimit] = useState(5)
	const [offset, setOffset] = useState(0)
	const [data, setData] = useState({
		rows: [],
		count: 0,
	})
	const dispatch = useDispatch()

	const handleEditClick = () => {
		dispatch(setStep(0))
	}

	const afterFetchNotificationEmail = (data, error) => {
		if (!error) {
			const rows = data?.rows?.map(el => {
				const humanFrequency = getHumanReadableFromCron(el?.frequency)
				return {
					...el,
					humanFrequency
				}
			})
			setData({
				...data,
				rows
			})
		}
	}

	const [{ loading }, fetchReviews] = useAxios(
		getDigestEmailService({ limit, offset, search }),
		afterFetchNotificationEmail
	)

	const afterActivate = (data, error) => {
		if (!error) {
			const mpart = data.active === 1 ? 'enabled' : 'disabled'
			const message = `Notification Email have been ${mpart}`
			showMessage({ message })
			setData(d => {
				const rows = d?.rows?.map(el => {
					if (el.id === data.id) {
						el.active = data.active
					}
					return el
				})
				return {
					...d,
					rows
				}
			})
		}
	}

	const [{ loading: activating }, activate] = useAxios(
		editDigestEmailService(),
		afterActivate
	)

	const handleActivate = (id, active) => {
		activate({ data: { id, active } })
	}


	useEffect(() => {
		fetchReviews()
	}, [limit, offset, search])

	return {
		loading: loading || activating,
		data,
		limit,
		setLimit,
		setOffset,
		setSearch,
		handleEditClick,
		handleActivate
	}
}

export default useDigestTable
