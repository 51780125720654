import { useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import styles from './UploadFile.module.scss'

const UploadFile = ({ setFile, setFiles, defaultValue, variant, defaultImage, ...others }) => {
	const [fileName, setFileName] = useState(defaultValue)
	const [image, setImage] = useState(defaultImage)
	const fileInput = useRef(null)

	const handleFileInputChange = (e) => {
		const files = e.target.files
		setFileName(files[0].name)
		setFile && setFile(files[0])
		setFiles && setFiles(files[0])

		const reader = new FileReader()
		reader.onload = function (e) {
			setImage(e.target.result)
		}
		reader.readAsDataURL(files[0])
	}

	const handleTextInputClick = (e) => {
		fileInput.current && fileInput.current.click()
	}

	useEffect(() => {
		if (defaultValue && defaultValue !== '') setFileName(defaultValue)
	}, [defaultValue])

	return (
		<div className={[styles.upload_file, styles[variant]].join(' ')}>
			<input
				type="text"
				onClick={handleTextInputClick}
				placeholder="Choose a file"
				value={fileName}
				readOnly
			/>
			<input
				type="file"
				onChange={handleFileInputChange}
				ref={fileInput}
				className={styles.upload_file}
				{...others}
			/>
			<Button onClick={handleTextInputClick}>
				{variant !== 'button' ? 'UploadFile' : <i className="fa fa-upload"></i>}
			</Button>
			{variant === 'button' && image && <img src={image} alt="" />}
		</div>
	)
}

export default UploadFile
