import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { useHistory } from 'react-router-dom'
import { showMessage } from 'utils/notification.util'
import {
	getDiamondCircleTypes,
	addDiamondCircleUser,
} from 'services/DiamondCircleService'
import _ from 'lodash'

const useDiamondCircleForm = () => {
	const history = useHistory()
	const [data, setData] = useState({
		email: null,
		typeId: null,
		startDate: null
	})
	const [types, setTypes] = useState([])

	const afterFetchTypes = (response, error) => {
		if (!error) {
			setTypes([...response])
		}
	}
	const [{ loading: loadingOptions }, getOptions] = useAxios(getDiamondCircleTypes(), afterFetchTypes)

	const afterAdd = (_data, error) => {
		if (!error) {
			const message = `User with email ${data.email} added successfully!`
			showMessage({ message })
			history.push('/diamond-circle')
		} else {
			showMessage({ message: _data.message, type: 'error' })
		}
	}

	const service = addDiamondCircleUser(data)
	const [{ loading: adding }, addUser] = useAxios(service, afterAdd)

	const handleChange = (state) => {
		setData({
			...data,
			...state
		})
	}

	const handleChangeType = (type) => {
		setData({
			...data,
			typeId: type
		})
	}

	const handleSubmit = () => {
		if (_.isNil(data.email) || _.isNil(data.startDate) || _.isNil(data.typeId)) {
			showMessage({ message: "Please make sure to fill all the required fields!", type: 'error' })
		} else {
			addUser()
		}

	}

	const handleCancel = () => {
		history.goBack()
	}

	const loading = adding || loadingOptions

	useEffect(async () => {
		await getOptions()
	}, [])

	return {
		types,
		data,
		loading,
		handleCancel,
		handleChange,
		handleChangeType,
		handleSubmit
	}
}

export default useDiamondCircleForm
