import useDashboard from "./useDashboard"
import { UsersDasboradTable } from "./usersDasboradTable"

const Dashboard = () => {

	const { data } = useDashboard()
	const {
		fgrTotal,
		stakeTotal,
		stakeCount,
		referCount,
		reviewsCount,
		todayFgrTotal,
		todayStakesSum,
		todayStakesCount
	} = data

	return (
		<>
			<div className="row">
				<div className="col-xl-2 col-sm-6 m-t35">
					<div className="card card-coin">
						<div className="card-body text-center">
							<h2 className="text-black mb-2 font-w600">{stakeTotal}</h2>
							<p className="mb-0 fs-14">
								Total Stake Volume
							</p>
						</div>
					</div>
				</div>
				<div className="col-xl-2 col-sm-6 m-t35">
					<div className="card card-coin">
						<div className="card-body text-center">
							<h2 className="text-black mb-2 font-w600">{stakeCount}</h2>
							<p className="mb-0 fs-13">
								Total Stake Count
							</p>
						</div>
					</div>
				</div>
				<div className="col-xl-2 col-sm-6 m-t35">
					<div className="card card-coin">
						<div className="card-body text-center">
							<h2 className="text-black mb-2 font-w600">{referCount}</h2>
							<p className="mb-0 fs-14">
								Total Refers Join
							</p>
						</div>
					</div>
				</div>
				<div className="col-xl-2 col-sm-6 m-t35">
					<div className="card card-coin">
						<div className="card-body text-center">
							<h2 className="text-black mb-2 font-w600">{reviewsCount}</h2>
							<p className="mb-0 fs-14">
								Total Reviews
							</p>
						</div>
					</div>
				</div>
				<div className="col-xl-2 col-sm-6 m-t35">
					<div className="card card-coin">
						<div className="card-body text-center">
							<h2 className="text-black mb-2 font-w600">{fgrTotal}</h2>
							<p className="mb-0 fs-14">
								Total FGR Claimed
							</p>
						</div>
					</div>
				</div>
				<div className="col-xl-2 col-sm-6 m-t35">
					<div className="card card-coin">
						<div className="card-body text-center">
							<h2 className="text-black mb-2 font-w600">{'?'}</h2>
							<p className="mb-0 fs-14">
								Total FAN Distributed
							</p>
						</div>
					</div>
				</div>
				<div className="col-xl-2 col-sm-6 m-t35">
					<div className="card card-coin">
						<div className="card-body text-center">
							<h2 className="text-black mb-2 font-w600">{todayStakesCount}</h2>
							<p className="mb-0 fs-14">
								Today Stakes Count
							</p>
						</div>
					</div>
				</div>
				<div className="col-xl-2 col-sm-6 m-t35">
					<div className="card card-coin">
						<div className="card-body text-center">
							<h2 className="text-black mb-2 font-w600">{todayStakesSum}</h2>
							<p className="mb-0 fs-14">
								Today Stakes Volume
							</p>
						</div>
					</div>
				</div>
				<div className="col-xl-2 col-sm-6 m-t35">
					<div className="card card-coin">
						<div className="card-body text-center">
							<h2 className="text-black mb-2 font-w600">{todayFgrTotal}</h2>
							<p className="mb-0 fs-14">
								Today FGR Claimed
							</p>
						</div>
					</div>
				</div>
				<div className="col-md-12 m-t35">
					<UsersDasboradTable />
				</div>
			</div>
		</>
	)
}
export default Dashboard
