import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { getTokenSaleCampaignGroups } from 'services/TokenCampaignService'

const useTokenSaleCampaignsTable = () => {
	const [search, setSearch] = useState('')
	const [limit, setLimit] = useState(5)
	const [offset, setOffset] = useState(0)
	const [campaigns, setCampaigns] = useState({
		rows: [],
		count: 0,
	})

	const afterFetchUsers = (data, error) => {
		if (!error) {
			setCampaigns(data)
		}
	}

	const [{ loading: loadingUsers }, fetchUsers] = useAxios(
		getTokenSaleCampaignGroups({ limit, offset, search }),
		afterFetchUsers
	)

	useEffect(() => {
		fetchUsers()
	}, [limit, offset, search])

	const loading = loadingUsers

	return {
		limit,
		loading,
		data: campaigns,
		setOffset,
		setLimit,
		setSearch
	}
}

export default useTokenSaleCampaignsTable
