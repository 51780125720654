import { buildFormData } from 'utils/form.util'

export const getCampaigns = (params, autoexec=false) => ({
	method: 'get',
	url: '/campaign',
	params,
	autoexec
})

export const getCampaignById = (id, autoexec) => ({
	method: 'get',
	url: `/campaign/${id}`,
	autoexec
})

export const getAirdropCampaigns = () => ({
	method: 'get',
	url: '/campaign/airdrop',
	autoexec: true
})

export const getCampaignBySlug = (slug) => ({
	method: 'get',
	url: `/campaign/slug/${slug}`,
	autoexec: true,
})

export const addCampaign = (data) => {
	const fields = [
		'name',
		'slug',
		'title',
		'subtext',
		'endDate',
		'formText',
		'startDate',
		'limitSignups',
		'rewardMessage',
		'welcomeMessage',
		'headerImage',
		'userTypeId',
		'campaignId',
		'isAirdrop',
		'reward'
	]
	const objects = ['benefits']
	const files = ['headerImg', 'logo']
	const formData = buildFormData(data, fields, files, objects)
	return {
		method: 'post',
		url: '/campaign',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		autoexec: false,
	}
}

export const editCampaign = (data) => {
	const fields = [
		'id',
		'name',
		'slug',
		'title',
		'subtext',
		'endDate',
		'formText',
		'startDate',
		'limitSignups',
		'rewardMessage',
		'welcomeMessage',
		'headerImage',
		'userTypeId',
		'campaignId',
		'isAirdrop',
		'reward',
	]
	const objects = ['benefits']
	const files = ['headerImg', 'logo']
	const formData = buildFormData(data, fields, files, objects)
	return {
		method: 'put',
		url: '/campaign',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		autoexec: false,
	}
}
