import { showMessage } from 'utils/notification.util'
import { getMarketingUsersCsvReport } from 'services/AdminService'
import useAxios from 'hooks/useAxios'

const useReports = () => {
	const [{ loading: downloading }, downloadReport] = useAxios(
		getMarketingUsersCsvReport(),
		(data, error) => {
			if (!error) {
				const blob = new Blob([data], { type: 'text/csv' })
				const url = window.URL.createObjectURL(blob)
				const a = document.createElement('a')
				a.href = url
				a.download = 'Marketing Cohort Report (detailed).csv'
				a.click()
				window.URL.revokeObjectURL(url)
				showMessage({ message: 'Report Generated successfully.' })
			}
		}
	)
	const loading = downloading
	return {
		downloadReport,
		loading,
	}
}
export default useReports
