export const getRewardBucketsService = () => ({
	method: 'get',
	url: '/rewardBucket',
	autoexec: true
})

export const getNaturalUnlockProgressionService = data => ({
	method: 'post',
	url: '/rewardBucket/progressionTest',
	data,
	autoexec: false
})

export const updateRewardBucketsService = (data) => ({
	method: 'put',
	url: '/rewardBucket',
	data,
	autoexec: false
})
