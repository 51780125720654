export const getDigestEmailTypesService = () => ({
	method: 'get',
	url: `/digestEmail/types`,
	autoexec: true
})
export const getTriggeredEmailTypesService = () => ({
	method: 'get',
	url: `/triggeredEmail/types`,
	autoexec: true
})

export const getEmailTemplatesService = () => ({
	method: 'get',
	url: `/emailNotification/templates`,
	autoexec: true
})

export const getDigestEmailService = (data) => ({
	method: 'get',
	url: `/digestEmail`,
	data
})

export const getTriggerEmailsService = (data) => ({
	method: 'get',
	url: `/triggeredEmail`,
	data
})

export const getDigestEmailByIdService = (id, autoexec) => ({
	method: 'get',
	url: `/digestEmail/${id}`,
	autoexec
})

export const getTriggeredEmailByIdService = (id, autoexec) => ({
	method: 'get',
	url: `/triggeredEmail/${id}`,
	autoexec
})

export const getTriggerEmailByIdService = (id, autoexec) => ({
	method: 'get',
	url: `/triggeredEmail/${id}`,
	autoexec
})

export const addDigestEmailService = (data) => ({
	method: 'post',
	url: `/digestEmail`,
	data
})

export const editDigestEmailService = (data) => ({
	method: 'put',
	url: `/digestEmail`,
	data
})

export const addTriggeredEmailService = (data) => ({
	method: 'post',
	url: `/triggeredEmail`,
	data
})

export const editTriggeredEmailService = (data) => ({
	method: 'put',
	url: `/triggeredEmail`,
	data
})