import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { clearTokenSaleCampaign, createCampaignsService, getTokenSaleCampaigns } from 'services/TokenCampaignService'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import _ from 'lodash'
import { showMessage } from 'utils/notification.util'

const useTokenSaleCampaignsTable = () => {
	const [reload, setReload] = useState(false)
	const [search, setSearch] = useState('')
	const [limit, setLimit] = useState(5)
	const [offset, setOffset] = useState(0)
	const [linksToAdd, setLinksToAdd] = useState(0)
	const [campaigns, setCampaigns] = useState({
		rows: [],
		count: 0,
	})
	const { groupId } = useParams()

	const afterFetchUsers = (data, error) => {
		if (!error) {
			setCampaigns(data)
		}
	}

	const [{ loading: loadingUsers }, fetchCampaigns] = useAxios(
		getTokenSaleCampaigns({ limit, offset, search, groupId }),
		afterFetchUsers
	)

	const [{ loading: clearing }, clearCampaign] = useAxios(
		clearTokenSaleCampaign(),
		(data, error) => {
			if (!error) {
				setCampaigns(c => {
					c.rows = c.rows.map(el => {
						if (el?.id === data?.id) {
							el.user = null
							el.wallet = null
						}
						return el
					})
					return c
				})

				showMessage({ message: 'Campaign sale cleared' })
			}
		}
	)


	const afterSave = (_data, error) => {
		if (!error) {
			setReload(el => !el)
			const message = 'Token Sale Campaign Links added successfully!'
			showMessage({ message })
		}
	}

	const [{ loading: saving }, handleAddMoreLinks] = useAxios(createCampaignsService({
		groupId,
		count: linksToAdd
	}), afterSave)

	const handleClearCampaign = (campaign) => () => {
		console.log({ campaign })
		if (_.isNil(campaign?.user)) {
			showMessage({ message: `This campaign don't have a wallet` })
			return
		}
		// eslint-disable-next-line no-restricted-globals
		const result = confirm("Are you sure you want to clear this link?");
		if (result === true) {
			clearCampaign({ data: { id: campaign?.id } })
		}
	}

	const handleLinksToAddChange = (e) => {
		setLinksToAdd(e.target.value)
	}

	useEffect(() => {
		fetchCampaigns()
	}, [limit, offset, search, reload])

	const loading = loadingUsers || clearing || saving

	return {
		limit,
		loading,
		linksToAdd,
		data: campaigns,
		handleClearCampaign,
		handleLinksToAddChange,
		handleAddMoreLinks,
		setOffset,
		setLimit,
		setSearch
	}
}

export default useTokenSaleCampaignsTable
