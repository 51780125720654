import { Row, Col, Card } from 'react-bootstrap'
import useReviewsTable from './useReviewsTable'
import { formatDate } from 'utils/date.utils'
import PageTitle from 'jsx/layouts/PageTitle'
import { CustomTable } from 'components/common/customTable'
import { SearchField } from 'components/common/searchField'

const frontBaseURL = process.env.REACT_APP_FRONT_BASE_URL

const ReviewsTable = () => {
	const { data, loading, limit, setLimit, setOffset, setSearch, handleApproveChange } =
		useReviewsTable()

	const columns = [
		{
			header: 'Project',
			render: (row) => (
				<a href={`${frontBaseURL}/project/${row.project.slug}`} target="_blank" rel="noreferrer">
					{row.project.title}
				</a>
			),
		},
		{
			header: 'Reviewer',
			render: (row) => (
				<a href={`${frontBaseURL}/profile/${row.user.username}`} target="_blank" rel="noreferrer">
					{row.user.displayName}
				</a>
			),
		},
		{
			header: 'Rating',
			render: (row) => <div>{row.rating} out of 5 stars</div>,
		},
		{
			header: 'Title',
			key: 'heading',
		},
		{
			header: 'Content',
			key: 'content',
		},
		{
			header: 'Date',
			render: (row) => formatDate(row.createdAt, 'inputDate'),
		},
		{
			header: 'Approved',
			render: (row) => {
				return (
					<div className="custom-control custom-checkbox checkbox-success check-lg">
						<input
							type="checkbox"
							checked={row.approved}
							className="custom-control-input"
							id={`customCheckBox${row.id}`}
							onChange={(e) => {
								handleApproveChange(row.id, e.target.checked)
							}}
						/>
						<label className="custom-control-label" htmlFor={`customCheckBox${row.id}`}></label>
					</div>
				)
			},
		},
	]

	return (
		<>
			<PageTitle title={'Reviews Management'} />
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>Current Reviews</Card.Title>
							<SearchField {...{ setSearch }} />
						</Card.Header>
						<Card.Body>
							<CustomTable
								{...{
									data,
									loading,
									limit,
									setLimit,
									setOffset,
									columns,
								}}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default ReviewsTable
