import { buildFormData } from 'utils/form.util'

export const getContests = (params) => ({
	method: 'get',
	url: '/contest/admin',
	params
})

export const setContestWinner = (data) => ({
	method: 'post',
	url: '/contest/winner',
	data,
	autoexec: false,
})

export const getContestById = (id, autoexec) => ({
	method: 'get',
	url: `/contest/${id}`,
	autoexec
})

export const enrollProjects = (data) => ({
	method: 'post',
	url: `/contest/enrollProjects`,
	data,
	autoexec: false
})

export const addContest = (data) => {
	const fields = ['name', 'endDate', 'startDate', 'isCompleted']
	const files = ['badge', 'badgeWinner']
	const formData = buildFormData(data, fields, files)
	return {
		method: 'post',
		url: '/contest',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		autoexec: false,
	}
}

export const editContest = (data) => {
	const fields = [
		'id',
		'name',
		'endDate',
		'startDate',
		'isCompleted',
		'onlyAdminJoin',
		'adminPreview'
	]
	const files = ['badge', 'badgeWinner', 'banner']
	const formData = buildFormData(data, fields, files)
	return {
		method: 'put',
		url: '/contest',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		autoexec: false,
	}
}
