import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { showMessage } from 'utils/notification.util'
import { failTransactionService, failTransactionsInProgressService, getTransactionsInProgress } from 'services/TransactionsService'

const useTroubleshoot = () => {
	const [search, setSearch] = useState('')
	const [limit, setLimit] = useState(5)
	const [offset, setOffset] = useState(0)
	const [reload, setReaload] = useState(true)
	const [transactions, setTransactions] = useState({
		rows: [],
		count: 0,
	})

	const afterFetchTransactionsInProgress = (data, error) => {
		if (!error) {
			setTransactions(data)
		}
	}

	const [{ loading: loadingUsers }, fetchTransactionsInProgress] = useAxios(
		getTransactionsInProgress({ limit, offset, search }),
		afterFetchTransactionsInProgress
	)

	const [{ loading: failingTransaction }, failTransaction] = useAxios(
		failTransactionService(),
		(_data, error) => {
			if (!error) {
				showMessage({ message: 'The transaction was set to failed successfully.' })
				setReaload(r => !r)
			}
		}
	)

	const [{ loading: failingAllTransactions }, failTransactionsInProgress] = useAxios(
		failTransactionsInProgressService(),
		(_data, error) => {
			if (!error) {
				showMessage({ message: 'The transactions were set to failed successfully.' })
				setOffset(0)
				setReaload(r => !r)
			}
		}
	)

	const handleFailTransaction = (transactionId) => () => {
		failTransaction({ data: { transactionId } })
	}

	const handleFailTransactionsInProgress = () => {
		failTransactionsInProgress()
	}

	useEffect(() => {
		fetchTransactionsInProgress()
	}, [limit, offset, reload])

	useEffect(() => {
		fetchTransactionsInProgress()
		setOffset(0)
	}, [search])

	const loading = loadingUsers || failingTransaction || failingAllTransactions

	return {
		limit,
		loading,
		data: transactions,
		handleFailTransactionsInProgress,
		handleFailTransaction,
		setOffset,
		setLimit,
		setSearch,
	}
}

export default useTroubleshoot
