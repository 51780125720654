import { CustomSelect } from 'components/common/customSelect'
import { useEffect } from 'react'
import { Button } from 'react-bootstrap'

const Step4 = ({
	data,
	cronObj,
	handleSave,
	handleChange,
	handleCancel,
	handleChangeFrequency,
	frequencies,
	monthDays,
	minutes,
	hours,
	days,
}) => {
	useEffect(() => {
		let { frequency, day, time, monthDay, minute } = cronObj
		switch (frequency) {
			case 0: //daily
				day = '*'
				monthDay = '*'
				break
			case 1: // weekly
				monthDay = '*'
				break
			case 2: // monthly
				day = '*'
				break
			default:
				break
		}
		handleChange({ frequency: `${minute} ${time} ${monthDay} * ${day}` })
	}, [cronObj])

	return (
		<div className="col-xl-12 col-lg-12">
			<div className="card">
				<div className="card-header">
					<h4 className="card-title">Frequency</h4>
				</div>
				<div className="card-body">
					<div className="basic-form">
						<form onSubmit={(e) => e.preventDefault()}>
							<div className="form-row">

								<div className="form-group col-md-3">
									<label>Send Frequency</label>
									<CustomSelect
										{...{
											value: cronObj.frequency,
											setValue: (frequency) => {
												handleChangeFrequency({ frequency })
											},
											options: frequencies,
										}}
									/>
								</div>
								{cronObj.frequency === 1 && <div className="form-group col-md-3">
									<label>Day</label>
									<CustomSelect
										{...{
											value: cronObj.day,
											setValue: (day) => {
												handleChangeFrequency({ day })
											},
											options: days,
										}}
									/>
								</div>}
								{cronObj.frequency === 2 && <div className="form-group col-md-3">
									<label>Month Day</label>
									<CustomSelect
										{...{
											value: cronObj.monthDay,
											setValue: (monthDay) => {
												handleChangeFrequency({ monthDay })
											},
											options: monthDays,
										}}
									/>
								</div>}
								<div className="form-group col-md-3">
									<label>Time</label>
									<CustomSelect
										{...{
											value: cronObj.time,
											setValue: (time) => {
												handleChangeFrequency({ time })
											},
											options: hours,
										}}
									/>
								</div>
								<div className="form-group col-md-3">
									<label>Minutes</label>
									<CustomSelect
										{...{
											value: cronObj.minute,
											setValue: (minute) => {
												handleChangeFrequency({ minute })
											},
											options: minutes,
										}}
									/>
								</div>
								<div className="form-group col-md-3">
									<label>Cron Expression</label>
									<input
										value={data.frequency}
										type="text"
										className="form-control"
										placeholder="Cron Job String"
										onChange={(e) => {
											handleChange({ frequency: e.target.value })
										}}
									/>
								</div>
							</div>

							<Button className="mr-2" variant="danger" onClick={handleCancel}>
								Cancel
							</Button>
							<Button variant="success" onClick={handleSave}>
								Save and continue
							</Button>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Step4
