import _ from 'lodash'
import { Link } from 'react-router-dom'
import { Row, Col, Card, Button, Badge } from 'react-bootstrap'
import useContestTable from './useContestTable'
import PageTitle from 'jsx/layouts/PageTitle'
import { CustomTable } from 'components/common/customTable'
import { SearchField } from 'components/common/searchField'
import { formatDate } from 'utils/date.utils'

const ContestTable = () => {
	const {
		data,
		loading,
		filters,
		handleChangeLimit,
		setOffset,
		handleChangeSearch,
		handleChangeSortColumn,
		handleChangeDirectioin,
	} = useContestTable()

	const columns = [
		{
			header: 'Badge',
			render: (row) => (
				<div className="d-flex align-items-center">
					<a href={row?.qrImage} target="_blank" rel="noreferrer">
						<img src={row.badge} className="rounded-md mr-2" width="24" alt="" />
					</a>
				</div>
			),
		},
		{
			header: 'Winner Badge',
			render: (row) => (
				<div className="d-flex align-items-center">
					<a href={row?.qrImage} target="_blank" rel="noreferrer">
						<img src={row.badgeWinner} className="rounded-md mr-2" width="24" alt="" />
					</a>
				</div>
			),
		},
		{ header: 'Name', key: 'name' },
		{ header: 'Winner', key: 'projectWinner.title' },
		{
			header: 'Created At',
			render: (row) => formatDate(_.get(row, 'createdAt', null), 'inputDate'),
		},
		{
			header: 'Projects count',
			render: (row) => <Link to={`/contest/projects/${row?.id}`}>{row?.projectCount}</Link>,
		},
		{
			header: 'Start Date',
			render: (row) => formatDate(_.get(row, 'startDate', null), 'inputDate'),
		},
		{
			header: 'End Date',
			render: (row) => formatDate(_.get(row, 'endDate', null), 'inputDate'),
		},
		{
			header: 'Completed',
			render: (row) =>
				row.isCompleted ? (
					<Badge variant="danger light">Yes</Badge>
				) : (
					<Badge variant="success light">No</Badge>
				)
		},
		{
			header: 'Action',
			render: (row) => (
				<div className="d-flex">
					<Link to={`/edit-contest/${row.id}`} className="btn btn-primary shadow btn-xs sharp mr-1">
						<i className="fa fa-pencil"></i>
					</Link>
				</div>
			),
		},
	]

	return (
		<>
			<PageTitle title={'Contest Management'} />

			<Row>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>Current Contests</Card.Title>

							<Link to="/new-contest">
								<Button className="mr-2" variant="success">
									Create Contest
								</Button>
							</Link>

							<SearchField {...{ search: filters.search, setSearch: handleChangeSearch }} />
						</Card.Header>
						<Card.Body>
							<CustomTable
								{...{
									data,
									loading,
									limit: filters?.limit,
									setLimit: handleChangeLimit,
									setOffset,
									columns,
									sortColumn: filters?.sortColumn,
									setSortColumn: handleChangeSortColumn,
									order: filters.direction,
									setOrder: handleChangeDirectioin,
								}}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default ContestTable
