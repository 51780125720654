import _ from 'lodash'
import { Row, Col, Card, Button } from 'react-bootstrap'
import useUsersRestrictedTable from './useUsersRestrictedTable'
import { formatDate } from 'utils/date.utils'
import PageTitle from 'jsx/layouts/PageTitle'
import { CustomTable } from 'components/common/customTable'
import { SearchField } from 'components/common/searchField'
import { CustomSelect } from 'components/common/customSelect'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const frontBaseURL = process.env.REACT_APP_FRONT_BASE_URL

const UsersRestrictedTable = () => {
	const {
		data,
		limit,
		loading,
		endDate,
		userList,
		selectedUser,
		handleDeleteRestriction,
		handleAddRestriction,
		setSelectedUser,
		setSearchUser,
		setEndDate,
		setOffset,
		setLimit,
		setSearch
	} = useUsersRestrictedTable()

	const columns = [
		{
			header: 'Join Date',
			render: (row) => formatDate(_.get(row, 'createdAt', null), 'inputDate'),
		},
		{
			header: 'Last Sign In',
			render: (row) => formatDate(_.get(row, 'lastSignIn', null), 'timestamp'),
		},
		{
			header: 'End Date',
			render: (row) => formatDate(_.get(row, 'restrictionEndDate', null), 'inputDate'),
		},
		{
			header: 'User',
			render: (row) => (
				<a href={`${frontBaseURL}/profile/${row.username}`} target="_blank" rel="noreferrer">
					<div className="d-flex align-items-center">
						<img src={row.avatar} className="rounded-md mr-2" width="24" alt="" /> {row.displayName}
					</div>{' '}
				</a>
			),
		},
		{ header: 'Username', key: 'username' },
		{ header: 'Email', key: 'email' },
		{
			header: 'Action',
			render: (row) => (
				<div className="d-flex">
					<Button className="btn btn-primary shadow btn-xs sharp mr-1" onClick={handleDeleteRestriction(row?.id)}>
						<i className="fa fa-trash"></i>
					</Button>
				</div>
			)
		}
	]

	return (
		<>
			<PageTitle title={'Restricted Users Management'} />

			<Row>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<div className="row col-md-10">
								<div className="form-group col-md-5">
									<CustomSelect
										{...{
											fieldName: 'email',
											placeholder: 'Users',
											value: selectedUser,
											setValue: setSelectedUser,
											options: userList,
											onInputChange: setSearchUser
										}}
									/>
								</div>

								<div className="form-group col-md-5">
									<DatePicker
										selected={endDate}
										placeholderText='Restriction End Date'
										onChange={setEndDate}
										className="form-control"
									/>
								</div>
								<div className="form-group col-md-1">
									<button className="btn btn-primary shadow sharp mt-1" onClick={handleAddRestriction}>
										<i className="fa fa-plus"></i>
									</button>
								</div>
							</div>
							<SearchField {...{ setSearch }} />
						</Card.Header>
						<Card.Body>
							<CustomTable
								{...{
									data,
									loading,
									limit,
									setLimit,
									setOffset,
									columns,
								}}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default UsersRestrictedTable
