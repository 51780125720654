import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

const ConfirmModal = ({ isOpen, handleClose, text, handleConfirm, confirmText, cancelText }) =>
	<Modal className="fade" show={isOpen} onHide={handleClose}>
		<Modal.Header>
			<h4 className="text-black">Confirmation</h4>
			<Button
				onClick={handleClose}
				variant=""
				className="close"
			>
				<span>&times;</span>
			</Button>
		</Modal.Header>
		<Modal.Body>
			<p>
				{text}
			</p>
		</Modal.Body>
		<Modal.Footer>
			<Button
				onClick={handleClose}
				variant="danger light"
			>
				{cancelText ?? 'Close'}
			</Button>
			<Button variant="primary" onClick={handleConfirm}>{confirmText ?? 'Confirm changes'}</Button>
		</Modal.Footer>
	</Modal>

const useConfirmModal = () => {
	const [isOpen, setIsOpen] = useState(false);

	function handleClose() {
		setIsOpen(false)
	}

	function handleOpen() {
		setIsOpen(true)
	}

	return {
		isOpen,
		handleClose,
		handleOpen
	}
};

export {
	ConfirmModal,
	useConfirmModal
}
