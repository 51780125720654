import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { getGoScoreConfiguration, updateGoScoreConfiguration, updateGoScores, projectDecayEmails } from 'services/GoScoreService'
import { getGoScoreStages } from 'services/SeedServices'
import { showMessage } from 'utils/notification.util'

const useGoScoreBadgeForm = () => {
	const [stages, setStages] = useState([])
	const [testEmails, setTestEmails] = useState(null)
	const [data, setData] = useState({
		perfectScore: '',
		goScoreStageAllowed: '',
		reviewWeightage: '',
		ratingWeightage: '',
		fanTokenWeightage: '',
		shareWeightage: '',
		followersWeightage: '',
		viewsWeightage: '',
		fanTokenCatWeightage: '',
		shareCatWeightage: '',
		followersCatWeightage: '',
		viewsCatWeightage: '',
		ratingreviewCatWeightage: '',
	})

	const afterFetchGoScoreStages = (data, error) => {
		if (!error) {
			setStages(data)
		}
	}

	const [{ loading: loadingStages }] = useAxios(getGoScoreStages(true), afterFetchGoScoreStages)

	const afterFetchConfig = (config, error) => {
		if (!error) {
			setData(config)
		}
	}

	const [{ loading }] = useAxios(getGoScoreConfiguration(), afterFetchConfig)

	const afterUpdateConfig = (config, error) => {
		if (!error) {
			setData(config)
			const message = 'GoScore configuration updated successfully!'
			showMessage({ message })
		}
	}
	const [{ loading: updating }, updateConfig] = useAxios(updateGoScoreConfiguration(data), afterUpdateConfig)

	const afterUpdateGoScores = (_config, error) => {
		if (!error) {
			const message = 'GoScores updated successfully!'
			showMessage({ message })
		}
	}
	const [{ loading: saving }, fillGoScores] = useAxios(updateGoScores(), afterUpdateGoScores)

	const afterSendProjectDecayEmails = (_config, error) => {
		if (!error) {
			const message = 'Project Decay Emails Send Successfully!'
			showMessage({ message })
		}
	}
	const [{ loading: sending }, sendDecayEmails] = useAxios(projectDecayEmails(testEmails), afterSendProjectDecayEmails)

	const handleSaveChanges = () => {
		updateConfig()
	}

	const handleUpdateGoscore = () => {
		fillGoScores()
	}

	const handleSendProjectDecayNotificationEmails = () => {
		sendDecayEmails()
	}

	const showMask = loading || saving || loadingStages || updating || sending

	const handleChange = (payload) => {
		setData({
			...data,
			...payload,
		})
	}

	return {
		data,
		showMask,
		stages,
		handleChange,
		handleSaveChanges,
		handleUpdateGoscore,
		handleSendProjectDecayNotificationEmails,
		setTestEmails,
		testEmails
	}
}

export default useGoScoreBadgeForm
