export const getBadges = (params) => ({
	method: 'get',
	url: '/goScoreBadge',
	params,
	autoexec: false,
})

export const getBadgeById = (autoexec, id) => ({
	method: 'get',
	url: `/goScoreBadge/${id}`,
	autoexec,
})

const buildFormBadgeData = (data, edit) => {
	const formData = new FormData()
	const fields = ['metricId', 'name', 'description', 'threshold', 'reward', 'image', 'active']
	if (edit) fields.push('id')

	const files = ['image']

	fields.forEach((key) => {
		if (data[key] instanceof File) {
			formData.append(key, data[key], data[key].name)
		} else if (!files.includes(key)) {
			formData.append(key, data[key])
		}
	})
	return formData
}

export const addGoScoreBadge = (data) => {
	const formData = buildFormBadgeData(data)
	return {
		method: 'post',
		url: '/goScoreBadge',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		autoexec: false,
	}
}

export const editGoScoreBadge = (data) => {
	const formData = buildFormBadgeData(data, true)
	return {
		method: 'put',
		url: '/goScoreBadge',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		autoexec: false,
	}
}

export const getGoScoreStages = () => ({
	method: 'get',
	url: '/goScoreStage',
	autoexec: true,
})

export const getGoScoreStageById = (id) => ({
	method: 'get',
	url: `/goScoreStage/${id}`,
	autoexec: true,
})

export const editGoScoreStage = (data) => {
	return {
		method: 'put',
		url: '/goScoreStage',
		data,
		autoexec: false,
	}
}

export const editGoScoreMilestone = (milestones) => {
	const data = {
		...milestones,
		milestoneMetrics: milestones.milestoneMetrics.map((el) => {
			const { icon, ...metric } = el
			return metric
		}),
	}
	return {
		method: 'put',
		url: '/goScoreMilestone',
		data,
		autoexec: false,
	}
}

export const uploadGoScoreMilestoneMetricImages = (metrics) => {
	const formData = new FormData()
	metrics.forEach((el) => {
		if (el.icon instanceof File) formData.append(el.id, el.icon, el.icon.name)
	})

	return {
		method: 'put',
		url: '/goScoreMilestone/uploadMetricsImages',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		autoexec: false,
	}
}

export const getGoScoreConfiguration = () => ({
	method: 'get',
	url: '/goscore/config',
	autoexec: true,
})

export const updateGoScoreConfiguration = (data) => ({
	method: 'put',
	url: '/goscore/config',
	autoexec: false,
	data
})

export const updateGoScores = () => ({
	method: 'post',
	url: '/goscore',
	autoexec: false
})

export const projectDecayEmails = (testEmails) => ({
	method: 'get',
	url: '/goScoreV2/notify-project-decay',
	params: { testEmails },
	autoexec: false
})
