import { Button } from 'react-bootstrap'
import useCollaboratorRoleForm from './useCollaboratorRoleForm'
import { LoadMask } from 'components/common/loadMask'
import { CustomCheckbox } from 'components/common/customCheckbox'

const CollaboratorRoleForm = () => {
	const {
		data,
		title,
		loading,
		permissions,
		isPermissionChecked,
		handlePermissionChange,
		handleChange,
		handleCancel,
		handleSave
	} = useCollaboratorRoleForm()

	const renderPermissions = () => {
		return permissions.map(el => {
			return <div className="form-group col-md-12" key={el?.id}>
				<CustomCheckbox
					label={el?.name}
					checked={isPermissionChecked(el?.id)}
					setChecked={(checked) => {
						handlePermissionChange({ checked, id: el?.id })
					}}
				/>
			</div>
		})
	}
	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">{title}</h4>
							</div>
							<div className="card-body">
								<div className="basic-form">
									<form onSubmit={(e) => e.preventDefault()}>
										<div className="form-row">
											<div className="form-group col-md-12">
												<label>Name</label>
												<input
													value={data.name}
													type="text"
													className="form-control"
													placeholder="Level Name"
													onChange={(e) => {
														handleChange({ name: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-12">
												<h4>Permissions</h4>
											</div>
											{renderPermissions()}
										</div>
										<Button className="mr-2" variant="success" onClick={handleSave}>
											Save
										</Button>
										<Button variant="danger" onClick={handleCancel}>
											Cancel
										</Button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default CollaboratorRoleForm
