import { Row, Col, Card, Button } from 'react-bootstrap'
import useTransactionsTable from './useTransactionsTable'
import { DateTime } from 'luxon'
import { TransactionDetailsModal } from './TransactionDetailsModal'
import PageTitle from 'jsx/layouts/PageTitle'
import styles from './TransactionsTable.module.scss'
import { CustomSelect } from 'components/common/customSelect'
import { CustomTable } from 'components/common/customTable'
import { SearchField } from 'components/common/searchField'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const TransactionsTable = () => {
	const {
		loading,
		data,
		project,
		filters,
		handleClearFilter,
		handleChangeLimit,
		setOffset,
		handleChangeSearch,
		handleChangeSortColumn,
		handleChangeDirectioin,
		handleChangeStatus,
		handleChangeType,
		handleChangeFromDateRange,
		handleChangeToDateRange,
		handleReconcileTransaction,
		statuses,
		types,
		selectedRow,
		show,
		setShow,
		handleViewDetails,
	} = useTransactionsTable()

	const columns = [
		{
			header: 'Date & Time',
			render: (row) =>
				<div>
					{DateTime.fromJSDate(new Date(row.createdAt), { zone: 'America/Los_Angeles' }).toFormat('yyyy-MM-dd  HH:mm:ss')}
				</div>
		},
		{
			header: 'Name',
			key: 'user.displayName'

		},
		{
			header: 'Email',
			key: 'user.email'
		},
		{
			header: 'Type',
			key: 'type.name'
		},
		{
			header: 'Amount',
			key: 'amount',
		},
		{
			header: 'Status',
			render: (row) => <div className={row.status.name}>{row.status.name}</div>,
		},
		{
			header: 'Action',
			render: (row) => (
				<div className="d-flex">
					<div onClick={() => { handleViewDetails(row) }} className="btn btn-primary shadow btn-xs sharp mr-1">
						<i className="fa fa-eye"></i>
					</div>
					{row.status.id === 3 && <div onClick={() => { handleReconcileTransaction(row.id) }} className="btn btn-primary shadow btn-xs sharp mr-1">
						<i className="fa fa-pencil"></i>
					</div>}
				</div>
			),
		},
	]
	return (
		<>
			<TransactionDetailsModal show={show}
				onHide={() => setShow(false)} row={selectedRow} project={project}/>
			<PageTitle title={'Transactions Management'} />
			<Row className={styles.transaction_table}>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title className='col-5 flex-fill mw-100' >Current Transactions</Card.Title>
							<SearchField {...{ search: filters.search, setSearch: handleChangeSearch }} />
							<Button onClick={handleClearFilter} className={styles.refresh_btn}>
								<i className="fa fa-refresh" aria-hidden="true"></i>
							</Button>

						</Card.Header>
						<Card.Header>
							<Row>
								<Col xs={12} md={3} xl={3}>
									<label>Status</label>
									<CustomSelect
										{...{
											value: filters.status,
											setValue: handleChangeStatus,
											options: statuses,
											className: styles.stages,
										}}
									/>
								</Col>
								<Col xs={12} md={3} xl={3}>
									<label>Type</label>
									<CustomSelect
										{...{
											value: filters.type,
											setValue: handleChangeType,
											options: types,
											className: styles.stages,
										}}
									/>
								</Col>
								<Col xs={12} md={3} xl={3}>
									<label>Start Date</label>
									<DatePicker selected={filters?.from} onChange={(from) => handleChangeFromDateRange({ from })} className="form-control" />
								</Col>
								<Col xs={12} md={3} xl={3}>
									<label>End Date</label>
									<DatePicker selected={filters?.to} onChange={(to) => handleChangeToDateRange({ to })} className="form-control" />
								</Col>
							</Row>
						</Card.Header>
						<Card.Body>
							<CustomTable
								{...{
									data,
									loading,
									limit: filters?.limit,
									setLimit: handleChangeLimit,
									setOffset,
									columns,
									sortColumn: filters?.sortColumn,
									setSortColumn: handleChangeSortColumn,
									order: filters.direction,
									setOrder: handleChangeDirectioin,
									maxLimit: 1000
								}}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default TransactionsTable
