export const getReviews = (params) => ({
	method: 'get',
	url: '/review',
	params,
	autoexec: false,
})

export const editReview = (data) => ({
	method: 'put',
	url: '/review',
	data,
	autoexec: false,
})
