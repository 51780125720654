export const getConfiguration = () => ({
	method: 'get',
	url: '/configuration',
	autoexec: true,
})

export const updateConfiguration = (data) => ({
	method: 'put',
	url: '/configuration',
	autoexec: false,
	data
})