import { Button } from 'react-bootstrap'
import useCampaignForm from './useCampaignForm'
import { LoadMask } from 'components/common/loadMask'
import { UploadFile } from 'components/common/uploadFile'
import { CustomCheckbox } from 'components/common/customCheckbox'
import { CustomSelect } from 'components/common/customSelect'
import { getFileDefaultValue } from 'utils/form.util'
import HtmlEditor from 'components/common/htmlEditor/HtmlEditor'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const frontBaseURL = process.env.REACT_APP_FRONT_BASE_URL

const CampaignForm = () => {
	const {
		data,
		loading,
		userTypes,
		airdropCampaigns,
		handleAddNewBenefit,
		handleChangeBenefit,
		handleRemoveBenefit,
		handleCancel,
		handleChange,
		saveChanges
	} = useCampaignForm()

	const renderBenefits = () => {
		return data?.benefits?.map((benefit, idx) => {
			return (
				<div
					className="form-row"
					key={`benefit-${idx}`}
					style={{
						borderTop: '1px solid #473F72',
						paddingTop: '20px',
					}}
				>
					<div className="form-group col-md-11">
						<label>Description</label>
						<input
							value={benefit.description}
							type="text"
							className="form-control"
							placeholder="Description"
							onChange={(e) => {
								handleChangeBenefit({ description: e.target.value }, idx)
							}}
						/>
					</div>
					<div
						className="col-md-1"
						style={{
							display: 'flex',
							alignItems: 'end',
							marginBottom: '20px',
						}}
					>
						<Button className="mr-2" variant="danger" onClick={handleRemoveBenefit(idx)}>
							<i className="fa fa-trash"></i>
						</Button>
					</div>
				</div>
			)
		})
	}

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">Campaign Details</h4>
							</div>
							<div className="card-body">
								<div className="basic-form">
									<form onSubmit={(e) => e.preventDefault()}>
										<div className="form-row">
											<div className="form-group col-md-6">
												<label>Campaign Name*</label>
												<input
													value={data.name}
													type="text"
													className="form-control"
													placeholder="Campaign Name"
													onChange={(e) => {
														handleChange({ name: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>URL*</label>
												<div className="input-group mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text">
															{frontBaseURL}/onboard/join/
														</span>
													</div>
													<input
														value={data.slug}
														type="text"
														className="form-control"
														placeholder="URL"
														onChange={(e) => {
															handleChange({ slug: e.target.value })
														}}
													/>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label>Title</label>
												<input
													value={data.title}
													type="text"
													className="form-control"
													placeholder="Title"
													onChange={(e) => {
														handleChange({ title: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-3">
												<label>Start Date*</label>
												<DatePicker selected={data?.startDate} onChange={(startDate) => handleChange({ startDate })} className="form-control" />
											</div>
											<div className="form-group col-md-3">
												<label>End Date*</label>
												<DatePicker selected={data?.endDate} onChange={(endDate) => handleChange({ endDate })} className="form-control" />
											</div>
											<div className="form-group col-md-6">
												<label>Form Top Text</label>
												<input
													value={data.formText}
													type="text"
													className="form-control"
													placeholder="Form Top Text"
													onChange={(e) => {
														handleChange({ formText: e.target.value })
													}}
												/>
											</div>
											<div className="input-group mb-3 col-md-3">
												<label>Hero Image</label>
												<UploadFile
													defaultValue={getFileDefaultValue(data, 'headerImage')}
													setFile={(headerImage) => {
														handleChange({ headerImage })
													}}
												/>
											</div>
											<div className="input-group mb-3 col-md-3">
												<label>Partner Logo</label>
												<UploadFile
													defaultValue={getFileDefaultValue(data, 'logo')}
													setFile={(logo) => {
														handleChange({ logo })
													}}
												/>
											</div>
											<div className="form-group col-md-12">
												<label>Subtext</label>
												<textarea
													value={data.subtext}
													className="form-control"
													rows="4"
													placeholder="Subtext"
													onChange={(e) => {
														handleChange({ subtext: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-3">
												<label>Limit Signups</label>
												<input
													value={data.limitSignups}
													type="number"
													className="form-control"
													placeholder="Limit Signups"
													onChange={(e) => {
														handleChange({ limitSignups: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-3">
												<label>Additional FAN Tokens</label>
												<input
													value={data.reward}
													type="number"
													className="form-control"
													placeholder="Reward"
													onChange={(e) => {
														handleChange({ reward: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>User Group</label>
												<CustomSelect
													{...{
														value: data.userTypeId,
														setValue: (userTypeId) => {
															handleChange({ userTypeId })
														},
														options: userTypes,
													}}
												/>
											</div>
											<div className="form-group col-md-2">
												<CustomCheckbox
													label="Is Airdrop"
													checked={data.isAirdrop}
													setChecked={(isAirdrop) => {
														handleChange({ isAirdrop })
													}}
												/>
											</div>
											{!data?.isAirdrop &&
												<div className="form-group col-md-12">
													<label>Airdrop Campaign</label>
													<CustomSelect
														{...{
															value: data.campaignId,
															setValue: (campaignId) => {
																handleChange({ campaignId })
															},
															options: airdropCampaigns,
														}}
													/>
												</div>}
											<div className="form-group col-md-12">
												<label>Award Message</label>
												<input
													value={data.rewardMessage}
													type="text"
													className="form-control"
													placeholder="Award Message"
													onChange={(e) => {
														handleChange({ rewardMessage: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-12">
												<label>Welcome message</label>
												<HtmlEditor
													{...{
														value: data?.welcomeMessage,
														setValue: value => {
															handleChange({
																welcomeMessage: value
															})
														}
													}}
												/>
											</div>
										</div>
										{renderBenefits()}
										<div className="form-row mb-3">
											<Button className="mr-2" variant="info" onClick={handleAddNewBenefit}>
												Add New Benefit
											</Button>
										</div>
										<Button className="mr-2" variant="success" onClick={() => saveChanges()}>
											Save
										</Button>
										<Button variant="danger" onClick={handleCancel}>
											Cancel
										</Button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default CampaignForm
