import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { getDashboardInfo } from 'services/DashboardService'

const useDashboard = () => {
	const [data, setData] = useState({})

	const afterFetchUsers = (data, error) => {
		if (!error) {
			setData(data)
		}
	}

	const [{ loading }] = useAxios(
		getDashboardInfo(),
		afterFetchUsers
	)

	return {
		data,
		loading
	}
}

export default useDashboard
