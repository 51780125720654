import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { useHistory } from 'react-router-dom'
import { showMessage } from 'utils/notification.util'
import { getUserTypes } from 'services/SeedServices'
import { addUser } from 'services/UserService'

const useUserForm = () => {
	const history = useHistory()
	const [userTypes, setUserTypes] = useState([])
	const [data, setData] = useState({
		username: null,
		password: null,
		firstName: null,
		lastName: null,
		email: null,
		typeId: null,
	})

	const afterFetchUserTypes = (data, error) => {
		if (!error) {
			setUserTypes(data)
		}
	}

	const [{ loading: loadingUserTypes }] = useAxios(getUserTypes(true), afterFetchUserTypes)

	const afterSave = (_data, error) => {
		if (!error) {
			const message = 'User added successfully!'
			showMessage({ message })
			history.push('/users')
		}
	}

	const [{ loading: saving }, saveChanges] = useAxios(addUser(data), afterSave)

	const handleChange = (payload) => {
		setData({
			...data,
			...payload,
		})
	}

	const handleCancel = () => {
		history.goBack()
	}

	const loading = loadingUserTypes || saving

	return {
		data,
		loading,
		userTypes,
		saveChanges,
		handleCancel,
		handleChange,
	}
}

export default useUserForm
