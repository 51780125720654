import { Link } from 'react-router-dom'
import { Row, Col, Card, Table, Button } from 'react-bootstrap'
import PageTitle from 'jsx/layouts/PageTitle'
import useCollaboratorRoleTable from './useCollaboratorRoleTable'
import { LoadMask } from 'components/common/loadMask'

const CollaboratorRoleTable = () => {
	const { levels, loading } = useCollaboratorRoleTable()

	const renderStages = () => {
		return levels.map((role) => {
			return (
				<tr key={`stage-${role.id}`}>
					<td>{role.name}</td>
					<td>{role.permissions.map(el => el?.name).join(' | ')}</td>
					<td>
						<div className="d-flex">
							<Link
								to={`/edit-collaborator-role/${role.id}`}
								className="btn btn-primary shadow btn-xs sharp mr-1"
							>
								<i className="fa fa-pencil"></i>
							</Link>
						</div>
					</td>
				</tr>
			)
		})
	}

	return (
		<>
			<PageTitle title={'Collaborator Roles'} />
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>Current Collaborator Roles</Card.Title>
							<Link to="new-collaborator-role">
								<Button className="mr-2" variant="success">
									Create Role
								</Button>
							</Link>
						</Card.Header>
						<Card.Body>
							{loading ? (
								<LoadMask />
							) : (
								<Table responsive>
									<thead>
										<tr>
											<th>
												<strong>Name</strong>
											</th>
											<th>
												<strong>Permissions</strong>
											</th>
											<th>
												<strong>Action</strong>
											</th>
										</tr>
									</thead>
									<tbody>{renderStages()}</tbody>
								</Table>
							)}
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default CollaboratorRoleTable
