import _ from 'lodash'
import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { useHistory, useParams } from 'react-router-dom'
import { showMessage } from 'utils/notification.util'
import {
	addTriggeredEmailService,
	editTriggeredEmailService,
	getEmailTemplatesService,
	getTriggeredEmailByIdService,
	getTriggeredEmailTypesService
} from 'services/EmailNotificationServices'
import { useDispatch, useSelector } from 'react-redux'
import { setStep } from './triggeredFormSlice'

const useTriggeredForm = () => {
	const history = useHistory()
	const { id } = useParams()
	const [notificationTypes, setNotificationTypes] = useState([])
	const [templates, setTemplates] = useState([])
	const [data, setData] = useState({
		name: '',
		subject: '',
		fromEmail: 'no-reply@filmio.com',
		fromName: 'Film.io Notifications',
		typeId: null,
		templateSlug: '',
		threshold: 0,
		active: false
	})
	const { step } = useSelector((state) => state.triggeredForm)
	const dispatch = useDispatch()

	const afterFetchNotificationTypes = (data, error) => {
		if (!error) {
			setNotificationTypes(data)
		}
	}

	const [{ loading: loadingTypes }] = useAxios(getTriggeredEmailTypesService(), afterFetchNotificationTypes)

	const afterFetchTemplates = (data, error) => {
		if (!error) {
			const temps = data?.map(el => ({
				id: el.slug,
				...el
			}))
			setTemplates(temps)
		}
	}

	const [{ loading: loadingTemplates }] = useAxios(getEmailTemplatesService(), afterFetchTemplates)

	const afterFetchtDigestEmail = (data, error) => {
		if (!error) {
			setData(data)
		}
	}

	const [{ loading: loadingEdit }] = useAxios(getTriggeredEmailByIdService(id, !_.isNil(id)), afterFetchtDigestEmail)

	const service = _.isNil(id) ? addTriggeredEmailService(data) : editTriggeredEmailService(data)
	const afterSave = (data, error) => {
		if (!error) {
			const message = `Notification Email ${_.isNil(id) ? 'created' : 'updated'} successfully!`
			showMessage({ message })
			if (_.isNil(id)) {
				history.push(`/edit-triggered-email/${data?.id}`)
				dispatch(setStep(1))
			} else if (step < 3) {
				dispatch(setStep(step + 1))
				history.push(`/edit-triggered-email/${data?.id}`)
			} else {
				history.push('/email-triggered')
			}
		}
	}
	const [{ loading: saving }, saveChanges] = useAxios(service, afterSave)

	const handleChange = (payload) => {
		setData({
			...data,
			...payload,
		})
	}

	const handleCancel = () => {
		history.push('/email-triggered')
	}

	const handleChangeStep = (stp) => () => {
		dispatch(setStep(stp))
	}

	const handleSave = () => {
		saveChanges()
	}

	const handlePublish = () => {
		saveChanges({ data: { ...data, active: 1 } })
	}

	const loading = loadingTypes || saving || saving || loadingTemplates || loadingEdit

	return {
		step,
		data,
		loading,
		templates,
		notificationTypes,
		handleChangeStep,
		handlePublish,
		handleChange,
		handleCancel,
		handleSave
	}
}

export default useTriggeredForm
