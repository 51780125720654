export const getStudios = (params) => ({
	method: 'get',
	url: '/studio',
	params,
	autoexec: false,
})

export const addStudio = (data) => ({
	method: 'post',
	url: '/studio',
	data,
	autoexec: false,
})

export const editStudio = (data) => ({
	method: 'put',
	url: '/studio',
	data,
	autoexec: false,
})

export const getStudioById = (autoexec, id) => ({
	method: 'get',
	url: `/studio/${id}`,
	autoexec,
})
