import { CustomSelect } from 'components/common/customSelect'
import { Button } from 'react-bootstrap'

const Step3 = ({
	handleChange,
	data,
	handleSave,
	handleCancel,
	templates
}) => {
	const template = templates.find(el => el.slug === data.templateSlug)
	return (
		<div className="col-xl-12 col-lg-12">
			<div className="card">
				<div className="card-header">
					<h4 className="card-title">Template</h4>
				</div>
				<div className="card-body">
					<div className="basic-form">
						<form onSubmit={(e) => e.preventDefault()}>
							<div className="form-row mb-3">
								<div className="form-group col-md-12">
									<label>Template</label>
									<CustomSelect
										{...{
											value: data.templateSlug,
											setValue: (templateSlug, templateName) => {
												handleChange({ templateSlug, templateName })
											},
											options: templates,
										}}
									/>
								</div>
								<div dangerouslySetInnerHTML={{ __html: template?.code }}
									className='col-md-12'
									id='template_view'>
								</div>
							</div>
							<Button className="mr-2" variant="danger" onClick={handleCancel}>
								Cancel
							</Button>
							<Button variant="success" onClick={handleSave}>
								Save and continue
							</Button>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Step3
