import _ from 'lodash'
import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { getCollaboratorPermissions } from 'services/SeedServices'
import { useHistory, useParams } from 'react-router-dom'
import { showMessage } from 'utils/notification.util'
import { addCollaboratorRole, editCollaboratorRole, getCollaboratorRoleById } from 'services/CollaboratorRoleService'

const useCollaboratorRoleForm = () => {
	const history = useHistory()
	const { id } = useParams()
	const [permissions, setPermissions] = useState([])
	const [data, setData] = useState({
		name: '',
		permissions: []
	})

	const afterFetchPermissions = (data, error) => {
		if (!error) {
			setPermissions(data)
		}
	}

	const [{ loading: loadingPermissions }] = useAxios(getCollaboratorPermissions(), afterFetchPermissions)

	const afterFetchRole = (data, error) => {
		if (!error) {
			data.permissions = data?.permissions?.map(el => el?.id)
			setData(data)
		}
	}
	const [{ loading: loadingRole }] = useAxios(getCollaboratorRoleById(id, !_.isNil(id)), afterFetchRole)

	const service = _.isNil(id) ? addCollaboratorRole(data) : editCollaboratorRole(data)
	const afterSave = (_data, error) => {
		if (!error) {
			const message = 'Collaborator Role updated successfully!'
			showMessage({ message })
			history.push('/collaborator-roles')
		}
	}
	const [{ loading: saving }, saveChanges] = useAxios(service, afterSave)

	const handleChange = (payload) => {
		setData({
			...data,
			...payload,
		})
	}

	const handleCancel = () => {
		history.goBack()
	}

	const handleSave = () => {
		saveChanges()
	}

	const isPermissionChecked = (id) => {
		return data.permissions.includes(id)
	}

	const handlePermissionChange = ({ checked, id }) => {
		if (checked) setData({
			...data,
			permissions: [...data.permissions, id]
		})
		else setData(r => {
			const permissions = r.permissions.filter(el => el !== id)
			return {
				...r,
				permissions
			}
		})
	}

	const title = _.isNil(id) ? 'Collaborator Role Details' : 'Edit Collaborator Role'

	const loading = loadingPermissions || saving || loadingRole || saving

	return {
		data,
		title,
		loading,
		permissions,
		isPermissionChecked,
		handlePermissionChange,
		handleChange,
		handleCancel,
		handleSave
	}
}

export default useCollaboratorRoleForm
