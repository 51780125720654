import { Row, Col, Card, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useTriggeredTable from './useTriggeredTable'
import { formatDate } from 'utils/date.utils'
import PageTitle from 'jsx/layouts/PageTitle'
import { CustomTable } from 'components/common/customTable'
import { SearchField } from 'components/common/searchField'

const TriggeredTable = () => {
	const {
		data,
		loading,
		limit,
		setLimit,
		setOffset,
		setSearch,
		handleActivate,
		handleEditClick
	} = useTriggeredTable()

	const columns = [
		{
			header: 'Name',
			key: 'name',
		},
		{
			header: 'Type',
			key: 'type.name',
		},
		{
			header: 'Template',
			key: 'templateName',
		},
		{
			header: 'Subject',
			key: 'subject',
		},
		// {
		// 	header: 'Threshold',
		// 	key: 'threshold',
		// },
		{
			header: 'From Email',
			key: 'fromEmail',
		},
		{
			header: 'From Name',
			key: 'fromName',
		},
		{
			header: 'Date',
			render: (row) => formatDate(row.createdAt, 'inputDate'),
		},
		{
			header: 'Active',
			render: (row) => {
				return (
					<div className="custom-control custom-checkbox checkbox-success check-lg">
						<input
							type="checkbox"
							checked={row.active}
							className="custom-control-input"
							id={`customCheckBox${row.id}`}
							onChange={(e) => {
								handleActivate(row.id, e.target.checked)
							}}
						/>
						<label className="custom-control-label" htmlFor={`customCheckBox${row.id}`}></label>
					</div>
				)
			},
		},
		{
			header: 'Action',
			render: (row) => (
				<div className="d-flex">
					<Link to={`/edit-triggered-email/${row.id}`} onClick={handleEditClick}
						className="btn btn-primary shadow btn-xs sharp mr-1">
						<i className="fa fa-pencil"></i>
					</Link>
				</div>
			),
		}
	]

	return (
		<>
			<PageTitle title={'Triggered Email Management'} />
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>Current Triggered Emails</Card.Title>

							<Link to="create-email-triggered" onClick={handleEditClick}>
								<Button className="mr-2" variant="success">
									Create Triggered Email
								</Button>
							</Link>

							<SearchField {...{ setSearch }} />
						</Card.Header>
						<Card.Body>
							<CustomTable
								{...{
									data,
									loading,
									limit,
									setLimit,
									setOffset,
									columns,
								}}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default TriggeredTable
