import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { getProfileCounts } from 'services/UserService'

const useUserProfile = (id) => {
	const [profileCounts, setProfileCounts] = useState({
		followersCount: 0,
		followingCount: 0,
		projectsCount: 0,
	})
	const afterProfileCounts = (g, error) => {
		if (!error) {
			setProfileCounts(g)
		}
	}

	const [{ loading }] = useAxios(getProfileCounts(true, id), afterProfileCounts)

	return {
		loading,
		profileCounts,
	}
}

export default useUserProfile
