import { Button } from 'react-bootstrap'
import useReports from './useReports'
import { LoadMask } from 'components/common/loadMask'

const Reports = () => {
	const { downloadReport, loading } = useReports()

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<>
					<div className="row">
						<div className="col-xl-2 col-sm-6">
							<div className="card">
								<div className="card-body text-center">
									<h4 className="text-black mb-4 font-w500">
										{'Marketing Cohort Report (detailed)'}
									</h4>
									<Button
										className="mr-2"
										variant="success"
										onClick={downloadReport}
										disabled={loading}
									>
										Download Report
									</Button>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	)
}
export default Reports
