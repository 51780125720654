import React from 'react'

const Footer = () => {
	var d = new Date()
	return (
		<div className="footer">
			<div className="copyright">
				<p>
					Copyright © Developed by{' '}
					<a href="http://film.io/" target="_blank" rel="noreferrer">
						Film.io
					</a>{' '}
					{d.getFullYear()}
				</p>
			</div>
		</div>
	)
}

export default Footer
