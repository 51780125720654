import { Suspense } from 'react'

/// Components
import Index from './jsx'
import { withRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
/// Style
import './vendor/swiper/swiper-bundle.css'
import './vendor/bootstrap-select/dist/css/bootstrap-select.min.css'
import 'utils/axios.util'
import './css/style.css'
import 'react-toastify/dist/ReactToastify.css'

function App(props) {
	return (
		<>
			<Suspense
				fallback={
					<div id="preloader">
						<div className="sk-three-bounce">
							<div className="sk-child sk-bounce1"></div>
							<div className="sk-child sk-bounce2"></div>
							<div className="sk-child sk-bounce3"></div>
						</div>
					</div>
				}
			>
				<Index />
			</Suspense>
			<ToastContainer />
		</>
	)
}

export default withRouter(App)
