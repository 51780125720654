import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { editNewsreel, getNewsreels } from 'services/NewsreelService'
import { showMessage } from 'utils/notification.util'

const useNewsreelsTable = () => {
	const [limit, setLimit] = useState(5)
	const [offset, setOffset] = useState(0)
	const [reviews, setNewsreels] = useState({
		rows: [],
		count: 0,
	})

	const afterFetchNewsreels = (data, error) => {
		if (!error) {
			setNewsreels(data)
		}
	}

	const [{ loading: loadingNewsreels }, fetchNewsreels] = useAxios(
		getNewsreels(limit, offset),
		afterFetchNewsreels
	)

	const afterEdit = (_data, error) => {
		if (!error) {
			showMessage({ message: 'Newsreels updated successfully.' })
			fetchNewsreels()
		}
	}

	const [{ loading: editing }, editNewsreelFn] = useAxios(editNewsreel(), afterEdit)

	const handleApproveChange = (data) => {
		editNewsreelFn({ data })
	}

	const loading = loadingNewsreels || editing

	useEffect(() => {
		fetchNewsreels()
	}, [limit, offset])

	return { loading, data: reviews, limit, setLimit, setOffset, handleApproveChange }
}

export default useNewsreelsTable
