import useAxios from 'hooks/useAxios'
import { useState } from 'react'
import { getGoScoreStages } from 'services/GoScoreService'

const useGoScoreStageTable = () => {
	const [stages, setStages] = useState([])

	const [{ loading }] = useAxios(getGoScoreStages(), (data, error) => {
		if (!error) {
			setStages(data)
		}
	})

	return {
		loading,
		stages,
	}
}

export default useGoScoreStageTable
