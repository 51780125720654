import _ from 'lodash'
import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { useHistory, useParams } from 'react-router-dom'
import { showMessage } from 'utils/notification.util'
import {
	addDigestEmailService,
	editDigestEmailService,
	getDigestEmailByIdService,
	getDigestEmailTypesService,
	getEmailTemplatesService
} from 'services/EmailNotificationServices'
import { createSelectOptions } from 'utils/form.util'
import { useDispatch, useSelector } from 'react-redux'
import { resetDigestForm, setDigestData, setStep } from './digestFormSlice'
import { getHours, getMinutes, getMonthDays, getWeekDays } from 'utils/cron.util'

const useDigestForm = () => {
	const history = useHistory()
	const { id } = useParams()
	const [notificationTypes, setNotificationTypes] = useState([])
	const [cronObj, setCronObj] = useState({
		frequency: 0,
		day: 0,
		time: 0,
		minute: 0,
		monthDay: 1,
	})
	const [templates, setTemplates] = useState([])
	const digestInfo = useSelector((state) => state.digestForm)
	const dispatch = useDispatch()
	const { formData: data, step } = digestInfo

	const frequencies = createSelectOptions([
		'Daily',
		'Weekly',
		'Monthly',
	])

	const days = createSelectOptions(getWeekDays())

	const hours = getHours()
	const monthDays = getMonthDays()
	const minutes = getMinutes()

	const getFrequencyHumanFormat = () => {
		const minute = cronObj.minute > 9 ? cronObj.minute : `0${cronObj.minute}`
		const time = cronObj.time > 9 ? cronObj.time : `0${cronObj.time}`
		const day = cronObj.day
		const weekDay = days[day]
		switch (cronObj?.frequency) {
			case 0:
				return `Every day at ${time}:${minute}`
			case 1:
				return `Every ${weekDay?.name} at ${time}:${minute}`
			case 2:
				return `Every day ${cronObj?.monthDay} of each month at ${time}:${minute}`
			default: return ''
		}
	}

	const updateCronParams = (cronExpression) => {
		if (_.isNil(cronExpression)) return
		const values = cronExpression.split(' ')
		const minute = values[0] !== '*' ? Number(values[0]) : 0
		const time = values[1] !== '*' ? Number(values[1]) : 0
		const monthDay = values[2] !== '*' ? Number(values[2]) : 1
		const day = values[4] !== '*' ? Number(values[4]) : 0
		let frequency = 0
		if (values[4] !== '*') frequency = 1
		if (values[2] !== '*') frequency = 2
		setCronObj({
			monthDay,
			day,
			time,
			minute,
			frequency
		})
	}

	const afterFetchNotificationTypes = (data, error) => {
		if (!error) {
			setNotificationTypes(data)
		}
	}

	const [{ loading: loadingTypes }] = useAxios(getDigestEmailTypesService(), afterFetchNotificationTypes)

	const afterFetchTemplates = (data, error) => {
		if (!error) {
			const temps = data?.map(el => ({
				id: el.slug,
				...el
			}))
			setTemplates(temps)
		}
	}

	const [{ loading: loadingTemplates }] = useAxios(getEmailTemplatesService(), afterFetchTemplates)

	const afterFetchtDigestEmail = (data, error) => {
		if (!error) {
			dispatch(setDigestData(data))
			updateCronParams(data?.frequency)
		}
	}

	const [{ loading: loadingEdit }] = useAxios(getDigestEmailByIdService(id, !_.isNil(id)), afterFetchtDigestEmail)

	const service = _.isNil(id) ? addDigestEmailService(data) : editDigestEmailService(data)
	const afterSave = (data, error) => {
		if (!error) {
			const message = `Notification Email ${_.isNil(id) ? 'created' : 'updated'} successfully!`
			showMessage({ message })
			if (_.isNil(id)) {
				dispatch(setStep(1))
				history.push(`/edit-digest-email/${data?.id}`)
			} else if (step < 4) {
				dispatch(setStep(step + 1))
				history.push(`/edit-digest-email/${data?.id}`)
			} else {
				history.push('/email-digest')
			}
		}
	}
	const [{ loading: saving }, saveChanges] = useAxios(service, afterSave)

	const handleChange = (payload) => {
		dispatch(setDigestData({
			...data,
			...payload,
		}))
	}

	const handleChangeFrequency = (payload) => {
		setCronObj({
			...cronObj,
			...payload,
		})
	}

	const handleCancel = () => {
		history.push('/email-digest')
	}

	const handleSave = () => {
		saveChanges()
	}

	const handlePublish = () => {
		saveChanges({ data: { ...data, active: 1 } })
	}

	const handleChangeStep = (stp) => () => {
		dispatch(setStep(stp))
	}

	useEffect(() => {
		return () => {
			dispatch(resetDigestForm())
		}
	}, [])

	const title = _.isNil(id) ? 'Email Notification Details' : 'Edit Email Notification'

	const loading = loadingTypes || saving || saving || loadingTemplates || loadingEdit

	return {
		data,
		step,
		days,
		hours,
		title,
		cronObj,
		loading,
		minutes,
		templates,
		monthDays,
		frequencies,
		notificationTypes,
		getFrequencyHumanFormat,
		handleChangeFrequency,
		handleChangeStep,
		handlePublish,
		handleChange,
		handleCancel,
		setCronObj,
		handleSave,
	}
}

export default useDigestForm
