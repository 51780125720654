import axios from 'axios'
import _ from 'lodash'
import { showMessage } from 'utils/notification.util'

const useAxiosV2 = () => {
    const axiosService = (config, callback) => {
        axios(config)
            .then(response => {
                if (response.status === 200) {
                    callback(response.data, false)
                } else {
                    const message = _.get(response, 'data.message', null)
                    if (message)
                        showMessage({ message: response?.data?.message, type: 'error' })
                }
            })
            .catch(error => {
                const message = _.get(error, 'response.data.message', null)
                callback(null, error)
                if (message)
                    showMessage({ message, type: 'error' })
            })
    }

    return { axiosService }
}

export default useAxiosV2
