import { Button } from 'react-bootstrap'
import useGoScoreConfigForm from './useConfigurationForm'
import { LoadMask } from 'components/common/loadMask'
import { CustomCheckbox } from 'components/common/customCheckbox'
import HtmlEditor from 'components/common/htmlEditor/HtmlEditor'
import { truncateToDecimals } from 'utils/form.util'

const ConfigurationForm = () => {
	const {
		data,
		showMask,
		handleChange,
		handleSaveChanges,
		handleFillWalletPool,
		reconcileSaleTransactions,
		addRetroactiveFollows
	} = useGoScoreConfigForm()

	return (
		<>
			{showMask ? (
				<LoadMask />
			) : (
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">Update Configuration Params</h4>
							</div>
							<div className="card-body">
								<div className="basic-form">
									<form onSubmit={(e) => e.preventDefault()}>
										<div className="form-row">
											<div className="form-group col-md-4">
												<CustomCheckbox
													label="Enable Creator Share"
													checked={data.enableCreatorShare}
													setChecked={(enableCreatorShare) => {
														handleChange({ enableCreatorShare })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Creator Share Limit</label>
												<input
													value={data.creatorShareLimit}
													type="number"
													className="form-control"
													placeholder="Creator Share Limit"
													onChange={(e) => {
														handleChange({ creatorShareLimit: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Creator Share Reward</label>
												<input
													value={data.creatorShareReward}
													type="number"
													className="form-control"
													placeholder="Creator Share Reward"
													onChange={(e) => {
														handleChange({ creatorShareReward: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<CustomCheckbox
													label="Enable Project Share"
													checked={data.enableProjectShare}
													setChecked={(enableProjectShare) => {
														handleChange({ enableProjectShare })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Project Share Limit</label>
												<input
													value={data.projectShareLimit}
													type="number"
													className="form-control"
													placeholder="Project Share Limit"
													onChange={(e) => {
														handleChange({ projectShareLimit: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Project Share Reward</label>
												<input
													value={data.projectShareReward}
													type="number"
													className="form-control"
													placeholder="Project Share Reward"
													onChange={(e) => {
														handleChange({ projectShareReward: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-3">
												<label>Token Sale Percentage</label>
												<input
													value={data.stakeLimit}
													type="number"
													className="form-control"
													placeholder="Token Sale Percentage"
													onChange={(e) => {
														handleChange({ stakeLimit: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-3">
												<label>Token Sale Goal</label>
												<input
													value={data.tokenSaleGoal}
													type="number"
													className="form-control"
													placeholder="Token Sale Goal"
													onChange={(e) => {
														handleChange({ tokenSaleGoal: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-3">
												<label>ETH USD Value</label>
												<input
													value={data.ethUsdValue}
													type="number"
													className="form-control"
													placeholder="ETH USD Value"
													onChange={(e) => {
														handleChange({ ethUsdValue: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-3">
												<CustomCheckbox
													label="Show Real Time Progress"
													checked={data.realTimeProgress}
													setChecked={(realTimeProgress) => {
														handleChange({ realTimeProgress })
													}}
												/>
											</div>
											<div className="form-group col-md-3">
												<CustomCheckbox
													label="Show Emergency Message"
													checked={data.saleFreeJoin}
													setChecked={(saleFreeJoin) => {
														handleChange({ saleFreeJoin })
													}}
												/>
											</div>
											<div className="form-group col-md-9">
											</div>
											<div className="form-group col-md-12">
												<strong>Validate wallet params</strong>
											</div>
											<div className="form-group col-md-3">
												<label>Min Wallet Living Days</label>
												<input
													value={data.minWalletLivingDays}
													type="number"
													className="form-control"
													placeholder="Min Wallet Living Days"
													onChange={(e) => {
														handleChange({ minWalletLivingDays: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-3">
												<label>Min Wallet Eth Amount</label>
												<input
													value={data.minEthAmount}
													type="number"
													className="form-control"
													placeholder="Min Wallet Eth Amount"
													onChange={(e) => {
														handleChange({ minEthAmount: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-2">
												<label>Min Wallet Matic Amount</label>
												<input
													value={data.minMaticAmount}
													type="number"
													className="form-control"
													placeholder="Min Wallet Matic Amount"
													onChange={(e) => {
														handleChange({ minMaticAmount: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-2">
												<label>Min Wallet USD Amount</label>
												<input
													value={data.minUsdtAmount}
													type="number"
													className="form-control"
													placeholder="Min Wallet USD Amount"
													onChange={(e) => {
														handleChange({ minUsdtAmount: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-2">
												<label>Minimum Transactions</label>
												<input
													value={data.minimumTransactions}
													type="number"
													className="form-control"
													placeholder="Minimum Transactions"
													onChange={(e) => {
														handleChange({ minimumTransactions: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-3">
												<label>Unstake FGR Rate</label>
												<input
													value={data.unstakeFgrRate}
													type="number"
													className="form-control"
													placeholder="Unstake FGR Rate"
													onChange={(e) => {
														const unstakeFgrRate = e.target.value === '' ? null : e.target.value
														handleChange({ unstakeFgrRate })
													}}
												/>
											</div>
											<div className="form-group col-md-3">
												<label>Circulating FAN Deduction</label>
												<input
													value={data.circulatingFanDeduction}
													type="number"
													className="form-control"
													placeholder="Circulating FAN Deduction"
													onChange={(e) => {
														const circulatingFanDeduction = e.target.value === '' ? 0 : e.target.value
														handleChange({ circulatingFanDeduction })
													}}
												/>
											</div>
											<div className="form-group col-md-3">
												<label>Ingredient-X Wallet Limit</label>
												<input
													value={data.ingXWalletLimit}
													type="number"
													max="100"
													min="0"
													className="form-control"
													placeholder="Ingredient-X Wallet Limit"
													onChange={(e) => {
														let  ingXWalletLimit = e.target.value === '' ? 0 : e.target.value
														ingXWalletLimit < 0 && (ingXWalletLimit = 0)
														ingXWalletLimit > 100 && (ingXWalletLimit = 100)
														handleChange({ ingXWalletLimit: truncateToDecimals(ingXWalletLimit, 2) })
													}}
												/>
											</div>
											<div className="form-group col-md-3">
												<label>Ingredient-X Group Limit</label>
												<input
													value={data.ingXGroupLimit}
													type="number"
													max="100"
													min="0"
													className="form-control"
													placeholder="Ingredient-X Group Limit"
													onChange={(e) => {
														let  ingXGroupLimit = e.target.value === '' ? 0 : e.target.value
														ingXGroupLimit < 0 && (ingXGroupLimit = 0)
														ingXGroupLimit > 100 && (ingXGroupLimit = 100)
														handleChange({ ingXGroupLimit: truncateToDecimals(ingXGroupLimit, 2) })
													}}
												/>
											</div>
											<div className="form-group col-md-12">
												<label>Welcome message</label>
												<HtmlEditor
													{...{
														value: data?.welcomeMessage,
														setValue: value => {
															handleChange({
																welcomeMessage: value
															})
														}
													}}
												/>
											</div>
										</div>
										<Button className="mr-2" variant="success" onClick={() => handleSaveChanges()}>
											Save
										</Button>
										<Button variant="primary" className="mr-2" onClick={() => handleFillWalletPool()}>
											Populate Wallet Pool
										</Button>
										<Button variant="primary" className="mr-2" onClick={() => addRetroactiveFollows()}>
											Add Retroactive Follows
										</Button>
										<Button variant="primary" className="mr-2" onClick={() => reconcileSaleTransactions()}>
											Reconcile Sale Transactions
										</Button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default ConfigurationForm
