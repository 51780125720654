import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
	user: {
		avatar: localStorage.getItem('avatar'),
		displayName: localStorage.getItem('displayName'),
		balance: localStorage.getItem('balance'),
		id: localStorage.getItem('id'),
		email: localStorage.getItem('email'),
		isAdmin: localStorage.getItem('isAdmin')
	},
}

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		removeAuthenticated: (state) => {
			state.isAuthenticated = false
		},
		updateSignInUser: (state, { payload }) => {
			state.user = {
				...state.user,
				...payload,
			}
		},
	},
})

export const { updateSignInUser, removeAuthenticated } = authSlice.actions

export default authSlice.reducer
