import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { getStudios } from 'services/StudioService'

const useStudiosTable = () => {
	const [search, setSearch] = useState('')
	const [limit, setLimit] = useState(5)
	const [offset, setOffset] = useState(0)
	const [studios, setStudios] = useState({
		rows: [],
		count: 0,
	})

	const afterFetchStudios = (data, error) => {
		if (!error) {
			setStudios(data)
		}
	}

	const [{ loading: loadingStudios }, fetchStudios] = useAxios(
		getStudios({ limit, offset, search }),
		afterFetchStudios
	)

	const loading = loadingStudios

	useEffect(() => {
		fetchStudios()
	}, [limit, offset, search])

	return { loading, data: studios, limit, setLimit, setOffset, setSearch }
}

export default useStudiosTable
