import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { getFanMetrics } from 'services/SeedServices'
import { useHistory, useParams } from 'react-router-dom'
import { showMessage } from 'utils/notification.util'
import { addFanLevel, editFanLevel, getFanLevelById } from 'services/SuperfanService'
import _ from 'lodash'

const useFanLevelForm = () => {
	const history = useHistory()
	const { id } = useParams()
	const [metrics, setMetrics] = useState([])
	const [data, setData] = useState({
		name: '',
		barColor: '',
		image: '',
		active: true,
		description: '',
		metrics: []
	})

	const afterFetchMetrics = (data, error) => {
		if (!error) {
			setMetrics(data)
		}
	}

	const [{ loading: loadingMetrics }] = useAxios(getFanMetrics(), afterFetchMetrics)

	const afterFetchLevel = (data, error) => {
		if (!error) {
			data.active = Boolean(data.active)
			setData(data)
		}
	}
	const [{ loading: loadingLevel }] = useAxios(getFanLevelById(id, !_.isNil(id)), afterFetchLevel)

	const service = _.isNil(id) ? addFanLevel(data) : editFanLevel(data)
	const afterSave = (_data, error) => {
		if (!error) {
			const message = 'Level updated successfully!'
			showMessage({ message })
			history.push('/superfan-level')
		}
	}
	const [{ loading: saving }, saveChanges] = useAxios(service, afterSave)

	const handleChange = (payload) => {
		setData({
			...data,
			...payload,
		})
	}

	const handleChangeMetric = (payload, idx) => {
		data.metrics[idx] = {
			...data.metrics[idx],
			...payload,
		}
		setData({
			...data,
		})
	}

	const handleCancel = () => {
		history.goBack()
	}

	const handleAddNewMetric = () => {
		setData({
			...data,
			metrics: [
				...data.metrics,
				{
					levelId: data.id,
				},
			],
		})
	}

	const handleRemoveMetric = (idx) => () => {
		const metrics = data.metrics.filter((_el, pos) => {
			return pos !== idx
		})
		setData({
			...data,
			metrics
		})
	}

	const handleSave = () => {
		saveChanges()
	}

	const title = _.isNil(id) ? 'Superfan Level Details' : 'Edit Superfan Level'

	const loading = loadingMetrics || saving || loadingLevel || saving

	return {
		data,
		title,
		metrics,
		loading,
		handleSave,
		handleCancel,
		handleChange,
		handleChangeMetric,
		handleAddNewMetric,
		handleRemoveMetric,
	}
}

export default useFanLevelForm
