import { useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { showMessage } from 'utils/notification.util'
import {
	addIngXRestrictionUser,
} from 'services/IngXRestrictionService'
import _ from 'lodash'

const useIngXRestrictionAddForm = () => {
	const history = useHistory()
	const [data, setData] = useState({
		email: null
	})

	const handleChange = (state) => {
		setData({
			...data,
			...state
		})
	}

	
	const handleSubmit = () => {
		if (_.isNil(data.email)) {
			showMessage({ message: "Please make sure to fill all the required fields!", type: 'error' })
		} else {
			axios(addIngXRestrictionUser({email: data.email})).then(() => {
				const message = `User with email ${data.email} added successfully!`
				showMessage({ message })
				history.push('/ingx-restriction')
			}).catch((error) => {showMessage({ message: error?.response?.data?.message, type: 'error' })})
		}

	}

	const handleCancel = () => {
		history.goBack()
	}

	return {
		data,
		handleCancel,
		handleChange,
		handleSubmit
	}
}

export default useIngXRestrictionAddForm
