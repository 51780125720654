import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { showMessage } from 'utils/notification.util'
import { editComment, getComments } from 'services/CommentService'

const useCommentsTable = () => {
	const [search, setSearch] = useState('')
	const [limit, setLimit] = useState(5)
	const [offset, setOffset] = useState(0)
	const [comments, setComments] = useState({
		rows: [],
		count: 0,
	})

	const afterFetchComments = (data, error) => {
		if (!error) {
			setComments(data)
		}
	}

	const [{ loading: loadingComments }, fetchComments] = useAxios(
		getComments(limit, offset, search),
		afterFetchComments
	)

	const afterEdit = (data, error) => {
		if (!error) {
			showMessage({ message: 'Comment updated successfully.' })
			fetchComments()
		}
	}

	const [{ loading: editing }, editCommentFn] = useAxios(editComment(), afterEdit)

	const handleApproveChange = (data) => {
		editCommentFn({ data })
	}

	const loading = loadingComments || editing

	useEffect(() => {
		fetchComments()
	}, [limit, offset, search])

	return { loading, data: comments, limit, setSearch, setLimit, setOffset, handleApproveChange }
}

export default useCommentsTable
