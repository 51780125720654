import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	step: 0
}

export const triggeredFormSlice = createSlice({
	name: 'triggeredForm',
	initialState,
	reducers: {
		setStep: (state, { payload }) => {
			state.step = payload
		}
	},
})

export const { setStep } = triggeredFormSlice.actions

export default triggeredFormSlice.reducer
