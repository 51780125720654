import { Row, Col, Card } from 'react-bootstrap'
import useNewsreelsTable from './useNewsreelsTable'
import { formatDate } from 'utils/date.utils'
import styles from './NewsreelsTable.module.scss'
import PageTitle from 'jsx/layouts/PageTitle'
import { CustomTable } from 'components/common/customTable'

const frontBaseURL = process.env.REACT_APP_FRONT_BASE_URL

const NewsreelsTable = () => {
	const { data, loading, limit, setLimit, setOffset, handleApproveChange } = useNewsreelsTable()

	const columns = [
		{
			header: 'Project',
			render: (row) => (
				<a href={`${frontBaseURL}/project/${row?.project?.slug}`} target="_blank" rel="noreferrer">
					{row?.project?.title}
				</a>
			),
		},
		{
			header: 'User',
			render: (row) => (
				<a href={`${frontBaseURL}/profile/${row.user.username}`} target="_blank" rel="noreferrer">
					{row.user.displayName}
				</a>
			),
		},
		{
			header: 'Post',
			key: 'content',
		},
		{
			header: 'Date',
			render: (row) => formatDate(row.createdAt, 'inputDate'),
		},
		{
			header: 'Approved',
			render: (row) => (
				<div className="custom-control custom-checkbox checkbox-success check-lg">
					<input
						type="checkbox"
						checked={row?.approved}
						className="custom-control-input"
						id={`customCheckBox${row?.id}`}
						onChange={(e) => {
							handleApproveChange({ id: row?.id, approved: e.target.checked })
						}}
					/>
					<label className="custom-control-label" htmlFor={`customCheckBox${row?.id}`}></label>
				</div>
			),
		},
	]

	return (
		<>
			<PageTitle title={'Newsreel Management'} />
			<Row className={styles.user_table}>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>Current Newsreels</Card.Title>
						</Card.Header>
						<Card.Body>
							<CustomTable
								{...{
									data,
									loading,
									limit,
									setLimit,
									setOffset,
									columns,
								}}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default NewsreelsTable
