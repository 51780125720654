import { Button } from 'react-bootstrap'
import useGoScoreConfigForm from './useRewardUnlockConfigForm'
import { LoadMask } from 'components/common/loadMask'

const RewardUnlockConfigForm = () => {
	const {
		buckets,
		showMask,
		gemOptions,
		progression,
		testBreakpoints,
		progressionParams,
		handleChangeProgressionParams,
		handleRemoveBucketBreakPoint,
		handleChangeBucketBreakpoint,
		handleRemoveTestBreakPoint,
		handleChangeTestBreakpoint,
		handleAddBucketBreakPoint,
		handleEmulateProgression,
		handleAddTestBreakpoint,
		handleClearProgression,
		handleChangeGemOption,
		handleRemoveGemOption,
		updateRewardBuckets,
		handleAddGemOption,
		handleChangeBucket,
		handleRemoveBucket,
		updateGemOptions,
		handleAddBucket
	} = useGoScoreConfigForm()

	const renderGemOptions = () => {
		return gemOptions?.map((gemOption, idx) => {
			return (
				<div
					className="form-row"
					key={`benefit-${idx}`}
					style={{
						paddingTop: '20px',
					}}
				>
					<div className="form-group col-md-5">
						<label>Gems amount</label>
						<input
							value={gemOption.amount}
							type="number"
							className="form-control"
							placeholder="Gems amount"
							onChange={(e) => {
								handleChangeGemOption({ amount: e.target.value }, idx)
							}}
						/>
					</div>
					<div className="form-group col-md-5">
						<label>Rate</label>
						<input
							value={gemOption.rate}
							type="number"
							className="form-control"
							placeholder="Rate"
							onChange={(e) => {
								handleChangeGemOption({ rate: e.target.value }, idx)
							}}
						/>
					</div>
					<div
						className="col-md-2"
						style={{
							display: 'flex',
							alignItems: 'end',
							marginBottom: '20px',
						}}
					>
						<Button className="mr-2" variant="danger" onClick={handleRemoveGemOption(idx)}>
							<i className="fa fa-trash"></i>
						</Button>
					</div>
				</div>
			)
		})
	}

	const renderBucketBreakpoints = (breackpoints, bucketIdx) => {
		return breackpoints?.map((breackpoint, idx) => {
			return (
				<div
					className="form-row"
					key={`benefit-${idx}`}
					style={{
						paddingTop: '10px',
					}}
				>
					<div className="form-group col-md-2"></div>
					<div className="form-group col-md-4">
						<label>Acceleration Factor</label>
						<input
							value={breackpoint.accelerationRate}
							type="number"
							className="form-control"
							placeholder="Acceleration Factor"
							onChange={(e) => {
								handleChangeBucketBreakpoint({ accelerationRate: e.target.value }, idx, bucketIdx)
							}}
						/>
					</div>
					<div className="form-group col-md-4">
						<label>Time Rate</label>
						<input
							value={breackpoint.timeRate}
							type="number"
							className="form-control"
							placeholder="Time Rate"
							onChange={(e) => {
								handleChangeBucketBreakpoint({ timeRate: e.target.value }, idx, bucketIdx)
							}}
						/>
					</div>
					<div
						className="col-md-2"
						style={{
							display: 'flex',
							alignItems: 'end',
							marginBottom: '20px',
						}}
					>
						<Button className="mr-2" variant="danger" onClick={handleRemoveBucketBreakPoint(idx, bucketIdx)}>
							<i className="fa fa-trash"></i>
						</Button>
					</div>
				</div>
			)
		})
	}

	const renderTestBreakpoints = (breackpoints) => {
		return breackpoints?.map((breackpoint, idx) => {
			return (
				<div
					className="form-row"
					key={`benefit-${idx}`}
					style={{
						paddingTop: '10px',
					}}
				>
					<div className="form-group col-md-2"></div>
					<div className="form-group col-md-4">
						<label>Acceleration Factor</label>
						<input
							value={breackpoint.accelerationRate}
							type="number"
							className="form-control"
							placeholder="Acceleration Factor"
							onChange={(e) => {
								handleChangeTestBreakpoint({ accelerationRate: e.target.value }, idx)
							}}
						/>
					</div>
					<div className="form-group col-md-4">
						<label>Time Rate</label>
						<input
							value={breackpoint.timeRate}
							type="number"
							className="form-control"
							placeholder="Time Rate"
							onChange={(e) => {
								handleChangeTestBreakpoint({ timeRate: e.target.value }, idx)
							}}
						/>
					</div>
					<div
						className="col-md-2"
						style={{
							display: 'flex',
							alignItems: 'end',
							marginBottom: '20px',
						}}
					>
						<Button className="mr-2" variant="danger" onClick={handleRemoveTestBreakPoint(idx)}>
							<i className="fa fa-trash"></i>
						</Button>
					</div>
				</div>
			)
		})
	}

	const renderBuckets = () => {
		return buckets?.map((bucket, idx) => {
			return (
				<div
					className="form-row"
					key={`benefit-${idx}`}
					style={{
						paddingTop: '20px',
					}}
				>
					<div className="form-group col-md-2">
						<label>Min FAN Amount</label>
						<input
							value={bucket.minTokenAmount}
							type="number"
							className="form-control"
							placeholder="Min FAN Amount"
							onChange={(e) => {
								handleChangeBucket({ minTokenAmount: e.target.value }, idx)
							}}
						/>
					</div>
					<div className="form-group col-md-2">
						<label>Max FAN Amount</label>
						<input
							value={bucket.maxTokenAmount}
							type="number"
							className="form-control"
							placeholder="Max FAN Amount"
							onChange={(e) => {
								handleChangeBucket({ maxTokenAmount: e.target.value }, idx)
							}}
						/>
					</div>
					<div className="form-group col-md-2">
						<label>Acceleration factor</label>
						<input
							value={bucket.accelerationFactor}
							type="number"
							className="form-control"
							placeholder="Acceleration factor"
							onChange={(e) => {
								handleChangeBucket({ accelerationFactor: e.target.value }, idx)
							}}
						/>
					</div>
					<div className="form-group col-md-2">
						<label>Max Days</label>
						<input
							value={bucket.maxDays}
							type="number"
							className="form-control"
							placeholder="Max Days"
							onChange={(e) => {
								handleChangeBucket({ maxDays: e.target.value }, idx)
							}}
						/>
					</div>
					<div
						className="col-md-2"
						style={{
							display: 'flex',
							alignItems: 'end',
							marginBottom: '20px',
						}}
					>
						<Button className="mr-2" variant="danger" onClick={handleRemoveBucket(idx)}>
							<i className="fa fa-trash"></i>
						</Button>
						<Button className="mr-2" variant="info" onClick={handleAddBucketBreakPoint(idx)}>
							<i className="fa fa-plus"></i>
						</Button>
					</div>
					{renderBucketBreakpoints(bucket?.bucketBreakPoints, idx)}
				</div>
			)
		})
	}

	const renderProgression = () => {
		return progression?.map((data, idx) => {
			return (
				<div
					className="form-row"
					key={`day-${idx}`}
				>
					<div className="form-group col-md-2">
						Day: {data?.day}
					</div>
					<div className="form-group col-md-3">
						Daily Release: {Number(data?.dailyRelease).toFixed(4)}
					</div>
					<div className="form-group col-md-3">
						Current Release: {Number(data?.currentRelease).toFixed(4)}
					</div>
				</div>
			)
		})
	}

	return (
		<>
			{showMask ? (
				<LoadMask />
			) : (
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">Update Time Unlock Configuration</h4>
							</div>
							<div className="card-body">
								<div className="basic-form">
									<form onSubmit={(e) => e.preventDefault()}>
										<div className="form-row" style={{ marginTop: 30 }}>
											<h4> Gem Options </h4>
										</div>
										{renderGemOptions()}
										<div className="form-row mb-3">
											<Button className="mr-2" variant="info" onClick={handleAddGemOption}>
												Add Gem Option
											</Button>
										</div>
										<div className="form-row" style={{ marginTop: 30 }}>
											<h4>
												Reward Buckets
											</h4>
										</div>
										{renderBuckets()}
										<div className="form-row mb-3">
											<Button className="mr-2" variant="info" onClick={handleAddBucket}>
												Add Reward Bucket
											</Button>
										</div>

										<div className="form-row" style={{ marginTop: 30 }}>
											<h4> Progression Simulation </h4>
										</div>
										<div className="form-row mb-3">
											<div className="form-group col-md-2">
												<label>Max Days</label>
												<input
													value={progressionParams.days}
													type="number"
													className="form-control"
													placeholder="Max Days"
													onChange={(e) => {
														handleChangeProgressionParams({ days: Number(e.target.value) })
													}}
												/>
											</div>
											<div className="form-group col-md-2">
												<label>Acceleration Factor</label>
												<input
													value={progressionParams.accFactor}
													type="number"
													className="form-control"
													placeholder="Acceleration Factor"
													onChange={(e) => {
														handleChangeProgressionParams({ accFactor: Number(e.target.value) })
													}}
												/>
											</div>
											<div className="form-group col-md-2">
												<label>Total Rewards</label>
												<input
													value={progressionParams.totalTokens}
													type="number"
													className="form-control"
													placeholder="Total Rewards"
													onChange={(e) => {
														handleChangeProgressionParams({ totalTokens: Number(e.target.value) })
													}}
												/>
											</div>
											<div className="form-group col-md-2">
												<label>Reward Day</label>
												<input
													value={progressionParams.rewardDay}
													type="number"
													className="form-control"
													placeholder="Reward Day"
													onChange={(e) => {
														handleChangeProgressionParams({ rewardDay: Number(e.target.value) })
													}}
												/>
											</div>
											<div className="form-group col-md-2">
												<label>New Reward Amount</label>
												<input
													value={progressionParams.newRewardAmount}
													type="number"
													className="form-control"
													placeholder="New Reward Amount"
													onChange={(e) => {
														handleChangeProgressionParams({ newRewardAmount: Number(e.target.value) })
													}}
												/>
											</div>
											<div className="col-md-2" style={{ marginTop: 30 }}>
												<Button className="mr-2" variant="info" onClick={handleAddTestBreakpoint}>
													<i className="fa fa-plus"></i>
												</Button>
											</div>
											{renderTestBreakpoints(testBreakpoints)}
										</div>
										<div className="form-row mb-3">
											<Button className="mr-2" variant="info" onClick={handleEmulateProgression}>
												Emulate Progression
											</Button>
											<Button className="mr-2" variant="info" onClick={handleClearProgression}>
												Clear progression
											</Button>
										</div>
										{renderProgression()}
										<Button className="mr-2" variant="info" onClick={updateRewardBuckets}>
											Save Buckets
										</Button>
										<Button className="mr-2" variant="info" onClick={updateGemOptions}>
											Save Gem Options
										</Button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default RewardUnlockConfigForm
