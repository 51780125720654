import _ from 'lodash'
import { Row, Col, Card } from 'react-bootstrap'
import useCommentsTable from './useCommentsTable'
import { formatDate } from 'utils/date.utils'
import PageTitle from 'jsx/layouts/PageTitle'
import { CustomTable } from 'components/common/customTable'
import { SearchField } from 'components/common/searchField'

const frontBaseURL = process.env.REACT_APP_FRONT_BASE_URL

const CommentsTable = () => {
	const { data, loading, limit, setLimit, setSearch, setOffset, handleApproveChange } =
		useCommentsTable()

	const columns = [
		{
			header: 'Comment',
			key: 'content',
		},
		{
			header: 'User',
			render: (row) => (
				<a href={`${frontBaseURL}/profile/${row.user.username}`} target="_blank" rel="noreferrer">
					{row.user.displayName}
				</a>
			),
		},
		{
			header: 'Newsreel',
			key: 'newsreel.content',
		},
		{
			header: 'Project',
			render: (row) => {
				const id = _.get(row, 'newsreel.project.title', false)
				if (!id) return ''
				return (
					<a
						href={`${frontBaseURL}/project/${_.get(row, 'newsreel.project.slug', null)}`}
						target="_blank"
						rel="noreferrer"
					>
						{_.get(row, 'newsreel.project.title', '')}
					</a>
				)
			},
		},
		{
			header: 'Date',
			render: (row) => formatDate(row.createdAt, 'inputDate'),
		},
		{
			header: 'Approved',
			render: (row) => (
				<div className="custom-control custom-checkbox checkbox-success check-lg">
					<input
						type="checkbox"
						checked={row.approved}
						className="custom-control-input"
						id={`customCheckBox${row.id}`}
						onChange={(e) => {
							handleApproveChange({ id: row.id, approved: e.target.checked })
						}}
					/>
					<label className="custom-control-label" htmlFor={`customCheckBox${row.id}`}></label>
				</div>
			),
		}
	]

	return (
		<>
			<PageTitle title={'Comment Management'} />
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>Current Comments</Card.Title>
							<SearchField {...{ setSearch }} />
						</Card.Header>
						<Card.Body>
							<CustomTable
								{...{
									data,
									loading,
									limit,
									setLimit,
									setOffset,
									columns,
								}}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default CommentsTable
