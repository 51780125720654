import { useHistory, useParams } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { loginService } from 'services/LoginService'
import { showMessage } from 'utils/notification.util'
import { addObjectToLocalStorage, validateLocalStorageKey } from 'utils/common.util'
import { updateSignInUser } from './authSlice'

function AdminLogin() {
	const { token } = useParams()
	const history = useHistory()
	const dispatch = useDispatch()
	const isAdmin = localStorage.getItem('isAdmin')
	const isValidJWT = validateLocalStorageKey("jwt", token)
	const { isAuthenticated } = useSelector((state) => state.auth)
	const isAuth = (Number(isAdmin) === 1 || isAuthenticated === 'true') && isValidJWT

	isAuth && history.push('/')
	!isAuth && localStorage.setItem('jwt', token)
	!isAuth && axios(loginService(true, token)).then((data) => {
		addObjectToLocalStorage({ ...data, isAuthenticated: true })
		dispatch(updateSignInUser(data?.data))
		history.push('/')
	}).catch((error) => {
		showMessage({ message: 'You are not authorized' })
		history.goBack()
		setTimeout(() => {
			window.close()
		}, 3000)
	})

	return (
		<div>
			{!isAuth && <Spinner animation="grow" variant="light" />}
		</div>
	)
}

export default AdminLogin
