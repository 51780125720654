import _ from 'lodash'
import { useRef, useMemo, useEffect } from 'react'

const SearchField = ({ search, setSearch, }) => {
  const handleChange = (e) => {
    // react pools events and reuses them for performance reasons
    // meaning the event might not persist in an asynchronous callback like _.debounce
    // so it's better to extract the value from the event and pass it to the debounced function
    const value = e.target.value
    handleChangeDebounced(value)
  }

  const handleChangeDebounced = useMemo(() => {
    return _.debounce((value) => {
      setSearch(value.trim())
    }, 500)
  }, [])

  const inputElement = useRef();

  useEffect(() => {
    if (!search) {
      inputElement.current.value = ''
    }
  }, [search])

  return (
    <div>
      <div className='form-group col-md-12'>
        <input
          ref={inputElement}
          type='text'
          className='form-control'
          placeholder='Search'
          autoFocus
          onChange={handleChange}
        />
      </div>
    </div>
  )
}

export default SearchField
