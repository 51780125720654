import _ from 'lodash'
import { Link } from 'react-router-dom'
import { Row, Col, Card, Button } from 'react-bootstrap'
import useTokenSaleCampaignsTable from './useTokenSaleCampaignsTable'
import PageTitle from 'jsx/layouts/PageTitle'
import { CustomTable } from 'components/common/customTable'
import { SearchField } from 'components/common/searchField'
import { formatDate } from 'utils/date.utils'

const frontBaseURL = process.env.REACT_APP_FRONT_BASE_URL

const TokenSaleCampaignsTable = () => {
	const {
		data,
		limit,
		loading,
		linksToAdd,
		handleAddMoreLinks,
		handleClearCampaign,
		handleLinksToAddChange,
		setOffset,
		setLimit,
		setSearch
	} = useTokenSaleCampaignsTable()

	const groupName = _.get(data, ['rows', 0, 'group', 'name'], '')

	const columns = [
		{
			header: 'URL',
			render: (row) => (
				<a href={`${frontBaseURL}/tokenSale/${row?.id}`} target="_blank" rel="noreferrer">
					{row?.id}
				</a>
			),
		},
		{
			header: 'Wallet',
			key: 'user.wallet.ncWalletAddress'
		},
		{
			header: 'User',
			render: (row) => row?.user ? (
				<a href={`${frontBaseURL}/profile/${row?.user?.username}`} target="_blank" rel="noreferrer">
					{row?.user?.displayName}
				</a>
			) : 'Untaken',
		},
		{
			header: 'Label',
			key: 'label'
		},
		{
			header: 'Start Date',
			render: (row) => formatDate(_.get(row, 'startDate', null), 'inputDate'),
		},
		{
			header: 'End Date',
			render: (row) => formatDate(_.get(row, 'endDate', null), 'inputDate'),
		},
		{
			header: 'Actions',
			render: (row) => (
				<div className="d-flex">
					<Link to={`/edit-token-sale-campaign/${row.id}`} className="btn btn-primary shadow btn-xs sharp mr-1">
						<i className="fa fa-pencil"></i>
					</Link>
					<Link className="btn btn-primary shadow btn-xs sharp mr-1"
						onClick={handleClearCampaign(row)}>
						<i className="fa fa-rotate-left"></i>
					</Link>
				</div>
			),
		},
	]

	return (
		<>
			<PageTitle title={'Token Sale Campaign Management'} />

			<Row>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>{groupName} Campaigns</Card.Title>
							<div className="form-group d-flex">
								<input
									value={linksToAdd}
									type="text"
									className="form-control mr-2"
									placeholder="Links to add"
									onChange={handleLinksToAddChange}
								/>
								<Button className="mr-2" variant="success"
									onClick={() => handleAddMoreLinks()}
								>
									Add
								</Button>
							</div>
							<SearchField {...{ setSearch }} />
						</Card.Header>
						<Card.Body>
							<CustomTable
								{...{
									data,
									loading,
									limit,
									setLimit,
									setOffset,
									columns,
								}}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default TokenSaleCampaignsTable
