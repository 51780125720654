const buildFormDaoTierData = (data, edit) => {
	const formData = new FormData()
	const fields = [
		'name',
		'description',
		'image',
		'ringImage',
		'levelUpImage',
		'bonus',
		'metrics',
		'benefits',
		'airdropPercent',
		'order',
		'stakeLimit',
	]

	if (edit) fields.push('id')

	const files = ['image', 'ringImage', 'levelUpImage']
	const objects = ['metrics', 'benefits']

	fields.forEach((key) => {
		if (data[key] instanceof File) {
			formData.append(key, data[key], data[key].name)
		} else if (objects.includes(key)) {
			formData.append(key, JSON.stringify(data[key]))
		} else if (!files.includes(key) && !objects.includes(key)) {
			const value = data[key] || null
			formData.append(key, value)
		}
	})
	return formData
}

export const addDaoTier = (data) => {
	const formData = buildFormDaoTierData(data)
	return {
		method: 'post',
		url: '/daoTier',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		autoexec: false,
	}
}

export const editDaoTier = (data) => {
	const formData = buildFormDaoTierData(data, true)
	return {
		method: 'put',
		url: '/daoTier',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		autoexec: false,
	}
}

export const getDaoTierById = (id, autoexec = true) => ({
	method: 'get',
	url: `/daoTier/${id}`,
	autoexec
})

export const getDaoTiers = () => ({
	method: 'get',
	url: '/daoTier',
	autoexec: true,
})

export const deleteDaoTier = (tierId) => ({
	method: 'delete',
	url: `/daoTier/${tierId}`,
	autoexec: false,
})