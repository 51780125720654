import _ from 'lodash'
import styles from './CustomCheckbox.module.scss'

const CustomCheckbox = ({ setChecked, label, ...props }) => {
	const id = _.uniqueId()
	return (
		<div className={`form-check ${styles.custom_checkbox}`}>
			<input
				id={id}
				type="checkbox"
				onChange={(e) => {
					setChecked(e.target.checked)
				}}
				{...props}
			/>
			<label className="form-check-label" htmlFor={id}>
				{label}
			</label>
		</div>
	)
}
export default CustomCheckbox
