import _ from 'lodash'
import { Row, Col, Card, Button } from 'react-bootstrap'
import useTroubleshoot from './useTroubleshoot'
import { formatDate } from 'utils/date.utils'
import PageTitle from 'jsx/layouts/PageTitle'
import { CustomTable } from 'components/common/customTable'
import { SearchField } from 'components/common/searchField'

const frontBaseURL = process.env.REACT_APP_FRONT_BASE_URL

const Troubleshoot = () => {
	const {
		data,
		limit,
		loading,
		setOffset,
		setLimit,
		setSearch,
		handleFailTransaction,
		handleFailTransactionsInProgress
	} = useTroubleshoot()

	const columns = [
		{
			header: 'Date',
			render: (row) => formatDate(_.get(row, 'createdAt', null), 'inputDate'),
		},
		{
			header: 'User',
			render: (row) => (
				<a href={`${frontBaseURL}/profile/${row?.user?.username}`} target="_blank" rel="noreferrer">
					<div className="d-flex align-items-center">
						{row?.user?.avatar && <img src={row?.user?.avatar} className="rounded-md mr-2" width="24" alt="" />}
						{row?.user?.displayName}
					</div>{' '}
				</a>
			),
		},
		{
			header: 'Project',
			render: (row) => (
				<a href={`${frontBaseURL}/project/${row?.project?.slug}`} target="_blank" rel="noreferrer">
					<div className="d-flex align-items-center">
						{row?.project?.title}
					</div>{' '}
				</a>
			),
		},
		{
			header: 'Type',
			render: (row) => (
				<div className="d-flex align-items-center">
					{row?.type?.name}
				</div>
			),
		},
		{ header: 'Amount', key: 'amount' },
		{ header: 'Hash', key: 'blockchainHash' },
		{
			header: 'User SCW',
			key: 'scw'
		},
		{
			header: 'Action',
			render: (row) => (
				<div className="d-flex">
					<button className="btn btn-primary shadow btn-xs sharp mr-1" onClick={handleFailTransaction(row.id)}>
						<i class="fa fa-times" aria-hidden="true"></i>
					</button>
				</div>
			),
		},
	]

	const title = 'Troubleshoot'
	const subtitle = 'Troubleshoot Transactions'

	return (
		<>
			<PageTitle title={title} />

			<Row>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>{subtitle}</Card.Title>
							<Button className="mr-2" variant="danger" onClick={handleFailTransactionsInProgress}>
								Fail transactions stuck in progress
							</Button>
							<SearchField {...{ setSearch }} />
						</Card.Header>
						<Card.Body>
							<CustomTable
								{...{
									data,
									loading,
									limit,
									setLimit,
									setOffset,
									columns,
								}}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default Troubleshoot
