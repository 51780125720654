import { buildFormData } from "utils/form.util"

export const getCollaboratorRoles = () => ({
	method: 'get',
	url: '/collaboratorRole',
	autoexec: true,
})

export const getCollaboratorRoleById = (id, autoexec = true) => ({
	method: 'get',
	url: `/collaboratorRole/${id}`,
	autoexec
})

export const addCollaboratorRole = (data) => {
	const fields = ['name']
	const objects = ['permissions']
	const files = []
	const formData = buildFormData(data, fields, files, objects)
	return {
		method: 'post',
		url: '/collaboratorRole',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		autoexec: false,
	}
}

export const editCollaboratorRole = (data) => {
	const fields = ['name', 'id']
	const objects = ['permissions']
	const files = []
	const formData = buildFormData(data, fields, files, objects)
	return {
		method: 'put',
		url: '/collaboratorRole',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		autoexec: false,
	}
}