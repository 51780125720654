import { Button } from 'react-bootstrap'
import useTokenSaleCampaignForm from './useTokenSaleCampaignForm'
import { LoadMask } from 'components/common/loadMask'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"


const TokenSaleCampaignForm = () => {
	const {
		data,
		loading,
		handleCancel,
		handleChange,
		saveChanges
	} = useTokenSaleCampaignForm()

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">Campaign Details</h4>
							</div>
							<div className="card-body">
								<div className="basic-form">
									<form onSubmit={(e) => e.preventDefault()}>
										<div className="form-row">
											<div className="form-group col-md-6">
												<label>Label</label>
												<input
													value={data.label}
													type="text"
													className="form-control"
													placeholder="Label"
													onChange={(e) => {
														handleChange({ label: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-3">
												<label>Start Date*</label>
												<DatePicker selected={data?.startDate} onChange={(startDate) => handleChange({ startDate })} className="form-control" />
											</div>
											<div className="form-group col-md-3">
												<label>End Date*</label>
												<DatePicker selected={data?.endDate} onChange={(endDate) => handleChange({ endDate })} className="form-control" />
											</div>
										</div>
										<Button className="mr-2" variant="success" onClick={() => saveChanges()}>
											Save
										</Button>
										<Button variant="danger" onClick={handleCancel}>
											Cancel
										</Button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default TokenSaleCampaignForm
